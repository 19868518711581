import React, { useState } from "react";
import ReactDOM from "react-dom";
import { Switch, Route } from "react-router-dom";
import graphandClient from "../../../utils/graphand";
import { GraphandForm } from "graphand-react";
import GraphandUIList from "../../../components/GraphandUIList";
import Breadcrumbs from "../../../components/Breadcrumbs";
import Heading from "../../../components/Heading";
import Tabs from "../../../components/Tabs";
import CrudButton from "../../../components/CrudButton";
import { InformationCircleIcon, PhotographIcon, CreditCardIcon, ExclamationIcon } from "@heroicons/react/outline";
import { faFilePdf, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Dropzone from "react-dropzone";
import filesize from "filesize";
import AddPaymentModal from "../../../modals/AddPaymentModal";
import ManagePaymentsModal from "../../../modals/ManagePaymentsModal";
import PreviewClaimModal from "../../../modals/PreviewClaim";
import { Menu } from "@headlessui/react";
import UpdateClaimModal from "../../../modals/UpdateClaim";
import InputText from "../../../fields/inputs/InputText";
import { GraphandFieldText } from "graphand-js";
import UpdateClaimDocumentsModal from "../../../modals/UpdateClaimDocuments";

const ContractRCProDetailsDetails = (props) => {
  return (
    <div className="space-y-4">
      <div className="bg-white shadow overflow-hidden sm:rounded-lg">
        <div className="px-4 py-5 sm:px-6 flex items-center">
          <div className="w-full">
            <h3 className="text-lg leading-6 font-medium text-gray-900">Informations du contrat</h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">Voici la liste des informations concernant le contrat ou le projet.</p>
          </div>
          <div>
            {props.contract.status === "active" ? (
              <button
                className="ml-4 rounded-md border border-emerald-500 text-emerald-500 hover:border-emerald-600 hover:text-emerald-600 px-3 py-2 text-sm"
                onClick={props.contract.downloadContract.bind(props.contract)}
                type="button"
              >
                Télécharger le PDF du contrat
              </button>
            ) : null}
          </div>
        </div>
        <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
          <dl className="sm:divide-y sm:divide-gray-200">
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Numéro du contrat</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{props.contract.renderFieldView("code")}</dd>
            </div>
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Date d'effet</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{props.contract.renderFieldView("effective-date")}</dd>
            </div>
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Fractionnement</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{props.contract.renderFieldView("payment-mode")}</dd>
            </div>
            <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">n° SIRET</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {props.contract.renderFieldView("siret")}

                {props.contract.siret && graphandClient.authmanager.user?.isLNA() ? (
                  <button
                    className="ml-4 rounded-md border border-primary-500 text-primary-500 hover:border-primary-600 hover:text-primary-600 px-3 py-2 text-sm"
                    onClick={props.contract.readCreditsafe.bind(props.contract)}
                    type="button"
                  >
                    Creditsafe
                  </button>
                ) : null}
              </dd>
            </div>
            {props.contract.activities?.suspense((activities) => (
              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Liste des activités du contrat</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{activities.map((activity) => activity.name).join(", ")}</dd>
              </div>
            )) || null}
            {graphandClient.authmanager.user?.isAdmin() ? (
              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Commentaires</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  <InputText
                    options={{ label: null }}
                    errors={[]}
                    field={new GraphandFieldText({ rte: true })}
                    value={props.contract.comments}
                    onChange={(comments) => props.contract.update({ set: { comments } })}
                  />
                </dd>
              </div>
            ) : null}
          </dl>
        </div>
      </div>
      {props.contract.customer.suspense((customer) => (
        <div className="bg-white shadow overflow-hidden sm:rounded-lg">
          <div className="px-4 py-5 sm:px-6 flex items-center">
            <div className="w-full">
              <h3 className="text-lg leading-6 font-medium text-gray-900">Informations du client</h3>
              <p className="mt-1 max-w-2xl text-sm text-gray-500">Voici la liste des informations concernant le client du contrat ou du projet.</p>
            </div>
          </div>
          <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
            <dl className="sm:divide-y sm:divide-gray-200">
              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Nom</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{customer.renderFieldView("slug")}</dd>
              </div>
              <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Adresse</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  <div className="w-full truncate">
                    {customer.renderFieldView("address")}, {customer.renderFieldView("postal")} {customer.renderFieldView("city")}
                  </div>
                </dd>
              </div>
            </dl>
          </div>
        </div>
      ))}
    </div>
  );
};

const ContractRCProDetailsMedias = ({ contract }) => {
  const [loading, setLoading] = useState(false);

  const _handleDrop = async (files) => {
    setLoading(true);
    const Media = graphandClient.getModel("Media");

    const promises = files.map(async (file) => {
      return Media.create({ file }).then((media) => {
        return contract.update({ set: { $addToSet: { medias: media._id } } });
      });
    });

    try {
      await Promise.all(promises);
    } catch (e) {
      alert("Une erreur est survenue lors de l'ajout d'un document");
    }

    setLoading(false);
  };

  return (
    <div className="ContractRCProUpdateMedias p-3">
      <Dropzone onDrop={_handleDrop}>
        {({ getRootProps, getInputProps }) => (
          <section>
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <div className="relative overflow-hidden">
                <input className="absolute inset-0 opacity-0" id="inputGroupFile03" type="file" />
                <label htmlFor="inputGroupFile03">
                  <div className="w-full flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                    <div className="space-y-1 text-center">
                      <svg className="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
                        <path
                          d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      <div className="flex text-sm text-gray-600">
                        <span className="relative cursor-pointer bg-white rounded-md font-medium text-secondary-600 hover:text-secondary-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-secondary-500">
                          Cliquez ici pour ajouter un fichier
                        </span>
                        <p className="pl-1">ou déposez-le dans la zone</p>
                      </div>
                      <p className="text-xs text-gray-500">PNG, JPG, PDF, etc.</p>
                    </div>
                  </div>
                </label>
              </div>
            </div>
          </section>
        )}
      </Dropzone>

      <section className="mt-8 pb-16" aria-labelledby="gallery-heading">
        <h2 id="gallery-heading" className="sr-only">
          Documents déposés
        </h2>
        <ul
          role="list"
          className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 md:grid-cols-4 lg:grid-cols-3 xl:grid-cols-4 xl:gap-x-8"
        >
          {contract.suspense(
            (_contract) => {
              if (loading) {
                return "Chargement ...";
              }

              return _contract.medias?.suspense(
                (medias) => {
                  if (!medias.length) {
                    return null;
                  }

                  return medias.reverse().map((media) =>
                    media.mimetype && media.mimetype.match("image") ? (
                      <li className="block relative group" onClick={() => media.download()}>
                        <div className="opacity-0 group-hover:opacity-100">
                          <div
                            className="opacity-50 hover:opacity-100 absolute top-2 right-2 bg-white rounded-full text-red-500 shadow z-10 h-8 w-8 flex items-center justify-center cursor-pointer"
                            onClick={(e) => {
                              e.stopPropagation();
                              media.delete();
                            }}
                          >
                            <FontAwesomeIcon icon={faTimes} />
                          </div>
                        </div>
                        <div className="block w-full aspect-w-10 aspect-h-7 rounded-lg bg-gray-100 overflow-hidden">
                          <img src={media.getUrl({ w: 512 })} alt="" className="object-cover pointer-events-none group-hover:opacity-75" />
                          <button type="button" className="absolute inset-0 focus:outline-none">
                            <span className="sr-only">Télécharger {media.name}</span>
                          </button>
                        </div>
                        <p className="mt-2 block text-sm font-medium text-gray-900 truncate pointer-events-none">{media.name}</p>
                        <p className="block text-sm font-medium text-gray-500 pointer-events-none">{filesize(media.size)}</p>
                      </li>
                    ) : (
                      <li className="block relative group" onClick={() => media.download()}>
                        <div className="opacity-0 group-hover:opacity-100">
                          <div
                            className="opacity-50 hover:opacity-100 absolute top-2 right-2 bg-white rounded-full text-red-500 shadow z-10 h-8 w-8 flex items-center justify-center cursor-pointer"
                            onClick={(e) => {
                              e.stopPropagation();
                              media.delete();
                            }}
                          >
                            <FontAwesomeIcon icon={faTimes} />
                          </div>
                        </div>
                        <div className="block w-full aspect-w-10 aspect-h-7 rounded-lg bg-gray-100 overflow-hidden border-2 border-gray-300 border-dashed">
                          <div className="flex items-center justify-center group-hover:opacity-75">
                            <FontAwesomeIcon icon={faFilePdf} size="3x" className="text-gray-500" />
                          </div>
                          <button type="button" className="absolute inset-0 focus:outline-none">
                            <span className="sr-only">Télécharger {media.name}</span>
                          </button>
                        </div>
                        <p className="mt-2 block text-sm font-medium text-gray-900 truncate pointer-events-none">{media.name}</p>
                        <p className="block text-sm font-medium text-gray-500 pointer-events-none">{filesize(media.size)}</p>
                      </li>
                    ),
                  );
                },
                "Chargement ...",
                { updateKey: _contract.medias?.ids, subscribe: true },
              );
            },
            { updateKey: contract._id, subscribe: true },
          )}
        </ul>
      </section>
    </div>
  );
};

class ContractRCProDetailsPayments extends React.Component {
  handleManagePayments = () => {
    const node = document.createElement("div");
    document.body.appendChild(node);
    ReactDOM.render(
      React.createElement(ManagePaymentsModal, {
        onConfirm: () => null,
        onClose: () => null,
        contract: this.props.contract,
      }),
      node,
    );
    node.remove();
    return;

    // const myDialog = dialog(
    //   <div className="modal-dialog modal-lg" role="document">
    //     <div className="modal-content">
    //       <div className="modal-header">
    //         <h5 className="modal-title">Gérer les paiements</h5>
    //         <button aria-label="Close" className="close" onClick={() => myDialog.close()} type="button">
    //           <span aria-hidden="true">&times;</span>
    //         </button>
    //       </div>
    //       <div className="modal-body"></div>
    //       <div className="modal-footer">
    //         <button className="btn btn-primary" onClick={() => this.handleAddPayment(this.props.contract.tiercePrice)} type="button">
    //           Ajouter un paiement
    //         </button>
    //         <button className="btn btn-secondary" onClick={() => myDialog.close()} type="button">
    //           Fermer
    //         </button>
    //       </div>
    //     </div>
    //   </div>,
    //   {
    //     close: () => null,
    //   },
    // );
  };

  handleAddPayment = async (amount = this.props.contract.tiercePrice) => {
    const confirmAmount = await new Promise((resolve, reject) => {
      const node = document.createElement("div");
      document.body.appendChild(node);
      ReactDOM.render(
        React.createElement(AddPaymentModal, {
          onConfirm: (amount) => resolve(amount),
          onClose: () => reject(),
          amount,
        }),
        node,
      );
      node.remove();
    });

    await graphandClient.getModel("Data:payments").create({
      amount: confirmAmount ?? amount,
      contract: this.props.contract._id,
    });
  };

  renderLine(index, total, year) {
    const props = this.props;
    total = total - year * this.props.contract.yearPrice;
    total = total < 0 ? 0 : total;
    const _relTotal = Math.ceil((total - this.props.contract.tiercePrice * (index - 1)) * 100) / 100;
    let relTotal = _relTotal > this.props.contract.tiercePrice ? this.props.contract.tiercePrice : _relTotal;
    relTotal = relTotal < 0 ? 0 : relTotal;

    return (
      <li>
        <a
          href="#"
          className="block hover:bg-gray-50"
          onClick={() => {
            if (
              relTotal < this.props.contract.tiercePrice &&
              total + this.props.contract.tiercePrice >= Math.floor(this.props.contract.tiercePrice * index) &&
              graphandClient.authmanager.user &&
              graphandClient.authmanager.user.isAdmin()
            ) {
              this.handleAddPayment(Math.ceil((this.props.contract.tiercePrice - relTotal) * 100) / 100);
            } else if (relTotal >= this.props.contract.tiercePrice) {
              props.contract.downloadAttestation(index + -1 + year * this.props.contract.quarter);
            }
          }}
        >
          <div className="px-4 py-4 sm:px-6">
            <div className="flex items-center justify-between">
              <div className="font-medium text-secondary-600 truncate">Paiement {index}</div>
              <div className="ml-2 flex-shrink-0 flex text-sm">
                {relTotal < this.props.contract.tiercePrice ? (
                  <div className="flex items-center text-yellow-500">
                    <div className="h-3 w-3 rounded-full bg-yellow-500 mr-2" /> Paiement en attente
                  </div>
                ) : (
                  <div className="flex items-center text-emerald-500">
                    <div className="h-3 w-3 rounded-full bg-emerald-500 mr-2" /> Payé
                  </div>
                )}
              </div>
            </div>
            <div className="mt-2 flex justify-between">
              <div className="sm:flex">
                <div className="flex items-center text-sm text-gray-500">
                  {relTotal} / {this.props.contract.tiercePrice} €
                </div>
              </div>
            </div>
          </div>
        </a>
      </li>
    );
  }

  render() {
    const props = this.props;
    return graphandClient
      .getModel("Data:payments")
      .getList({ query: { contract: props.contract._id } })
      .suspense((list) => {
        const total = Math.ceil(list.reduce((total, p) => total + p.amount, 0) * 100) / 100;
        const percentYear = Math.ceil((total / this.props.contract.yearPrice) * 100);
        let years =
          percentYear % 100 === 0 ? Math.ceil(total / this.props.contract.yearPrice) + 1 : Math.floor(total / this.props.contract.yearPrice) + 1;
        years = years > 0 ? years : 0;
        return (
          <div className="space-y-6">
            {Array(years)
              .fill(null)
              .map((_, _year) => {
                const year = years - _year;
                return (
                  <div className="bg-white overflow-hidden sm:rounded-lg sm:shadow">
                    <div className="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
                      <h3 className="text-lg leading-6 font-medium text-gray-900">Année {year}</h3>
                    </div>
                    <ul className="divide-y divide-gray-200" aria-disabled="true">
                      {Array(
                        props.contract["payment-mode"] === "year"
                          ? 1
                          : props.contract["payment-mode"] === "semester"
                          ? 2
                          : props.contract["payment-mode"] === "trimester"
                          ? 4
                          : 0,
                      )
                        .fill(null)
                        .map((_, index) => this.renderLine(index + 1, total, year - 1))}
                    </ul>
                  </div>
                );
              })}
            {graphandClient.authmanager.user.isAdmin() ? (
              <div className="bg-white overflow-hidden sm:rounded-lg sm:shadow">
                <div className="bg-white px-4 py-5 border-b border-gray-200 sm:px-6">
                  <h3 className="leading-2 font-medium text-gray-900">Total des paiements : {total.toString()} €</h3>
                </div>
                <ul className="px-4 py-4 sm:px-6" aria-disabled="true">
                  <CrudButton type="create" label="Enregistrer un paiement" onClick={() => this.handleAddPayment(this.props.contract.tiercePrice)} />
                  <CrudButton type="update" label="Gérer les paiements" onClick={() => this.handleManagePayments()} />
                </ul>
              </div>
            ) : null}
          </div>
        );
      });
  }
}

class ContractRCProDetailsClaims extends React.Component {
  render() {
    const props = this.props;
    return (
      <div className="ClaimsList p-3">
        <div className="my-2 md:flex md:items-center md:justify-between">
          <div className="flex-1 min-w-0" />
          <div className="mt-4 flex-shrink-0 flex md:mt-0 md:ml-4">
            {graphandClient.authmanager.user?.isAdmin() ? (
              <CrudButton
                type={"create"}
                label={"Ajouter un sinistre"}
                onClick={() => this.props.history.push(`/contracts-rc-pro/${this.props.contract._id}/details/claims/add`)}
              />
            ) : null}
          </div>
        </div>
        <GraphandUIList
          controls={(claim) => (
            <Menu.Items className="absolute right-0 w-56 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-10 overflow-hidden">
              <Menu.Item>
                {({ active }) => (
                  <button
                    className={`${active ? "bg-gray-500 text-white" : "text-gray-900"} group flex items-center w-full px-2 py-2 text-sm`}
                    onClick={() => {
                      const node = document.createElement("div");
                      document.body.appendChild(node);
                      ReactDOM.render(
                        React.createElement(PreviewClaimModal, {
                          onClose: () => null,
                          _id: claim._id,
                        }),
                        node,
                      );
                      node.remove();
                    }}
                    type="button"
                  >
                    Aperçu
                  </button>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <button
                    className={`${active ? "bg-gray-500 text-white" : "text-gray-900"} group flex items-center w-full px-2 py-2 text-sm`}
                    onClick={() => {
                      const node = document.createElement("div");
                      document.body.appendChild(node);
                      ReactDOM.render(
                        React.createElement(UpdateClaimModal, {
                          onClose: () => null,
                          claim,
                        }),
                        node,
                      );
                      node.remove();
                    }}
                    type="button"
                  >
                    Modifier
                  </button>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <button
                    className={`${active ? "bg-gray-500 text-white" : "text-gray-900"} group flex items-center w-full px-2 py-2 text-sm`}
                    onClick={() => {
                      const node = document.createElement("div");
                      document.body.appendChild(node);
                      ReactDOM.render(
                        React.createElement(UpdateClaimDocumentsModal, {
                          onClose: () => null,
                          claim,
                        }),
                        node,
                      );
                      node.remove();
                    }}
                    type="button"
                  >
                    Documents
                  </button>
                )}
              </Menu.Item>
            </Menu.Items>
          )}
          map={["claimRef", "date", "statut"]}
          model={graphandClient.getModel("Data:claims")}
          query={{ contract: props.contract._id }}
          searchOptions={{ label: null, placeholder: "Rechercher un sinistre" }}
        />
      </div>
    );
  }
}

class ContractRCProDetailsClaimCreate extends React.Component {
  handleSubmit = async (values) => {
    if (!this.props.contract._id) {
      return null;
    }
    values.contract = this.props.contract._id;
    await graphandClient.getModel("Data:claims").create(values);
    this.props.history.push(`/contracts-rc-pro/${this.props.contract._id}/details/claims`);
  };

  render() {
    const props = this.props;

    return (
      <div className="ClaimCreate p-3">
        <GraphandForm model={graphandClient.getModel("Data:claims")} onSubmit={this.handleSubmit}>
          {({ formRef, handleSubmit, fields }) => (
            <form ref={formRef} onSubmit={handleSubmit} className="space-y-8 divide divide-y divide-gray-200">
              <div className="mb-6">
                <Heading title={"Ajouter un sinistre"} />
                <div className="grid grid-cols-3 gap-4">
                  <div className="col-span-1">{fields.render("claimRef")}</div>
                  <div className="col-span-1">{fields.render("nature")}</div>
                  <div className="col-span-1">{fields.render("statut")}</div>
                  <div className="col-span-1">{fields.render("amount")}</div>
                  <div className="col-span-1">{fields.render("feesAmount")}</div>
                  <div className="col-span-1">{fields.render("appealAmount")}</div>
                  <div className="col-span-1">{fields.render("provision")}</div>
                  <div className="col-span-1">{fields.render("expertProvision")}</div>
                  <div className="col-span-1">{fields.render("appealProvision")}</div>
                  <div className="col-span-1">{fields.render("date")}</div>
                  <div className="col-span-1">{fields.render("payDate")}</div>

                  <div className="col-span-1">{fields.render("startDate")}</div>
                  <div className="col-span-1">{fields.render("endDate")}</div>
                  <div className="col-span-1">{fields.render("openingDate")}</div>

                  <div className="col-span-3">{fields.render("circonstances")}</div>

                  <div className="col-span-3">{fields.render("observations")}</div>

                  <div className="col-span-3">{fields.render("comments")}</div>
                </div>
              </div>
              <div className="pt-6">
                <Heading title={"Tiers lésé"} />
                <div className="grid grid-cols-3 gap-4">
                  <div className="col-span-1">{fields.render("firstname")}</div>
                  <div className="col-span-1">{fields.render("lastname")}</div>
                  <div className="col-span-1">{fields.render("email")}</div>
                  <div className="col-span-1">{fields.render("phone")}</div>
                  <div className="col-span-1">{fields.render("assureur")}</div>
                  <div className="col-span-3">{fields.render("address")}</div>
                  <div className="col-span-1">{fields.render("postalcode")}</div>
                  <div className="col-span-1">{fields.render("city")}</div>
                  <div className="col-span-3 text-right mt-6">
                    <CrudButton type="submit" />
                  </div>
                </div>
              </div>
            </form>
          )}
        </GraphandForm>
      </div>
    );
  }
}

class ContractRCProDetailsClaimUpdate extends React.Component {
  handleSubmit = async (values) => {
    const claim = await graphandClient.getModel("Data:claims").get(this.props.match.params.id);
    claim.update({ set: values });
    this.props.history.push("/claims");
  };

  render() {
    const props = this.props;

    return (
      <div className="ClaimUpdate p-3">
        <Heading title={"Modifier le sinistre"} />
        {graphandClient
          .getModel("Data:claims")
          .get(this.props.match.params.id)
          .suspense((claim) => (
            <GraphandForm
              instance={claim}
              model={graphandClient.getModel("Data:claims")}
              onSubmit={this.handleSubmit}
              map={["name", "description", "date"]}
              controls={[
                <div className="text-right mt-8">
                  <CrudButton type={"submit"} />
                </div>,
              ]}
            />
          ))}
      </div>
    );
  }
}

class ContractRCProDetails extends React.Component {
  render() {
    const props = this.props;
    return graphandClient
      .getModel("Data:contrat-rc-pro")
      .get(props.match.params.id)
      .suspense((contract) => {
        return (
          <div className="py-8 sm:px-8 bg-gray-100">
            <div className="max-w-screen-xl w-full mx-auto space-y-4 sm:space-y-8 ContractRCProUpdate">
              <div className="contract-header">
                <Breadcrumbs
                  pages={[
                    {
                      name: "contracts-rc-pro",
                      label: "Portefeuille",
                    },
                    { label: `Détails` },
                  ]}
                />
                <Heading title={contract.isProject() ? "Détails du projet" : `Détails du contrat n°${contract.renderFieldView("code")}`} />
                <Tabs
                  items={[
                    {
                      link: props.match.url,
                      label: "Détails",
                      icon: InformationCircleIcon,
                    },
                    {
                      link: `${props.match.url}/medias`,
                      label: "Documents",
                      icon: PhotographIcon,
                    },
                    contract.isProject()
                      ? false
                      : {
                          link: `${props.match.url}/payments`,
                          label: "Paiements",
                          icon: CreditCardIcon,
                        },
                    contract.isProject()
                      ? false
                      : {
                          link: `${props.match.url}/claims`,
                          label: "Sinitres",
                          icon: ExclamationIcon,
                        },
                  ].filter(Boolean)}
                />
              </div>
              <div className="flex flex-col">
                <div className="py-2 align-middle inline-block min-w-full pb-4">
                  <Switch>
                    <Route
                      exact
                      path={props.match.url}
                      component={(_props) =>
                        React.createElement(ContractRCProDetailsDetails, {
                          ...props,
                          ..._props,
                          contract,
                        })
                      }
                    />
                    <Route
                      path={`${props.match.url}/medias`}
                      component={(_props) =>
                        React.createElement(ContractRCProDetailsMedias, {
                          ...props,
                          ..._props,
                          contract,
                        })
                      }
                    />
                    <Route
                      path={`${props.match.url}/payments`}
                      component={(_props) =>
                        React.createElement(ContractRCProDetailsPayments, {
                          ...props,
                          ..._props,
                          contract,
                        })
                      }
                    />
                    <Route
                      path={`${props.match.url}/claims`}
                      exact
                      component={(_props) =>
                        React.createElement(ContractRCProDetailsClaims, {
                          ...props,
                          ..._props,
                          contract,
                        })
                      }
                    />
                    <Route
                      path={`${props.match.url}/claims/add`}
                      exact
                      component={(_props) =>
                        React.createElement(ContractRCProDetailsClaimCreate, {
                          ...props,
                          ..._props,
                          contract,
                        })
                      }
                    />
                    <Route
                      path={`${props.match.url}/claims/:id`}
                      exact
                      component={(_props) =>
                        React.createElement(ContractRCProDetailsClaimUpdate, {
                          ...props,
                          ..._props,
                          contract,
                        })
                      }
                    />
                  </Switch>
                </div>
              </div>
            </div>
          </div>
        );
      });
  }
}

export default ContractRCProDetails;
