import { GraphandModelData, GraphandFieldRelation } from "graphand-js";
import React from "react";
import InputRelation from "../fields/inputs/InputRelation";

class Claim extends GraphandModelData {
  static apiIdentifier = "claims";
  static defaultField = "name";
}

Claim.customFields({
  contract: new GraphandFieldRelation({
    name: "Contrat RC Pro",
    ref: "Data:contrat-rc-pro",
    description: "Le code est le numéro unique du contrat (sans le préfixe LNA-TPEBAT 2020-)",
    renderInput(props) {
      // props.options.getLabelField = (item) => {
      //   return ContractRCPro.formatLabelField(item, item._id);
      // };
      return React.createElement(InputRelation, { ...props, field: this });
    },
  }),
});

export default Claim;
