import React from "react";
import Breadcrumbs from "../../../components/Breadcrumbs";
import ROLES from "../../../enums/roles";
import graphandClient from "../../../utils/graphand";
import Heading from "../../../components/Heading";
import { GraphandForm } from "graphand-react";
import CrudButton from "../../../components/CrudButton";

class CustomerCreate extends React.Component {
  handleSubmit = async (values) => {
    const account = await graphandClient.getModel("Account").create({
      ...values,
      role: ROLES.CUSTOMER,
      password: Math.random().toString(36).substr(2, 9),
    });
    if (account) {
      this.props.history.push("/customers");
    }
  };

  render() {
    return (
      <div className="py-8 sm:px-8 bg-gray-100">
        <div className="max-w-screen-xl w-full mx-auto space-y-4 sm:space-y-8 CustomerCreate">
          <Breadcrumbs
            pages={[
              { name: "customers", label: "Clients" },
              { name: "customers/add", label: "Ajouter un client" },
            ]}
          />
          <Heading title={"Ajouter un client"}></Heading>
          <div className="my-4 p-8 bg-white border border-gray-200 rounded-md shadow">
            <GraphandForm
              map={["agency", "firstname", "lastname", "email", "phone", "slug", "address", "postal", "city", "iban", "bic", "bank-name"]}
              model={graphandClient.getModel("Account")}
              onSubmit={this.handleSubmit}
              controls={[
                <div className="text-right mt-8">
                  <CrudButton type={"submit"} />
                </div>,
              ]}
            />
          </div>
        </div>
      </div>
    );
  }
}

export default CustomerCreate;
