import { format } from "date-fns";
import { fr } from "date-fns/locale";
import React from "react";

const ViewDate = ({ value, field }) => {
  if (!value) {
    return null;
  }

  return <span>{format(value, field.time ? "Pp" : "P", { locale: fr })}</span>;
};

export default ViewDate;
