import React from "react";
import Breadcrumbs from "../../../components/Breadcrumbs";
import Heading from "../../../components/Heading";
import ROLES from "../../../enums/roles";
import Account from "../../../models/Account";
import graphandClient from "../../../utils/graphand";
import GraphandUIList from "../../../components/GraphandUIList";
import { Menu } from "@headlessui/react";
import { GraphandForm } from "graphand-react";
import { GraphandFieldText } from "graphand-js";

class CustomersList extends React.Component {
  state = {
    q: "",
    ids: undefined,
  };

  constructor(props) {
    super(props);

    this.searchTemplate = ({ fields, formRef, handleSubmit }) => (
      <form onSubmit={handleSubmit} ref={formRef}>
        {fields.render("q", { label: null, placeholder: "Rechercher un client" })}
      </form>
    );
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.q && this.state.q !== prevState.q) {
      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout);
      }

      const EsMapping = graphandClient.getModel("EsMapping");
      this.searchTimeout = setTimeout(
        () =>
          EsMapping.search("61116081286d046964c79ba8", {
            query: {
              query_string: {
                query: `*${this.state.q}*`,
              },
            },
            size: 10,
          }).then((res) => {
            const ids = res.hits.hits.map((r) => r._id);
            this.setState({ ids });
          }),
        500,
      );
    }
  }

  render() {
    return (
      <div className="py-8 sm:px-8 bg-gray-100">
        <div className="max-w-screen-xl w-full mx-auto space-y-4 sm:space-y-8">
          <Breadcrumbs pages={[{ name: "customers", label: "Clients" }]} />
          <Heading title={"Liste des clients"} />
          <GraphandForm values={{ q: this.state.q }} onChange={({ q }) => this.setState({ q })} fields={{ q: new GraphandFieldText({}) }}>
            {this.searchTemplate}
          </GraphandForm>
          <GraphandUIList
            map={["fullname", "email", "agency"]}
            model={graphandClient.getModel("Account")}
            query={{ role: ROLES.CUSTOMER }}
            ids={this.state.q && this.state.ids ? this.state.ids : undefined}
            controls={
              graphandClient.authmanager.user?.isAdmin()
                ? (item) => (
                    <Menu.Items className="absolute right-0 w-56 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-10 overflow-hidden">
                      {item.status !== "draft" && item.status !== "archived" ? (
                        <Menu.Item>
                          {({ active }) => (
                            <button
                              className={`${active ? "bg-gray-500 text-white" : "text-gray-900"} group flex items-center w-full px-2 py-2 text-sm`}
                              onClick={() => this.props.history.push(`/customers/${item._id}`)}
                              type="button"
                            >
                              Modifier
                            </button>
                          )}
                        </Menu.Item>
                      ) : null}
                    </Menu.Items>
                  )
                : undefined
            }
          />
        </div>
      </div>
    );
  }
}

export default CustomersList;
