import React from "react";
import Breadcrumbs from "../../../components/Breadcrumbs";
import Heading from "../../../components/Heading";
import ROLES from "../../../enums/roles";
import Account from "../../../models/Account";
import graphandClient from "../../../utils/graphand";
import GraphandUIList from "../../../components/GraphandUIList";
import { Menu } from "@headlessui/react";
import { GraphandForm } from "graphand-react";
import { GraphandFieldText } from "graphand-js";

import Tabs from "../../../components/Tabs";
import { CreditCardIcon, CalendarIcon, ExclamationIcon } from "@heroicons/react/outline";
import { Route, Switch } from "react-router-dom";
import NextPayments from "../NextPayments";
import LatePayments from "../LatePayments";

class PaymentsList extends React.Component {
  render() {
    const { props } = this;
    return (
      <div className="py-8 sm:px-8 bg-gray-100">
        <div className="max-w-screen-xl w-full mx-auto space-y-4 sm:space-y-8">
          <Breadcrumbs pages={[{ name: "payments", label: "Paiements" }]} />
          <Heading title={"Liste des paiements"} />
          <div>
            <Tabs
              items={[
                {
                  link: props.match.url,
                  label: "Historique des paiements",
                  icon: CreditCardIcon,
                },
                {
                  link: `${props.match.url}/next`,
                  label: "Paiements à venir",
                  icon: CalendarIcon,
                },
                {
                  link: `${props.match.url}/late`,
                  label: "Paiements en retard",
                  icon: ExclamationIcon,
                },
              ].filter(Boolean)}
            />

            <Switch>
              <Route
                exact
                path={props.match.url}
                component={() => <GraphandUIList map={["contract", "amount", "createdAt"]} model={graphandClient.getModel("Data:payments")} />}
              />
              <Route exact path={`${props.match.url}/next`} component={(_props) => React.createElement(NextPayments, { ...props, ..._props })} />
              <Route exact path={`${props.match.url}/late`} component={(_props) => React.createElement(LatePayments, { ...props, ..._props })} />
            </Switch>
          </div>
        </div>
      </div>
    );
  }
}

export default PaymentsList;
