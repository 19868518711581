"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var DataFieldTypes;
(function (DataFieldTypes) {
    DataFieldTypes["ID"] = "Id";
    DataFieldTypes["TEXT"] = "Text";
    DataFieldTypes["NUMBER"] = "Number";
    DataFieldTypes["BOOLEAN"] = "Boolean";
    DataFieldTypes["RELATION"] = "Relation";
    DataFieldTypes["COLOR"] = "Color";
    DataFieldTypes["DATE"] = "Date";
    DataFieldTypes["JSON"] = "JSON";
})(DataFieldTypes || (DataFieldTypes = {}));
exports.default = DataFieldTypes;
