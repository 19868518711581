import React from "react";

import graphandClient from "../../../utils/graphand";
import Breadcrumbs from "../../../components/Breadcrumbs";
import Heading from "../../../components/Heading";
import CrudButton from "../../../components/CrudButton";
import { MailIcon, PhoneIcon } from "@heroicons/react/outline";

class ContactsList extends React.Component {
  render() {
    const headingActions = [];
    if (graphandClient.authmanager.user?.isAdmin()) {
      headingActions.push({
        label: "Ajouter une coordonnée",
        link: "/contacts/add",
        crudType: "create",
      });
    }

    return (
      <div className="py-8 sm:px-8 bg-gray-100">
        <div className="max-w-screen-xl w-full mx-auto space-y-4 sm:space-y-8 ContractRCProUpdate">
          <Breadcrumbs pages={[{ name: "contacts", label: "Coordonnées" }]} />
          <Heading title={"Coordonnées"} actions={headingActions} />
          <div className="py-4">
            {graphandClient
              .getModel("Data:contacts")
              .getList({ query: { email: { $ne: null } } })
              .suspense((list) => {
                return list?.length ? (
                  <ul className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
                    {list.map((contact, index) => {
                      const image = contact.image;
                      return (
                        <li key={index} className="col-span-1 bg-white rounded-lg shadow divide-y divide-gray-200">
                          <div className="w-full flex items-center justify-between p-6">
                            <div className="flex-1 truncate">
                              <div className="flex items-center space-x-3">
                                <h3 className="text-gray-900 text-sm font-medium truncate">{contact.name}</h3>
                                {contact.role ? (
                                  <span className="flex-shrink-0 inline-block px-2 py-0.5 text-emerald-800 text-xs font-medium bg-emerald-100 rounded-full">
                                    {contact.role}
                                  </span>
                                ) : null}
                              </div>
                              {contact.position ? (
                                <p className="mt-1 text-gray-500 text-sm truncate italic text-primary">{contact.position}</p>
                              ) : null}
                            </div>
                            {image ? (
                              <img className="w-10 h-10 bg-gray-300 rounded-full flex-shrink-0 ml-2" src={image.getUrl()} alt={contact.name} />
                            ) : (
                              <span className="inline-block w-10 h-10 flex-shrink-0 ml-2 rounded-full overflow-hidden bg-gray-100">
                                <svg className="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                                  <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                                </svg>
                              </span>
                            )}
                            {graphandClient.authmanager.user?.isAdmin() ? (
                              <div className="p-2 flex flex-col -mt-6 -mr-6 items-end">
                                <CrudButton
                                  type={"update"}
                                  reduced
                                  rounded
                                  size="xs"
                                  onClick={() => this.props.history.push(`/contacts/${contact._id}`)}
                                  className="px-1 py-1 mb-2"
                                />
                                <CrudButton type={"delete"} reduced rounded size="xs" onClick={() => contact.delete()} className="px-1 py-1" />
                              </div>
                            ) : null}
                          </div>
                          <div>
                            <div className="-mt-px flex divide-x divide-gray-200">
                              <div className="w-0 flex-1 flex">
                                <a
                                  href={`mailto:${contact.email}`}
                                  className="relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500"
                                >
                                  {contact.email}
                                </a>
                              </div>
                              {contact.phone ? (
                                <div className="-ml-px w-0 flex-1 flex">
                                  <a
                                    href={`tel:${contact.phone}`}
                                    className="relative w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-br-lg hover:text-gray-500"
                                  >
                                    {contact.phone}
                                  </a>
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                ) : null;
              })}
          </div>
        </div>
      </div>
    );
  }
}

export default ContactsList;
