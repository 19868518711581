import React from "react";
import graphandClient from "./graphand";
import Login from "../components/Login";

export const loggedGuard = (Component, props) => {
  const _guard = class extends React.Component {
    loggedSubscription = graphandClient.authmanager.loggedSubject.subscribe((logged) => this.setState({ logged }));
    state = {
      logged: graphandClient.authmanager.logged,
    };

    componentWillUnmount() {
      this.loggedSubscription && this.loggedSubscription.unsubscribe();
    }

    render() {
      const { logged } = this.state;

      if (logged) {
        return React.createElement(Component, this.props);
      }

      return React.createElement(Login, { ...props, ...this.props});
    }
  };

  return _guard;
};

export const notLoggedGuard = (Component, props) => {
  const _guard = class extends React.Component {
    loggedSubscription = graphandClient.authmanager.loggedSubject.subscribe((logged) => this.setState({ logged }));
    state = {
      logged: graphandClient.authmanager.logged,
    };

    componentWillUnmount() {
      this.loggedSubscription.unsubscribe();
    }
    
    
    render() {
      const { logged } = this.state;

      if (!logged) {
        return React.createElement(Component, { ...props, ...this.props});
      }

      return "notLoggedGuard";
    }
  };

  return _guard;
};

export const partnerGuard = (Component) => {
  const _guard = class extends React.Component {
    userSubscription = graphandClient.authmanager.userSubject.subscribe((user) => this.setState({ user }));
    state = {
      user: graphandClient.authmanager.user,
    };

    componentWillUnmount() {
      this.userSubscription.unsubscribe();
    }

    render() {
      const { user } = this.state;

      if (user?.isPartner()) {
        return React.createElement(Component, this.props);
      }
      if (graphandClient.authmanager.loading) {
        return "Chargement ...";
      }

      return React.createElement(Login);
    }
  };

  return _guard;
};

export const adminGuard = (Component) => {
  const _guard = class extends React.Component {
    userSubscription = graphandClient.authmanager.userSubject.subscribe((user) => this.setState({ user }));
    state = {
      user: graphandClient.authmanager.user,
    };

    componentWillUnmount() {
      this.userSubscription.unsubscribe();
    }

    render() {
      const { user } = this.state;

      if (user?.isAdmin()) {
        return React.createElement(Component, this.props);
      }
      if (graphandClient.authmanager.loading) {
        return "Chargement ...";
      }

      return React.createElement(Login);
    }
  };

  return _guard;
};
