import React from "react";
import PropTypes from "prop-types";
import { PlusCircleIcon, EyeIcon, PencilAltIcon, TrashIcon, XCircleIcon, CheckCircleIcon } from "@heroicons/react/outline";
import "./index.scss";

class CrudButton extends React.Component {
  static typesDefaultValues = {
    create: {
      color: "emerald",
      label: "Ajouter",
      icon: PlusCircleIcon,
    },
    read: {
      color: "secondary",
      label: "Voir",
      icon: EyeIcon,
    },
    update: {
      color: "secondary",
      label: "Modifier",
      icon: PencilAltIcon,
    },
    delete: {
      color: "red",
      label: "Supprimer",
      icon: TrashIcon,
    },
    submit: {
      color: "secondary",
      label: "Valider",
      icon: CheckCircleIcon,
    },
    cancel: {
      color: "gray",
      label: "Annuler",
      icon: XCircleIcon,
    },
    none: {
      color: "secondary",
      label: null,
      icon: null,
    },
  };

  componentDidMount(prevProps, prevState, snapshot) {
    const types = Object.keys(CrudButton.typesDefaultValues);
    if (!types.includes(this.props.type)) {
      this.props.type = "none";
    }
  }

  getDefaultTypeClass() {
    const color = CrudButton.typesDefaultValues[this.props.type].color;
    const sizeClasses = {
      xs: "px-1 py-1 text-xs",
      sm: "px-3 py-2 text-sm",
      md: "px-4 py-2 text-sm",
      lg: "px-5 py-3 text-md",
    };

    let classes = `${this.props.className} ${sizeClasses[this.props.size]} ${this.props.rounded ? "rounded-full" : ""}`;
    if (color === "emerald") {
      return classes + ` text-white bg-${color}-500 hover:bg-${color}-600 focus:ring-${color}-500`;
    }

    return classes + ` text-${color}-700 bg-${color}-100 hover:bg-${color}-200 focus:ring-${color}-500`;
  }

  getLabel() {
    return this.props.label || CrudButton.typesDefaultValues[this.props.type].label;
  }

  getIcon() {
    return this.props.icon || CrudButton.typesDefaultValues[this.props.type].icon;
  }

  getContent() {
    const color = CrudButton.typesDefaultValues[this.props.type].color;
    const Icon = this.getIcon();
    const colorClass = color === "emerald" ? `text-white` : `text-${color}-800`;
    if (this.props.reduced) {
      return <Icon className={`h-4 w-4 ${colorClass}`} aria-hidden="true" />;
    }

    if (!this.props.withIcon) {
      return this.getLabel();
    }

    return (
      <>
        {<Icon className={`h-4 w-4 mr-2 -ml-1 ${colorClass}`} aria-hidden="true" />}
        {this.getLabel()}
      </>
    );
  }

  render() {
    const { type, onClick } = this.props;
    const content = this.getContent();

    return (
      <button
        className={
          "crud-button transition inline-flex items-center border border-transparent leading-4 font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 " +
          this.getDefaultTypeClass()
        }
        onClick={onClick}
        type={type === "submit" ? "submit" : "button"}
      >
        {content}
      </button>
    );
  }
}

CrudButton.propTypes = {
  type: PropTypes.string,
  label: PropTypes.string,
};

CrudButton.defaultProps = {
  type: "none",
  label: null,
  icon: null,
  onclick: null,
  reduced: false,
  withIcon: true,
  rounded: false,
  size: "sm",
  className: null,
};

export default CrudButton;
