"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.schemaField = exports.ownProperty = void 0;
const ownProperty = (getDefaultValue, assignRecursive) => {
    return (target, key) => {
        const _key = `#${key}`;
        Object.defineProperty(target, key, {
            get() {
                if (!this.hasOwnProperty(_key)) {
                    this[_key] = (getDefaultValue === null || getDefaultValue === void 0 ? void 0 : getDefaultValue.apply(this)) || null;
                }
                try {
                    if (assignRecursive && this !== this.__proto__ && this.__proto__[key] !== undefined) {
                        return assignRecursive(this[_key], this.__proto__[key]);
                    }
                }
                catch (e) {
                    console.warn(`Unable to process recursive @ownProperty ${key}`);
                }
                return this[_key];
            },
            set(value) {
                this[_key] = value;
            },
        });
    };
};
exports.ownProperty = ownProperty;
const schemaField = (type, options) => {
    return (target, key) => { };
};
exports.schemaField = schemaField;
