import Graphand, { GraphandFieldId, GraphandFieldRelation, GraphandModel, GraphandModelMedia } from "graphand-js";
import React from "react";
import Account from "../models/Account";
import Activity from "../models/Activity";
import Agency from "../models/Agency";
import ContractRCPro from "../models/ContractRCPro";
import Media from "../models/Media";
import Claim from "../models/Claim";
import GraphandPluginReact from "graphand-react";
import InputText from "../fields/inputs/InputText";
import InputNumber from "../fields/inputs/InputNumber";
import InputBoolean from "../fields/inputs/InputBoolean";
import ViewId from "../fields/views/ViewId";
import ViewText from "../fields/views/ViewText";
import ViewNumber from "../fields/views/ViewNumber";
import ViewRelation from "../fields/views/ViewRelation";
import ViewDate from "../fields/views/ViewDate";
import ViewBoolean from "../fields/views/ViewBoolean";
import InputRelation from "../fields/inputs/InputRelation";
import InputDate from "../fields/inputs/InputDate";
import mime from "mime";
import { GraphandPlugin as RxjsAuthGraphandPlugin, AuthTokenStorage } from "rxjs-auth";

const graphandClient = Graphand.createClient({
  // host: "api.graphand.io.local:1337",
  // ssl: false,
  project: "5ea9879bdbbba6001aec717c",
  realtime: true,
  models: [Account, Agency, ContractRCPro, Activity, Media, Claim],
  plugins: [
    new GraphandPluginReact({
      fields: {
        Id: {
          view: ViewId,
        },
        Text: {
          view: ViewText,
          input: InputText,
        },
        Number: {
          view: ViewNumber,
          input: InputNumber,
        },
        Relation: {
          view: ViewRelation,
          input: InputRelation,
        },
        Date: {
          view: ViewDate,
          input: InputDate,
        },
        Boolean: {
          view: ViewBoolean,
          input: InputBoolean,
        },
      },
    }),
    new RxjsAuthGraphandPlugin({
      authClientOptions: {
        tokenStorage: AuthTokenStorage.localStorage,
      },
    }),
  ],
});

GraphandFieldId.prototype.options = {
  tableCellProps: () => {
    return { className: "truncate w-20" };
  },
};
GraphandFieldRelation.prototype.getDefaultField = async function () {
  if (this.ref === "Account") {
    return "fullname";
  }

  if (this.ref === "Data:agency") {
    return "name";
  }

  if (this.ref === "Data:contrat-rc-pro") {
    return "code";
  }

  if (this.ref === "Data:activities") {
    return "name";
  }

  if (this.ref === "Data:claims") {
    return "name";
  }

  return "_id";

  // const dataModel = graphandClient.getModel("DataModel").get()
  // const model = this.model || graphandClient.getModel(this.ref);
  // if (model.defaultField) {
  //   return model.defaultField;
  // }
  //
  // try {
  //   const { 0: imageField } = Object.keys(model.fields).filter((key) => {
  //     return model.fields[key] instanceof GraphandFieldRelation && model.fields[key].ref === "Media" && !model.fields[key].multiple;
  //   });
  //   return imageField || "_id";
  // } catch (e) {
  //   return "_id";
  // }
};
GraphandFieldRelation.prototype.options = {
  tableCellProps: (field) => {
    return {};
    // const defaultField = field.getDefaultField();
    // const displayField = field.model.fields[defaultField];
    // const displayFieldOptions = displayField.options || {};
    // // prevent infinite loop
    // const cellProps = typeof displayField.options?.tableCellProps === "function" ? {} : displayFieldOptions.tableCellProps || {};
    //
    // if (displayField instanceof GraphandFieldRelation && displayField.ref === "Media" && !displayField.multiple) {
    //   return { className: "w-14", ...cellProps };
    // }
    //
    // return cellProps;
  },
};

GraphandModel.defaultField = "_id";

export default graphandClient;
