import React from "react";
import { Translation } from "react-i18next";

const InputBoolean = ({ options, value, onChange, errors, id, slug, field, inputRef }) => {
  let label = field.__dataField?.name || options.label;
  if (label === undefined) {
    label = <Translation>{(t) => t(`labels.fields.${slug}`)}</Translation>;
  }

  return (
    <div {...options}>
      <div className="flex items-center mb-2">
        <input
          ref={inputRef}
          checked={value}
          className="h-4 w-4 text-secondary-600 focus:ring-secondary-500 border-gray-300 rounded"
          id={id}
          name={id}
          onChange={(e) => onChange(e.currentTarget.checked)}
          type="checkbox"
        />
        <label className="ml-2 block text-sm text-gray-900" htmlFor={id}>
          {label}
        </label>
      </div>
      {errors.map((e) => (
        <p className="mt-2 text-sm text-red-600" id="email-error">
          {e.message}
        </p>
      ))}
    </div>
  );
};

export default InputBoolean;
