"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const utils_1 = require("../utils");
class AggregationExecutor {
    constructor(options) {
        var _a;
        this._id = options._id;
        this.vars = (_a = options.vars) !== null && _a !== void 0 ? _a : {};
        this.client = options.client;
    }
    static clearCache(cacheKey) {
        if (cacheKey) {
            delete this.cache[cacheKey];
        }
        else {
            this.cache = {};
        }
    }
    cache(key) {
        this.cacheKey = this.getCacheKey(key);
        return this;
    }
    clearCache(key) {
        const _cacheKey = this.getCacheKey(key);
        const { constructor } = Object.getPrototypeOf(this);
        return constructor.clearCache(_cacheKey);
    }
    then() {
        return this.execute().then(...arguments);
    }
    catch() {
        return this.execute().catch(...arguments);
    }
    toPromise() {
        return this.execute();
    }
    getCacheKey(key = this.vars) {
        return `${this._id}:${typeof key === "string" ? key : JSON.stringify(key)}`;
    }
    async _exec() {
        const { data } = await this.client._axios.post(`/aggregations/${this._id}/execute`, (0, utils_1.parseQuery)(this.vars));
        this.res = data;
        return data;
    }
    async execute() {
        await this.client._init();
        if (this.cacheKey) {
            const { constructor } = Object.getPrototypeOf(this);
            if (!constructor.cache[this.cacheKey]) {
                constructor.cache[this.cacheKey] = new Promise((resolve, reject) => this._exec().then(resolve).catch(reject));
            }
            return (await constructor.cache[this.cacheKey]) || {};
        }
        return (await this._exec()) || {};
    }
}
AggregationExecutor.cache = {};
exports.default = AggregationExecutor;
