import React, { Fragment } from "react";
import { NavLink, withRouter } from "react-router-dom";
import graphandClient from "../../utils/graphand";
import { Dialog, Menu, Transition } from "@headlessui/react";
import {
  ChevronLeftIcon,
  CurrencyEuroIcon,
  DocumentTextIcon,
  DotsVerticalIcon,
  FolderIcon,
  HomeIcon,
  PhoneIcon,
  UserCircleIcon,
  UsersIcon,
  XIcon,
  ShieldExclamationIcon,
} from "@heroicons/react/outline";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import resolveConfig from "tailwindcss/resolveConfig";

const tailwindConfig = resolveConfig();

const getBreakpointValue = (value) => tailwindConfig.theme.screens[value].slice(0, tailwindConfig.theme.screens[value].indexOf("px"));

const classNames = (...classes) => {
  return classes.filter(Boolean).join(" ");
};

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      isReduced: false,
    };
  }

  logout() {
    this.props.handleLogout();
    this.props.history.push("/");
  }

  componentDidMount() {
    this.userSubscription = graphandClient.authmanager.userSubject.subscribe(() => this.forceUpdate());
    this.setState({ isReduced: window.outerWidth < getBreakpointValue("md") });
  }

  componentWillUnmount() {
    this.userSubscription && this.userSubscription.unsubscribe();
  }

  render() {
    const { navigation } = this.props;
    const pathName = this.props.location.pathname.split("/").filter((p) => p);
    const basePathName = pathName[0] || "";

    graphandClient.authmanager.user?.isPartner() &&
      graphandClient.authmanager.user?.agency?.suspense((a) => {
        if (a?.raw.responsible === graphandClient.authmanager.user?._id) {
          navigation.push({
            name: "Agency",
            label: `Mon agence ${a.name}`,
            link: `/agencies/${a._id}`,
            icon: FolderIcon,
          });
        }
      });

    return (
      <>
        <Transition.Root show={this.state.isOpen} as={Fragment}>
          <Dialog
            as="div"
            static
            className="fixed inset-0 flex z-40 lg:hidden"
            open={this.state.isOpen}
            onClose={() => this.setState({ isOpen: true })}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-200 bg-opacity-75" />
            </Transition.Child>
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <div className="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-white">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute top-0 right-0 -mr-12 pt-2">
                    <button
                      className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                      onClick={() => this.setState({ isOpen: false })}
                    >
                      <span className="sr-only">Fermer le menu</span>
                      <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                    </button>
                  </div>
                </Transition.Child>
                <div className="flex-shrink-0 flex items-center px-4">
                  <img alt="LNA solutions d'assurance" className="h-16 w-auto" src="/images/lna_logo_white.png" />
                </div>
                <div className="mt-5 flex-1 h-0 overflow-y-auto">
                  <nav className="px-2">
                    <div className="space-y-1">
                      {navigation.map((item, index) => {
                        item.current =
                          basePathName && item.page
                            ? Boolean(item.page?.match(new RegExp(`^${basePathName}`)))
                            : (item.page || "") === (basePathName || "");
                        item.link = item.link || `/${item.page}`;
                        let condition = item.condition ?? true;
                        if (typeof condition === "function") {
                          condition = condition();
                        }

                        return condition && item.link ? (
                          <NavLink
                            key={index}
                            to={item.link}
                            className={classNames(
                              item.current ? "text-gray-100" : "text-gray-200 hover:text-gray-100 hover:bg-gray-50",
                              "group flex items-center px-2 py-2 text-base leading-5 font-medium rounded-md",
                            )}
                            aria-current={item.current ? "page" : undefined}
                          >
                            <item.icon
                              className={classNames(
                                item.current ? "text-secondary-500" : "text-gray-400 group-hover:text-gray-500",
                                "mr-3 flex-shrink-0 h-6 w-6",
                              )}
                              aria-hidden="true"
                            />
                            {item.label}
                          </NavLink>
                        ) : null;
                      })}
                    </div>
                  </nav>
                </div>
              </div>
            </Transition.Child>
            <div className="flex-shrink-0 w-14" aria-hidden="true">
              {/* Dummy element to force sidebar to shrink to fit close icon */}
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden lg:flex lg:flex-shrink-0">
          <div className={`flex-shrink-0 flex flex-col w-auto shadow-sm pt-6 bg-primary-700 transition`}>
            <div className="flex justify-end flex-shrink-0 px-6">
              <button className="bg-transparent focus:outline-none -mt-2 -mr-2" onClick={() => this.setState({ isReduced: !this.state.isReduced })}>
                {
                  <ChevronLeftIcon
                    className="flex-shrink-0 h-6 w-6 text-gray-100 transition"
                    aria-hidden="true"
                    style={{ transform: this.state.isReduced ? "rotate(180deg)" : "" }}
                  />
                }
              </button>
            </div>
            <div className={`flex items-center flex-shrink-0 px-6 mb-8`}>
              <img
                alt="LNA solutions d'assurance"
                className={`transition ${this.state.isReduced ? "h-8 mt-4" : "h-28 mx-12"} w-auto mx-auto`}
                src={"/images/lna_logo_dark.png"}
              />
            </div>
            <div className="flex flex-col">
              <nav className="px-3">
                <div className="space-y-1">
                  {navigation.map((item, index) => {
                    item.link = item.link || `/${item.page}`;
                    item.current =
                      basePathName && item.page
                        ? Boolean(item.page?.match(new RegExp(`^${basePathName}`)))
                        : (item.page || "") === (basePathName || "");
                    let condition = item.condition ?? true;
                    if (typeof condition === "function") {
                      condition = condition();
                    }

                    return condition && item.link ? (
                      <NavLink
                        key={index}
                        to={item.link}
                        className={classNames(
                          item.current
                            ? "text-gray-100 hover:bg-gray-50 hover:text-primary-800"
                            : "text-gray-300 hover:text-primary-800 hover:bg-gray-50",
                          this.state.isReduced ? "justify-center" : "",
                          "group flex items-center px-2 py-2 text-base leading-5 font-medium rounded-md w-full",
                        )}
                        aria-current={item.current ? "page" : undefined}
                      >
                        <item.icon
                          className={classNames(
                            item.current ? "text-secondary-500" : "text-gray-200 group-hover:text-gray-500",
                            this.state.isReduced ? "" : "mr-3",
                            "flex-shrink-0 h-6 w-6",
                          )}
                          aria-hidden="true"
                        />
                        {this.state.isReduced ? null : item.label}
                      </NavLink>
                    ) : null;
                  })}
                </div>
              </nav>
            </div>
            <div className="h-0 flex-1 flex flex-col justify-end">
              {/* User account dropdown */}
              {graphandClient.authmanager.user?.suspense((user) => (
                <Menu as="div" className="px-3 py-3 mt-6 relative inline-block text-left">
                  {({ open }) => (
                    <>
                      <div>
                        <Menu.Button className="group w-full rounded-md px-2 py-2 text-sm text-left font-medium text-gray-100 focus:outline-none">
                          <span className="flex w-full justify-between items-center">
                            <span className="flex min-w-0 items-center justify-between space-x-3 mr-2">
                              <UserCircleIcon className="flex-shrink-0 h-8 w-8 text-gray-200 group-hover:text-gray-100" aria-hidden="true" />
                              {this.state.isReduced ? null : (
                                <span className="flex-1 flex flex-col min-w-0">
                                  <span className="text-gray-100 text-sm font-medium truncate">{user.fullname}</span>
                                </span>
                              )}
                            </span>
                            <DotsVerticalIcon className="flex-shrink-0 h-5 w-5 text-secondary-200 group-hover:text-gray-100" aria-hidden="true" />
                          </span>
                        </Menu.Button>
                      </div>
                      <Transition
                        show={open}
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                      >
                        <Menu.Items
                          static
                          className="z-10 mx-3 origin-bottom absolute right-0 w-56 left-0 mt-1 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-200 focus:outline-none"
                          style={{ top: "-0.75rem", transform: "translateY(-100%)" }}
                        >
                          <div className="py-1">
                            <Menu.Item>
                              {({ active }) => (
                                <a
                                  href="#"
                                  onClick={() => this.logout()}
                                  className={classNames(active ? "bg-gray-100" : "", "block px-4 py-2 text-sm")}
                                >
                                  <FontAwesomeIcon className="mr-2" icon={faSignOutAlt} />
                                  Déconnexion
                                </a>
                              )}
                            </Menu.Item>
                          </div>
                        </Menu.Items>
                      </Transition>
                    </>
                  )}
                </Menu>
              ))}
            </div>
          </div>
        </div>
      </>
    );
  }
}

Sidebar.defaultProps = {
  navigation: [
    { name: "Dashboard", label: "Tableau de bord", link: "/", icon: HomeIcon },
    {
      name: "Tarificateur",
      label: "Tarificateur",
      page: "generator",
      icon: CurrencyEuroIcon,
      condition: () => graphandClient.authmanager.user?.isPartner(),
    },
    {
      name: "Portefeuille",
      label: "Portefeuille",
      page: "contracts-rc-pro",
      icon: DocumentTextIcon,
      condition: () => graphandClient.authmanager.user?.isPartner(),
    },
    {
      name: "Payments",
      label: "Paiements",
      page: "payments",
      icon: CurrencyEuroIcon,
      condition: () => graphandClient.authmanager.user?.isAdmin(),
    },
    {
      name: "Clients",
      label: "Clients",
      page: "customers",
      icon: UsersIcon,
      condition: () => graphandClient.authmanager.user?.isPartner(),
    },
    {
      name: "Sinistres",
      label: "Sinistres",
      page: "claims",
      icon: ShieldExclamationIcon,
      condition: () => graphandClient.authmanager.user?.isAdmin(),
    },
    {
      name: "Contacts",
      label: "Coordonnées",
      page: "contacts",
      icon: PhoneIcon,
    },
  ],
  handleLogout: null,
};

Sidebar = withRouter(Sidebar);

export default Sidebar;
