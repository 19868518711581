import { Editor } from "@tinymce/tinymce-react";
import React, { useRef } from "react";
import { Translation } from "react-i18next";

const InputTextRte = ({ options, onChange, errors, id, slug, field, inputRef, value }) => {
  inputRef = inputRef || useRef();

  const _handleChange = () => {
    const content = inputRef.current.editor.getContent();
    if (content !== value) {
      onChange(content);
    }
  };

  let label = field.__dataField?.name || options.label;
  if (label === undefined) {
    label = <Translation>{(t) => t(`labels.fields.${slug}`)}</Translation>;
  }

  return (
    <div {...options}>
      {label ? (
        <label className="block text-sm font-medium text-gray-700 mb-1" htmlFor={id}>
          {label} {field.required ? "*" : null}
        </label>
      ) : null}
      {options.description ? <p className="text-sm text-gray-500 mb-1">{options.description}</p> : null}
      <div>
        <Editor
          ref={inputRef}
          apiKey="57e9k3d96f598ujoiufqtpwdrs2gd5douatvklm2br42m0s7"
          id={id}
          init={{
            height: 500,
            menubar: false,
            statusbar: false,
            plugins: [
              "advlist autolink lists link image charmap print preview anchor",
              "searchreplace visualblocks code fullscreen",
              "insertdatetime media table paste",
            ],
            toolbar: "formatselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | undo redo",
          }}
          initialValue={value}
          onBlur={_handleChange}
        />
      </div>

      {errors.map((e) => (
        <p className="mt-2 text-sm text-red-600" id={`${e.field}:${e.code}`}>
          {e.message}
        </p>
      ))}
    </div>
  );
};

export default InputTextRte;
