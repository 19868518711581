"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ModelScopes;
(function (ModelScopes) {
    ModelScopes["Account"] = "Account";
    ModelScopes["Aggregation"] = "Aggregation";
    ModelScopes["Data"] = "Data:*";
    ModelScopes["DataField"] = "DataField";
    ModelScopes["DataModel"] = "DataModel";
    ModelScopes["Environment"] = "Environment";
    ModelScopes["EsMapping"] = "EsMapping";
    ModelScopes["Log"] = "Log";
    ModelScopes["Media"] = "Media";
    ModelScopes["Module"] = "Module";
    ModelScopes["Project"] = "Project";
    ModelScopes["Organization"] = "Organization";
    ModelScopes["OrgInvitation"] = "OrgInvitation";
    ModelScopes["Restriction"] = "Restriction";
    ModelScopes["Role"] = "Role";
    ModelScopes["Rule"] = "Rule";
    ModelScopes["Sockethook"] = "Sockethook";
    ModelScopes["SockethookHost"] = "SockethookHost";
    ModelScopes["Token"] = "Token";
    ModelScopes["User"] = "User";
    ModelScopes["Webhook"] = "Webhook";
})(ModelScopes || (ModelScopes = {}));
exports.default = ModelScopes;
