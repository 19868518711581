import { GraphandFieldRelation, GraphandModelPromise } from "graphand-js";
import React, { useEffect, useState } from "react";
import AsyncSelect from "react-select/async";
import graphandClient from "../../../utils/graphand";
import { Translation } from "react-i18next";

class InputRelation extends React.Component {
  constructor(props) {
    super(props);

    if (!props.field || !(props.field instanceof GraphandFieldRelation)) {
      throw new Error();
    }
  }

  getLabelField = (item) => {
    return this.props.options?.getLabelField?.call(this.props.options, item) || (item && item.get(item.constructor.defaultField)) || item?._id;
  };

  _promiseOptions = (inputValue) => {
    const { options, field } = this.props;
    let searchQuery = null;
    if (inputValue && options.searchFields?.length) {
      const $or = options.searchFields.reduce(($or, slug) => {
        const model = graphandClient.getModel(field.ref);
        const search = model.fields[slug]?.search?.call(model.fields[slug], inputValue);
        search && $or.push({ [slug]: search });
        return $or;
      }, []);
      searchQuery = $or.length && { $or };
    }

    const query = field.query && searchQuery ? { $and: [field.query, searchQuery] } : searchQuery || field.query || {};

    const model = graphandClient.getModel(field.ref);
    const mapList = (items) => {
      return items.map((item) => ({ label: this.getLabelField(item), value: item._id }));
    };

    const list = model.getList({ query });
    return list.then ? list.then(mapList) : mapList(list);
  };

  _Label = (props) => {
    const { field } = this.props;
    const [item, setItem] = useState(undefined);

    useEffect(() => {
      const model = graphandClient.getModel(field.ref);
      const _item = model.get(props._id);
      if (_item instanceof GraphandModelPromise) {
        _item.then(setItem);
      } else {
        setItem(_item);
      }
    });

    return item ? this.getLabelField(item) || null : null;
  };

  _decode = (value) => value && { value: value?._id || value, label: React.createElement(this._Label, { _id: value }) };

  _CheckList = () => {
    const { value, onChange, id, field } = this.props;
    const [list, setList] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
      const model = graphandClient.getModel(field.ref);
      const sub = model.getList({}).subscribe(setList);

      return () => sub.unsubscribe();
    }, []);

    if (loading) {
      return "Chargement ...";
    }

    if (field.multiple) {
      const _value = value?.ids || value || [];
      return list.map((item) => (
        <div className="form-check custom-control custom-checkbox">
          <input
            checked={_value.includes(item._id)}
            className="focus:ring-primary-500 h-4 w-4 text-primary-600 border-gray-300 rounded mr-2"
            id={`${id}:${item._id}`}
            onClick={() => {
              let newValue = _value;

              if (_value?.includes(item._id)) {
                newValue = _value.filter((_id) => _id !== item._id);
              } else {
                if (this.props.options?.maxSize && _value.length + 1 > this.props.options.maxSize) {
                  alert(`Ce champ est limité à ${this.props.options.maxSize} éléments sélectionnés`);
                  return;
                }

                newValue = _value.concat(item._id);
              }

              if (!newValue.length) {
                newValue = null;
              }

              onChange(newValue);
            }}
            type="checkbox"
            value={item._id}
          />
          <label className="form-check-label custom-control-label" htmlFor={`${id}:${item._id}`}>
            {React.createElement(this._Label, { _id: item._id })}
          </label>
        </div>
      ));
    }

    return list.map((item) => (
      <div className="form-check">
        <input
          checked={value === item._id}
          className="focus:ring-primary-500 h-4 w-4 text-primary-600 border-gray-300 rounded mr-2 mb-4"
          id={`${id}:${item._id}`}
          onClick={() => onChange(item._id)}
          type="radio"
          value={item._id}
        />
        <label className="form-check-label" htmlFor={`${id}:${item._id}`}>
          {React.createElement(this._Label, { _id: item._id })}
        </label>
      </div>
    ));
  };

  _renderInputContent = (value) => {
    const { options, errors, onChange, field } = this.props;
    value = value ?? this.props.value;
    switch (options.type) {
      case "list":
        return React.createElement(this._CheckList);
      default:
        return (
          <AsyncSelect
            className="w-full mb-4"
            defaultOptions
            isMulti={field.multiple}
            loadOptions={this._promiseOptions}
            noOptionsMessage={() => "Aucun résultat"}
            onChange={(v) => onChange(v && (field.multiple ? v.map((v) => v.value) : v.value))}
            placeholder={options.placeholder || field.name}
            value={field.multiple ? value?.map((v) => this._decode(v)) : this._decode(Array.isArray(value) ? value[0] : value)}
          />
        );
    }
  };

  render() {
    const { options, value, onChange, errors, id, slug, field, inputRef } = this.props;

    let label = field.__dataField?.name || options.label;
    if (label === undefined) {
      label = <Translation>{(t) => t(`labels.fields.${slug}`)}</Translation>;
    }

    return (
      <div {...options}>
        {label ? (
          <label className="block text-sm font-medium text-gray-700 mb-2" htmlFor={id}>
            {label} {field.required ? "*" : null}
          </label>
        ) : null}
        {options.description ? <p className="text-sm text-gray-500 mb-1">{options.description}</p> : null}
        {value?.suspense ? value.suspense((v) => this._renderInputContent(v)) : this._renderInputContent()}

        {errors?.map((e) => (
          <p className="mt-2 text-sm text-red-600" id={`${e.field}:${e.code}`}>
            {`errors.fields.${e.code}`}
          </p>
        ))}
      </div>
    );
  }
}

export default InputRelation;
