"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var ServerHooksEvents;
(function (ServerHooksEvents) {
    ServerHooksEvents["BEFORE_CREATE"] = "before_create";
    ServerHooksEvents["AFTER_CREATE"] = "after_create";
    ServerHooksEvents["BEFORE_UPDATE"] = "before_update";
    ServerHooksEvents["AFTER_UPDATE"] = "after_update";
    ServerHooksEvents["BEFORE_DELETE"] = "before_delete";
    ServerHooksEvents["AFTER_DELETE"] = "after_delete";
    ServerHooksEvents["BEFORE_EXECUTE"] = "before_execute";
    ServerHooksEvents["AFTER_EXECUTE"] = "after_execute";
    ServerHooksEvents["BEFORE_LOGIN"] = "before_login";
    ServerHooksEvents["AFTER_LOGIN"] = "after_login";
    ServerHooksEvents["BEFORE_REGISTER"] = "before_register";
    ServerHooksEvents["AFTER_REGISTER"] = "after_register";
})(ServerHooksEvents || (ServerHooksEvents = {}));
exports.default = ServerHooksEvents;
