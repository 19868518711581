import React from "react";
import { Link, withRouter } from "react-router-dom";
import HeroIcon from "../HeroIcon";
import CrudButton from "../CrudButton";
import "./index.scss";

class Heading extends React.Component {
  render() {
    const { actions, title, titleLevel, titleSize, className } = this.props;
    const TitleTag = `h${titleLevel}`;

    return (
      <div className={`md:flex md:items-center md:justify-between mb-4 ${className}`}>
        <div className="flex-1 min-w-0">
          <TitleTag className={`text-${titleSize}xl font-bold leading-7 text-secondary-900 sm:text-${titleSize - 1}xl sm:truncate`}>{title}</TitleTag>
        </div>
        <div className="mt-4 flex md:mt-0 md:ml-4">
          {actions.map((action, index) => {
            const condition = action.condition ?? true;
            const color = action.color || "primary";
            const handler = action.handler || (action.link ? () => this.props.history.push(`${action.link}`) : null);

            return condition && handler ? (
              <>
                {action.crudType ? (
                  <CrudButton key={index} type={action.crudType} onClick={handler} label={action.label} />
                ) : (
                  <button
                    key={index}
                    type="button"
                    className={`inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 bg-${color}-600 hover:bg-${color}-700`}
                    onClick={handler}
                  >
                    {action.icon ? <HeroIcon icon={action.icon} className="-ml-1 mr-2 h-5 w-5 text-gray-300" aria-hidden="true" /> : null}

                    {action.label}
                  </button>
                )}
              </>
            ) : null;
          })}
        </div>
      </div>
    );
  }
}

Heading.defaultProps = {
  title: "",
  titleLevel: 1,
  titleSize: 4,
  actions: [],
  className: null,
};

Heading = withRouter(Heading);

export default Heading;
