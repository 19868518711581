import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { isValid } from "date-fns";
import React, { useEffect, useRef, useState } from "react";
import { Datepicker } from "vanillajs-datepicker";
import { Translation } from "react-i18next";

Object.assign(Datepicker.locales, {
  fr: {
    days: ["dimanche", "lundi", "mardi", "mercredi", "jeudi", "vendredi", "samedi"],
    daysShort: ["dim.", "lun.", "mar.", "mer.", "jeu.", "ven.", "sam."],
    daysMin: ["d", "l", "ma", "me", "j", "v", "s"],
    months: ["janvier", "février", "mars", "avril", "mai", "juin", "juillet", "août", "septembre", "octobre", "novembre", "décembre"],
    monthsShort: ["janv.", "févr.", "mars", "avril", "mai", "juin", "juil.", "août", "sept.", "oct.", "nov.", "déc."],
    today: "Aujourd'hui",
    monthsTitle: "Mois",
    clear: "Effacer",
    weekStart: 1,
    format: "dd/mm/yyyy",
  },
});

const InputDate = ({ options, value, onChange, errors, id, slug, field, inputRef }) => {
  const [_value, setValue] = useState(value);
  const datePicker = useRef();
  inputRef = inputRef || useRef();

  useEffect(() => {
    const _changeDateListener = (e) => {
      const { date } = e.detail;
      if (date !== _value) {
        setValue(date);
      }
    };

    if (inputRef.current) {
      datePicker.current = new Datepicker(inputRef.current, {
        format: "dd/mm/yyyy",
        language: "fr",
      });

      inputRef.current.addEventListener("changeDate", _changeDateListener);
    }

    return () => {
      if (inputRef.current) {
        datePicker.current.destroy();
        inputRef.current.removeEventListener("changeDate", _changeDateListener);
      }
    };
  }, [inputRef.current]);

  useEffect(() => {
    datePicker.current?.setDate(_value);

    if (_value) {
      try {
        if (isValid(_value) && _value?.getTime() !== value?.getTime()) {
          onChange(_value);
        }
      } catch (e) {}
    } else if (value !== undefined) {
      onChange(undefined);
    }
  }, [_value]);

  useEffect(() => {
    if (isValid(value) && value?.getTime() !== _value?.getTime()) {
      setValue(value);
    }
  }, [value]);

  let label = field.__dataField?.name || options.label;
  if (label === undefined) {
    label = <Translation>{(t) => t(`labels.fields.${slug}`)}</Translation>;
  }

  return (
    <div {...options} className={`${options.disabled ? "opacity-50" : ""} ${options.className}`}>
      {label ? (
        <label className="block text-sm font-medium text-gray-700 mb-1" htmlFor={id}>
          {label} {field.required ? "*" : null}
        </label>
      ) : null}
      {options.description ? <p className="text-sm text-gray-500 mb-1">{options.description}</p> : null}
      <div className="relative rounded-md shadow-sm">
        <input
          ref={inputRef}
          className={`rounded-md block w-full border rounded-md shadow-sm py-2 h-11 px-3 focus:outline-none sm:text-sm focus:border-light-secondary-500 ${
            errors?.length ? `border-red-500` : `border-gray-200`
          }`}
          disabled={options.disabled}
          id={id}
          name={id}
          placeholder={options.placeholder}
          type="text"
        />
        {errors?.length ? (
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <div className="h-5 w-5 flex items-center justify-center">
              <FontAwesomeIcon className="text-red-500" icon={faExclamationCircle} size="lg" />
            </div>
          </div>
        ) : null}
      </div>

      {errors?.map((e) => (
        <p className="mt-2 text-sm text-red-600" id={`${e.field}:${e.code}`}>
          {e.message}
        </p>
      ))}
    </div>
  );
};

InputDate.encodeInput = true;

export default InputDate;
