import React from "react";
import graphandClient from "../../../utils/graphand";
import Breadcrumbs from "../../../components/Breadcrumbs";
import Heading from "../../../components/Heading";
import { GraphandForm } from "graphand-react";
import { CheckIcon } from "@heroicons/react/solid";

const steps = ["Contrat", "Activités", "Responsabilité civile"];

class ContractRCProUpdate extends React.Component {
  contract;

  state = {
    currentStep: 0,
  };

  async componentDidMount() {
    this.contract = await graphandClient.getModel("Data:contrat-rc-pro").get(this.props.match.params.id);
    this.forceUpdate();
  }

  async componentDidUpdate(prevProps) {
    if (this.props.match.params.id !== prevProps.match.params.id) {
      this.contract = await graphandClient.getModel("Data:contrat-rc-pro").get(this.props.match.params.id);
      this.forceUpdate();
    }
  }

  handleSubmit = async (set) => {
    await this.contract.update({ set });
    this.props.history.push("/contracts-rc-pro");
  };

  handleDelete = async () => {
    await this.contract.delete();
    this.props.history.push("/contracts-rc-pro");
  };

  render() {
    if (!this.contract) return null;

    return (
      <div className="py-8 sm:px-8 bg-gray-100">
        <div className="max-w-screen-xl w-full mx-auto space-y-4 sm:space-y-8 ContractRCProUpdate">
          <Breadcrumbs pages={[{ name: "contracts-rc-pro", label: "Portefeuille" }, { label: "Modifier" }]} />
          <Heading title={"Modifier la tarification du contrat"} />
          <div className="my-4 bg-white border border-gray-200 rounded-md shadow">
            <nav aria-label="Progress" className="border-b border-gray-300">
              <ol className="divide-y divide-gray-300 md:flex md:divide-y-0">
                {steps.map((step, stepIdx) => (
                  <li key={step.ref} className="relative md:flex-1 md:flex">
                    {stepIdx < this.state.currentStep ? (
                      <a className="cursor-pointer group flex items-center w-full" onClick={() => this.setState({ currentStep: stepIdx })}>
                        <span className="px-6 py-4 flex items-center text-sm font-medium">
                          <span className="flex-shrink-0 w-10 h-10 flex items-center justify-center bg-primary-600 rounded-full group-hover:bg-primary-800">
                            <CheckIcon className="w-6 h-6 text-white" aria-hidden="true" />
                          </span>
                          <span className="ml-4 text-sm font-medium text-gray-900">{step}</span>
                        </span>
                      </a>
                    ) : stepIdx == this.state.currentStep || (stepIdx == 0 && !this.state.currentStep) ? (
                      <a
                        className="cursor-pointer px-6 py-4 flex items-center text-sm font-medium"
                        onClick={() => this.setState({ currentStep: stepIdx })}
                        aria-current="step"
                      >
                        <span className="flex-shrink-0 w-10 h-10 flex items-center justify-center border-2 border-primary-600 rounded-full">
                          <span className="text-primary-600">{stepIdx + 1}</span>
                        </span>
                        <span className="ml-4 text-sm font-medium text-primary-600 font-bold">{step}</span>
                      </a>
                    ) : (
                      <a className="cursor-pointer group flex items-center" onClick={() => this.setState({ currentStep: stepIdx })}>
                        <span className="px-6 py-4 flex items-center text-sm font-medium">
                          <span className="flex-shrink-0 w-10 h-10 flex items-center justify-center border-2 border-gray-300 rounded-full group-hover:border-gray-400">
                            <span className="text-gray-500 group-hover:text-gray-900">{stepIdx + 1}</span>
                          </span>
                          <span className="ml-4 text-sm font-medium text-gray-500 group-hover:text-gray-900">{step}</span>
                        </span>
                      </a>
                    )}

                    {stepIdx !== steps.length - 1 ? (
                      <>
                        {/* Arrow separator for lg screens and up */}
                        <div className="hidden md:block absolute top-0 right-0 h-full w-5" aria-hidden="true">
                          <svg className="h-full w-full text-gray-300" viewBox="0 0 22 80" fill="none" preserveAspectRatio="none">
                            <path d="M0 -2L20 40L0 82" vectorEffect="non-scaling-stroke" stroke="currentcolor" strokeLinejoin="round" />
                          </svg>
                        </div>
                      </>
                    ) : null}
                  </li>
                ))}
              </ol>
            </nav>

            <div className="p-8">
              {(() => {
                switch (this.state.currentStep) {
                  case 0:
                    return (
                      <GraphandForm
                        model={graphandClient.getModel("Data:contrat-rc-pro")}
                        onSubmit={this.handleSubmit}
                        template={graphandClient.getModel("Data:contrat-rc-pro").contractFormTemplate.bind(this)}
                        instance={this.contract}
                      />
                    );
                  case 1:
                    return (
                      <GraphandForm
                        model={graphandClient.getModel("Data:contrat-rc-pro")}
                        onSubmit={this.handleSubmit}
                        template={graphandClient.getModel("Data:contrat-rc-pro").activitiesFormTemplate.bind(this)}
                        instance={this.contract}
                      />
                    );
                  case 2:
                    return (
                      <GraphandForm
                        model={graphandClient.getModel("Data:contrat-rc-pro")}
                        onSubmit={this.handleSubmit}
                        template={graphandClient.getModel("Data:contrat-rc-pro").responsabiliteFormTemplate.bind(this)}
                        instance={this.contract}
                      />
                    );
                  default:
                    return null;
                }
              })()}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ContractRCProUpdate;
