import React from "react";
import graphandClient from "../../../utils/graphand";
import Breadcrumbs from "../../../components/Breadcrumbs";
import Heading from "../../../components/Heading";
import { GraphandForm } from "graphand-react";
import CrudButton from "../../../components/CrudButton";

class ContactUpdate extends React.Component {
  handleSubmit = async (values) => {
    const contact = await graphandClient.getModel("Data:contacts").get(this.props.match.params.id);
    contact.update({ set: values });
    this.props.history.push("/contacts");
  };

  render() {
    return (
      <div className="py-8 sm:px-8 bg-gray-100">
        <div className="max-w-screen-xl w-full mx-auto space-y-4 sm:space-y-8 ContactUpdate">
          <Breadcrumbs pages={[{ name: "contacts", label: "Coordonnées" }, { label: "Modifier la coordonnée" }]} />
          <Heading title={"Modifier la coordonnée"} />
          {graphandClient
            .getModel("Data:contacts")
            .get(this.props.match.params.id)
            .suspense((contact) => (
              <GraphandForm
                instance={contact}
                model={graphandClient.getModel("Data:contacts")}
                map={["name", "email", "phone", "position"]}
                onSubmit={this.handleSubmit}
                controls={[
                  <div className="text-right mt-8">
                    <CrudButton type={"submit"} />
                  </div>,
                ]}
              />
            ))}
        </div>
      </div>
    );
  }
}

export default ContactUpdate;
