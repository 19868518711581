import React from "react";

const ViewId = (props) => {
  const { options } = props;
  return (
    <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full bg-secondary-100 text-secondary-800 ${options.className || ""}`}>
      {props.value}
    </span>
  );
};

export default ViewId;
