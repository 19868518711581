import { faTimes, faLink, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dialog, Transition } from "@headlessui/react";
import React from "react";
import GraphandItemView from "../../../components/GraphandItemView";
import GraphandUIList from "../../../components/GraphandUIList";
import graphandClient from "../../../utils/graphand";

class ViewRelation extends React.Component {
  state = {
    isOpen: false,
    previewField: "_id",
  };

  componentDidMount() {
    this.props.field.getDefaultField().then((previewField) => this.setState({ previewField }));
  }

  closeModal = () => {
    this.setState({ isOpen: false });
  };

  openModal = () => {
    this.setState({ isOpen: true });
  };

  renderModal() {
    const { value, field } = this.props;

    const controls = (
      <div className="flex items-stretch ml-4 flex-row-reverse">
        <button
          className="bg-white h-full truncate inline-flex items-center px-4 border border-gray-200 shadow-sm text-sm font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary-500"
          onClick={this.closeModal}
          type="button"
        >
          Fermer
        </button>
      </div>
    );

    return (
      <Transition as={React.Fragment} show={this.state.isOpen}>
        <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={this.closeModal}>
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75" />
            </Transition.Child>

            <span aria-hidden="true" className="inline-block h-screen align-middle">
              &#8203;
            </span>
            <Transition.Child
              as={React.Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-screen-2xl p-6 my-8 text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                {field.multiple ? (
                  <GraphandUIList controls={controls} ids={value} model={graphandClient.getModel(field.ref)} />
                ) : (
                  <GraphandItemView
                    controls={controls}
                    fields={Object.keys(graphandClient.getModel(field.ref).fields).filter(
                      (f) => !["_id", "createdAt", "createdBy", "updatedAt", "updatedBy", "user", "fullname"].includes(f),
                    )}
                    id={value._id}
                    model={graphandClient.getModel(field.ref)}
                  />
                )}
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    );
  }

  render() {
    const { value, options, field } = this.props;
    const { previewField } = this.state;

    if (!value) {
      return <FontAwesomeIcon className="text-gray-400" icon={faTimes} />;
    }

    return (
      <>
        {this.renderModal()}
        {value.suspense?.call(
          value,
          (res, loading) => {
            if (loading) {
              return <FontAwesomeIcon className="animate-spin" icon={faSpinner} />;
            }

            if (field.multiple) {
              return (
                <button
                  className="border-gray-200 bg-white h-full truncate inline-flex items-center px-4 py-2 border shadow-sm text-sm font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary-500"
                  onClick={this.openModal}
                  onKeyDown={this.openModal}
                  tabIndex="0"
                  type="button"
                >
                  <div className="h-5 w-5 flex items-center justify-center mr-1">
                    <FontAwesomeIcon icon={faLink} />
                  </div>
                  {res?.length || 0}
                </button>
              );
            }

            if (!res?.get?.call(res, previewField)) {
              return <FontAwesomeIcon className="text-gray-400" icon={faTimes} />;
            }

            if (options.fromRelation) {
              return res.renderFieldView(previewField, { ...options, fromRelation: true });
            }

            return (
              <button
                className="group h-full truncate inline-flex items-center text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary-500"
                onClick={this.openModal}
                onKeyDown={this.openModal}
                tabIndex="0"
                type="button"
              >
                <div className="h-5 w-5 flex items-center justify-center mr-2 text-gray-400 group-hover:text-secondary-700">
                  <FontAwesomeIcon icon={faLink} />
                </div>
                {res.renderFieldView(previewField, { ...options, fromRelation: true })}
              </button>
            );
          },
          { updateKey: value?.toString() },
        ) || null}
      </>
    );
  }
}

export default ViewRelation;
