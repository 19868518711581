import { Dialog, Transition } from "@headlessui/react";
import React, { useState } from "react";
import graphandClient from "../utils/graphand";
import Dropzone from "react-dropzone";
import filesize from "filesize";
import { faFilePdf, faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const UpdateClaimDocuments = ({ claim }) => {
  const [loading, setLoading] = useState(false);

  const _handleDrop = async (files) => {
    setLoading(true);
    const Media = graphandClient.getModel("Media");

    const promises = files.map(async (file) => {
      return Media.create({ file }).then((media) => {
        return claim.update({ set: { $addToSet: { medias: media._id } } });
      });
    });

    try {
      await Promise.all(promises);
    } catch (e) {
      alert("Une erreur est survenue lors de l'ajout d'un document");
    }

    setLoading(false);
  };

  return (
    <div className="ContractRCProUpdateMedias p-3">
      <Dropzone onDrop={_handleDrop}>
        {({ getRootProps, getInputProps }) => (
          <section>
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <div className="relative overflow-hidden">
                <input className="absolute inset-0 opacity-0" id="inputGroupFile03" type="file" />
                <label htmlFor="inputGroupFile03">
                  <div className="w-full flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                    <div className="space-y-1 text-center">
                      <svg className="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
                        <path
                          d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                          strokeWidth={2}
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                      <div className="flex text-sm text-gray-600">
                        <span className="relative cursor-pointer bg-white rounded-md font-medium text-secondary-600 hover:text-secondary-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-secondary-500">
                          Cliquez ici pour ajouter un fichier
                        </span>
                        <p className="pl-1">ou déposez-le dans la zone</p>
                      </div>
                      <p className="text-xs text-gray-500">PNG, JPG, PDF, etc.</p>
                    </div>
                  </div>
                </label>
              </div>
            </div>
          </section>
        )}
      </Dropzone>

      <section className="mt-8 pb-16" aria-labelledby="gallery-heading">
        <h2 id="gallery-heading" className="sr-only">
          Documents déposés
        </h2>
        <ul
          role="list"
          className="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 md:grid-cols-4 lg:grid-cols-3 xl:grid-cols-4 xl:gap-x-8"
        >
          {claim.suspense(
            (_claim) => {
              if (loading) {
                return "Chargement ...";
              }

              return _claim.medias?.suspense(
                (medias) => {
                  if (!medias.length) {
                    return null;
                  }

                  return medias.reverse().map((media) =>
                    media.mimetype && media.mimetype.match("image") ? (
                      <li className="block relative group" onClick={() => media.download()}>
                        <div className="opacity-0 group-hover:opacity-100">
                          <div
                            className="opacity-50 hover:opacity-100 absolute top-2 right-2 bg-white rounded-full text-red-500 shadow z-10 h-8 w-8 flex items-center justify-center cursor-pointer"
                            onClick={(e) => {
                              e.stopPropagation();
                              media.delete();
                            }}
                          >
                            <FontAwesomeIcon icon={faTimes} />
                          </div>
                        </div>
                        <div className="block w-full aspect-w-10 aspect-h-7 rounded-lg bg-gray-100 overflow-hidden">
                          <img src={media.getUrl({ w: 512 })} alt="" className="object-cover pointer-events-none group-hover:opacity-75" />
                          <button type="button" className="absolute inset-0 focus:outline-none">
                            <span className="sr-only">Télécharger {media.name}</span>
                          </button>
                        </div>
                        <p className="mt-2 block text-sm font-medium text-gray-900 truncate pointer-events-none">{media.name}</p>
                        <p className="block text-sm font-medium text-gray-500 pointer-events-none">{filesize(media.size)}</p>
                      </li>
                    ) : (
                      <li className="block relative group" onClick={() => media.download()}>
                        <div className="opacity-0 group-hover:opacity-100">
                          <div
                            className="opacity-50 hover:opacity-100 absolute top-2 right-2 bg-white rounded-full text-red-500 shadow z-10 h-8 w-8 flex items-center justify-center cursor-pointer"
                            onClick={(e) => {
                              e.stopPropagation();
                              media.delete();
                            }}
                          >
                            <FontAwesomeIcon icon={faTimes} />
                          </div>
                        </div>
                        <div className="block w-full aspect-w-10 aspect-h-7 rounded-lg bg-gray-100 overflow-hidden border-2 border-gray-300 border-dashed">
                          <div className="flex items-center justify-center group-hover:opacity-75">
                            <FontAwesomeIcon icon={faFilePdf} size="3x" className="text-gray-500" />
                          </div>
                          <button type="button" className="absolute inset-0 focus:outline-none">
                            <span className="sr-only">Télécharger {media.name}</span>
                          </button>
                        </div>
                        <p className="mt-2 block text-sm font-medium text-gray-900 truncate pointer-events-none">{media.name}</p>
                        <p className="block text-sm font-medium text-gray-500 pointer-events-none">{filesize(media.size)}</p>
                      </li>
                    ),
                  );
                },
                { updateKey: _claim.medias?.ids, subscribe: true },
              );
            },
            { updateKey: claim._id, subscribe: true },
          )}
        </ul>
      </section>
    </div>
  );
};

const UpdateClaimDocumentsModal = (props) => {
  const [isOpen, setIsOpen] = useState(true);

  function closeModal() {
    setIsOpen(false);
    props.onClose();
  }

  return (
    <Transition as={React.Fragment} show={isOpen}>
      <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={closeModal}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75" />
          </Transition.Child>

          <span aria-hidden="true" className="inline-block h-screen align-middle">
            &#8203;
          </span>
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block w-full max-w-screen-2xl p-6 my-8 text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
              <h2 className={`font-bold leading-7 text-secondary-900 sm:text-2xl sm:truncate mb-3`}>Documents du sinistre</h2>
              <UpdateClaimDocuments {...props} />
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default UpdateClaimDocumentsModal;
