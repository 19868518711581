import { GraphandModelMedia, GraphandFieldText } from "graphand-js";
import { toast } from "react-toastify";
import mime from "mime";

class Media extends GraphandModelMedia {
  async download() {
    let url;

    // if (this.size < 10000000) {
    const file = await fetch(this.getUrl());
    const fileBlob = await file.blob();
    url = URL.createObjectURL(fileBlob);
    // } else {
    //   url = this.getUrl({ name: true });
    // }

    const a = document.createElement("a");
    a.style.display = "none";
    a.href = url;
    a.setAttribute("download", this.name);
    a.target = "_blank";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
}

Media.hook("postCreate", function (created, err) {
  if (created && !err) {
    toast.success("Le fichier a bien été ajouté");
  }
});

Media.hook("postUpdate", function (updated, err) {
  if (updated && !err) {
    toast.success("Le fichier a bien été modifié");
  }
});

Media.hook("postDelete", function (args, err) {
  if (!err) {
    toast.success("Le fichier a bien été supprimé");
  }
});

export default Media;
