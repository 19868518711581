import React from "react";

const ViewNumber = (props) => {
  if (!props.value) {
    return 0;
  }

  return <span>{props.value}</span>;
};

export default ViewNumber;
