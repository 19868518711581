import React from "react";

class GraphandItemView extends React.Component {
  render() {
    const fallback = (
      <div className="GraphandItemView">
        {this.props.controls ? (
          <div className="flex items-stretch w-full h-10 mb-2 sm:mb-4 justify-between">
            <div />
            {this.props.controls}
          </div>
        ) : null}
      </div>
    );

    return this.props.model.get(this.props.id).suspense(
      (item) => {
        if (!item) {
          return (
            <div className="GraphandItemView">
              {this.props.controls ? (
                <div className="flex items-stretch w-full h-10 mb-2 sm:mb-4 justify-between">
                  <div />
                  {this.props.controls}
                </div>
              ) : null}
            </div>
          );
        }

        return (
          <div className="GraphandItemView">
            {this.props.controls ? (
              <div className="flex items-stretch w-full h-10 mb-2 sm:mb-4 justify-between">
                <div/>
                {this.props.controls}
              </div>
            ) : null}
            <div className="bg-white shadow overflow-hidden sm:rounded-lg">
              <div className="px-4 py-5 sm:p-0">
                <dl className="sm:divide-y sm:divide-gray-200">
                  {this.props.fields.map((slug) => {
                    const field = this.props.model.fields[slug];
                    if (!field) {
                      return null;
                    }

                    return (
                      <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500">{field.name || slug}</dt>
                        <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">{item.renderFieldView(slug)}</dd>
                      </div>
                    );
                  })}
                </dl>
              </div>
            </div>
          </div>
        );
      },
      { subscribe: true, fallback },
    );
  }
}

GraphandItemView.defaultProps = {
  controls: null,
  fields: ["_id"],
};

export default GraphandItemView;
