import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import Dashboard from "./components/Dashboard";
import Login from "./components/Login";
import Sidebar from "./components/Sidebar";
import ContractRCProCreate from "./pages/contracts-rc-pro/ContractCreate";
import ContractsRCProList from "./pages/contracts-rc-pro/ContractsList";
import ContractRCProUpdate from "./pages/contracts-rc-pro/ContractUpdate";
import ContractRCProDetails from "./pages/contracts-rc-pro/ContractDetails";
import CustomerCreate from "./pages/customers/CustomerCreate";
import CustomersList from "./pages/customers/CustomersList";
import CustomerUpdate from "./pages/customers/CustomerUpdate";
import ContactsList from "./pages/contacts/ContactsList";
import ContactCreate from "./pages/contacts/ContactCreate";
import ContactUpdate from "./pages/contacts/ContactUpdate";
import "react-toastify/dist/ReactToastify.css";
import { adminGuard, loggedGuard, notLoggedGuard, partnerGuard } from "./utils/guards";
import graphandClient from "./utils/graphand";
import PaymentsList from "./pages/payments/PaymentsList";
import NextPayments from "./pages/payments/NextPayments";
import LatePayments from "./pages/payments/LatePayments";
import ClaimsList from "./pages/claims/ClaimsList";

class App extends React.Component {
  state = {
    isUserLogged: graphandClient.authmanager.logged,
  };

  componentDidMount() {
    graphandClient.authmanager.loggedSubject.subscribe((isUserLogged) => isUserLogged !== this.state.isUserLogged && this.setState({ isUserLogged }));
  }

  handleLogin = async (values) => {
    await graphandClient.authmanager.login(values);
  };

  handleLogout = () => {
    graphandClient.authmanager.logout();
    graphandClient.logout();
  };

  render() {
    return (
      <div id="app" className="h-screen flex overflow-hidden bg-white">
        <ToastContainer />
        <Router>
          {this.state.isUserLogged ? <Sidebar handleLogout={this.handleLogout} /> : null}
          <div className="flex flex-col w-0 flex-1 overflow-auto bg-gray-100 min-h-screen-lg">
            <Switch>
              <Route component={notLoggedGuard(Login, { handleSubmit: this.handleLogin })} exact path="/login" />
              <Route component={loggedGuard(Dashboard, { handleSubmit: this.handleLogin })} exact path="/" />

              <Route component={partnerGuard(CustomersList)} exact path="/customers" />
              <Route component={partnerGuard(CustomerCreate)} exact path="/customers/add" />
              <Route component={partnerGuard(CustomerUpdate)} exact path="/customers/:id" />

              <Route component={partnerGuard(ContractsRCProList)} exact path="/contracts-rc-pro" />
              <Route component={partnerGuard(ContractRCProCreate)} exact path="/generator" />
              <Route component={partnerGuard(ContractRCProUpdate)} exact path="/contracts-rc-pro/:id" />
              <Route component={partnerGuard(ContractRCProDetails)} path="/contracts-rc-pro/:id/details" />

              <Route component={partnerGuard(PaymentsList)} path="/payments" />

              {/*<Route component={partnerGuard(NextPayments)} exact path="/payments-next" />*/}
              {/*<Route component={partnerGuard(LatePayments)} exact path="/payments-late" />*/}

              <Route component={loggedGuard(ContactsList)} exact path="/contacts" />
              <Route component={adminGuard(ContactCreate)} exact path="/contacts/add" />
              <Route component={adminGuard(ContactUpdate)} exact path="/contacts/:id" />

              <Route component={adminGuard(ClaimsList)} exact path="/claims" />
              <Route component={adminGuard(ContactUpdate)} exact path="/claims/add" />
              <Route component={adminGuard(ContactUpdate)} exact path="/claims/:id" />
            </Switch>
          </div>
        </Router>
      </div>
    );
  }
}

export default App;
