import { faChevronLeft, faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

const Pagination = (props) => {
  const { nbPages, count, pageSize, page, onPageChange } = props;

  const halPages = parseInt(nbPages / 2, 10);
  const pageMax = Math.ceil(count / pageSize);
  let pagesBefore = page - 1;
  pagesBefore = pagesBefore > halPages ? halPages : pagesBefore;

  let pagesAfter = pageMax - page;
  pagesAfter = pagesAfter > halPages ? halPages : pagesAfter;
  pagesAfter += halPages - pagesBefore;

  if (pagesAfter < halPages) {
    pagesBefore += halPages - pagesAfter;
  }

  pagesBefore = pagesBefore > pageMax - 1 ? pageMax - 1 : pagesBefore;
  pagesAfter = pagesAfter > pageMax - 1 ? pageMax - 1 : pagesAfter;

  pagesBefore = pagesBefore > 0 ? pagesBefore : null;
  pagesAfter = pagesAfter > 0 ? pagesAfter : null;

  pagesBefore = pagesBefore > page - 1 ? page - 1 : pagesBefore;

  return (
    <>
      <div className="flex-1 flex justify-between sm:hidden">
        <a
          className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
          onClick={() => page > 1 && onPageChange(page - 1)}
        >
          Précédent
        </a>
        <a
          className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50"
          onClick={() => page < pageMax && onPageChange(page + 1)}
        >
          Suivant
        </a>
      </div>
      <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
        <div className="mr-4">
          <p className="text-sm text-gray-700 truncate">
            Afficher : <span className="font-medium">{page * pageSize - (pageSize - 1)}</span> -{" "}
            <span className="font-medium">{page * pageSize > count ? count : page * pageSize}</span> sur <span className="font-medium">{count}</span>{" "}
            résultats
          </p>
        </div>
        <div>
          <nav aria-label="Pagination" className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px">
            <span
              className={`${
                page > 1 ? "cursor-pointer hover:bg-gray-50" : "opacity-50"
              } relative inline-flex items-center px-2 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-500`}
              onClick={() => page > 1 && onPageChange(page - 1)}
            >
              <span className="sr-only">Précédent</span>
              <div className="h-5 w-5 flex items-center justify-center">
                <FontAwesomeIcon icon={faChevronLeft} />
              </div>
            </span>
            {pagesBefore &&
              Array(pagesBefore)
                .fill(null)
                .map((_, index) => (
                  <span
                    className="cursor-pointer bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium"
                    onClick={() => onPageChange(page - pagesBefore + index)}
                  >
                    {page - pagesBefore + index}
                  </span>
                ))}
            <span
              aria-current="page"
              className="cursor-pointer z-10 bg-white border-secondary-500 text-secondary-600 bg-secondary-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium"
            >
              {page}
            </span>
            {pagesAfter &&
              Array(pagesAfter)
                .fill(null)
                .map((_, index) =>
                  page + index + 1 > pageMax ? null : (
                    <span
                      className="cursor-pointer bg-white border-gray-300 text-gray-500 hover:bg-gray-50 relative inline-flex items-center px-4 py-2 border text-sm font-medium"
                      onClick={() => onPageChange(page + index + 1)}
                    >
                      {page + index + 1}
                    </span>
                  ),
                )}
            <span
              className={`${
                page < pageMax ? "cursor-pointer hover:bg-gray-50" : "opacity-50"
              } relative inline-flex items-center px-2 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-500`}
              onClick={() => page < pageMax && onPageChange(page + 1)}
            >
              <span className="sr-only">Suivant</span>
              <div className="h-5 w-5 flex items-center justify-center">
                <FontAwesomeIcon icon={faChevronRight} />
              </div>
            </span>
          </nav>
        </div>
      </div>
    </>
  );
};

export default Pagination;
