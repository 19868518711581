import React from "react";
import graphandClient from "../../../utils/graphand";
import Breadcrumbs from "../../../components/Breadcrumbs";
import Heading from "../../../components/Heading";
import { GraphandForm } from "graphand-react";
import CrudButton from "../../../components/CrudButton";
import account from "../../../models/Account";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";

class CustomerUpdate extends React.Component {
  handleSubmit = async (payload) => {
    try {
      const Account = graphandClient.getModel("Account");
      const account = await Account.get(this.props.match.params.id);
      await account.update({ set: payload });
      this.props.history.push("/customers");
    } catch (e) {
      console.log(e);
      throw e;
    }
  };

  render() {
    const Account = graphandClient.getModel("Account");
    return (
      <div className="py-8 sm:px-8 bg-gray-100">
        <div className="max-w-screen-xl w-full mx-auto space-y-4 sm:space-y-8 CustomerUpdate">
          <Breadcrumbs pages={[{ name: "customers", label: "Clients" }, { label: "Modifier un client" }]} />
          <Heading title={"Modifier le client"} />
          <div className="my-4 p-8 bg-white border border-gray-200 rounded-md shadow">
            {Account.get(this.props.match.params.id).suspense(
              (account) => {
                return (
                  <GraphandForm
                    instance={account}
                    map={[
                      "agency",
                      "firstname",
                      "lastname",
                      "email",
                      "phone",
                      "slug",
                      "address",
                      "postal",
                      "city",
                      "iban",
                      "bic",
                      "bank-name",
                      "creditsafeId",
                    ]}
                    onSubmit={this.handleSubmit}
                    controls={[
                      <div className="text-right mt-8">
                        <CrudButton type={"submit"} />
                      </div>,
                    ]}
                  />
                );
              },
              { updateKey: this.props.match.params.id },
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default CustomerUpdate;
