import { GraphandFieldText, GraphandModelData } from "graphand-js";
import React from "react";
import { toast } from "react-toastify";
import InputMedia from "../components/InputMedia";

class Agency extends GraphandModelData {
  static apiIdentifier = "agency";
  static defaultField = "name";

  static getFields() {
    const fields = super.getFields(arguments);
    if (fields.logo) {
      fields.logo.renderInput = function (props) {
        return React.createElement(InputMedia, { ...props, field: this });
      };
    }
    return fields;
  }
}

Agency.customFields({
  address: new GraphandFieldText({
    name: "Adresse",
  }),
});

Agency.hook("postCreate", function (created, err) {
  if (created && !err) {
    toast.success("L'agence a bien été ajoutée");
  }
});

Agency.hook("postUpdate", function (updated, err) {
  if (updated && !err) {
    toast.success("L'agence a bien été modifiée");
  }
});

Agency.hook("postDelete", function (args, err) {
  if (!err) {
    toast.success("L'agence a bien été supprimée");
  }
});

export default Agency;
