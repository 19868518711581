import { faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Menu, Transition } from "@headlessui/react";
import React from "react";
import { Link } from "react-router-dom";
import { DotsVerticalIcon } from "@heroicons/react/solid";

const GraphandUIListLine = (props) => {
  let className = "transition ease-out duration-100 ";
  className += props.even ? "bg-gray-50" : "bg-white";
  className += " ";
  className += props.loading ? "opacity-50" : "opacity-100";

  const _render = (item) => (
    <tr className={className}>
      {[...props.fields].map((slug) => {
        const field = props.model.fields[slug];
        if (!field) {
          return null;
        }

        const cellProps =
          field.options?.tableCellProps && typeof field.options?.tableCellProps === "function"
            ? field.options?.tableCellProps(field, slug)
            : field.options?.tableCellProps || {};

        return item ? (
          <td {...cellProps} className={`px-1 sm:px-2 text-left h-14 text-sm font-medium text-gray-500 py-0 ${cellProps.className || ""}`}>
            {item.renderFieldView(slug, { minimal: true })}
          </td>
        ) : (
          <td {...cellProps} className={`px-1 sm:px-2 text-left h-14 text-sm font-medium text-gray-500 py-0 ${cellProps.className || ""}`} />
        );
      })}
      {item && props.controls && typeof props.controls === "function" ? (
        <td className="px-2 sm:px-4 h-14 text-sm font-medium space-x-4 w-px whitespace-nowrap">
          <Menu as="div" className="relative inline-block text-left">
            <div>
              <Menu.Button className="inline-flex justify-center w-full px-4 py-2 text-sm font-medium text-gray-500 hover:bg-gray-100 rounded-md focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
                <DotsVerticalIcon className="w-5 h-5 text-gray-500 hover:text-gray-800" aria-hidden="true" />
              </Menu.Button>
            </div>
            <Transition
              as={React.Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              {props.controls(item)}
            </Transition>
          </Menu>
        </td>
      ) : (
        <td className="px-2 sm:px-4 h-14 text-sm font-medium space-x-4 w-px whitespace-nowrap" />
      )}
    </tr>
  );

  return props.item ? props.item.suspense(() => _render(props.item), { subscribe: true }) : _render(props.item);
};

GraphandUIListLine.defaultProps = {
  fields: [],
  controls: null,
};

export default GraphandUIListLine;
