import { Dialog, Transition } from "@headlessui/react";
import React, { useState } from "react";
import graphandClient from "../utils/graphand";
import { GraphandForm } from "graphand-react";
import CrudButton from "../components/CrudButton";

const UpdateClaimModal = (props) => {
  const [isOpen, setIsOpen] = useState(true);

  function closeModal() {
    setIsOpen(false);
    props.onClose();
  }

  async function _handleSubmit(values) {
    await props.claim.update({ set: values });
    closeModal();
  }

  const ClaimModel = graphandClient.getModel("Data:claims");
  return (
    <Transition as={React.Fragment} show={isOpen}>
      <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={closeModal}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75" />
          </Transition.Child>

          <span aria-hidden="true" className="inline-block h-screen align-middle">
            &#8203;
          </span>
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block w-full max-w-screen-2xl p-6 my-8 text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
              <GraphandForm model={ClaimModel} instance={props.claim} onSubmit={_handleSubmit}>
                {({ formRef, handleSubmit, fields }) => (
                  <form ref={formRef} onSubmit={handleSubmit} className="space-y-8 divide divide-y divide-gray-200">
                    <div className="mb-6">
                      <h2 className={`font-bold leading-7 text-secondary-900 sm:text-2xl sm:truncate mb-3`}>Modifier le sinistre</h2>
                      <div className="grid grid-cols-3 gap-4">
                        <div className="col-span-1">{fields.render("claimRef")}</div>
                        <div className="col-span-1">{fields.render("nature")}</div>
                        <div className="col-span-1">{fields.render("statut")}</div>
                        <div className="col-span-1">{fields.render("amount")}</div>
                        <div className="col-span-1">{fields.render("feesAmount")}</div>
                        <div className="col-span-1">{fields.render("appealAmount")}</div>
                        <div className="col-span-1">{fields.render("provision")}</div>
                        <div className="col-span-1">{fields.render("expertProvision")}</div>
                        <div className="col-span-1">{fields.render("appealProvision")}</div>
                        <div className="col-span-1">{fields.render("date")}</div>
                        <div className="col-span-1">{fields.render("payDate")}</div>
                        <div className="col-span-1">{fields.render("startDate")}</div>
                        <div className="col-span-1">{fields.render("endDate")}</div>
                        <div className="col-span-1">{fields.render("openingDate")}</div>
                        <div className="col-span-1">{fields.render("manager")}</div>

                        <div className="col-span-3">{fields.render("circonstances")}</div>

                        <div className="col-span-3">{fields.render("observations")}</div>

                        <div className="col-span-3">{fields.render("comments")}</div>
                      </div>
                    </div>
                    <div className="pt-6">
                      <h2 className={`font-bold leading-7 text-secondary-900 sm:text-2xl sm:truncate mb-3`}>Tiers lésé</h2>
                      <div className="grid grid-cols-3 gap-4">
                        <div className="col-span-1">{fields.render("firstname")}</div>
                        <div className="col-span-1">{fields.render("lastname")}</div>
                        <div className="col-span-1">{fields.render("email")}</div>
                        <div className="col-span-1">{fields.render("phone")}</div>
                        <div className="col-span-1">{fields.render("assureur")}</div>
                        <div className="col-span-3">{fields.render("address")}</div>
                        <div className="col-span-1">{fields.render("postalcode")}</div>
                        <div className="col-span-1">{fields.render("city")}</div>
                        <div className="col-span-3 text-right mt-6">
                          <CrudButton type="submit" />
                        </div>
                      </div>
                    </div>
                  </form>
                )}
              </GraphandForm>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default UpdateClaimModal;
