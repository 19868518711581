import React from "react";
import "./index.scss";
import graphandClient from "../../utils/graphand";
import { GraphandFieldText } from "graphand-js";
import { GraphandForm } from "graphand-react";

class Login extends React.Component {
  componentDidMount() {
    this.loadingSubscription = graphandClient.authmanager.loadingSubject.subscribe(() => this.forceUpdate());
  }

  componentWillUnmount() {
    this.loadingSubscription && this.loadingSubscription.unsubscribe();
  }

  handleSubmit = async (values) => {
    await graphandClient.authmanager.login(values);
  };

  fields = {
    email: new GraphandFieldText({
      name: "Adresse email",
    }),
    password: new GraphandFieldText({
      name: "Mot de passe",
    }),
  };

  fieldsOptions = {
    email: {
      type: "email",
    },
    password: {
      type: "password",
    },
  };

  render() {
    let { handleSubmit } = this.props;
    handleSubmit = handleSubmit ?? this.handleSubmit;

    return (
      <div className="py-8 sm:px-8 bg-gray-100 min-h-screen flex items-center">
        <div className="max-w-screen-md w-full mx-auto space-y-4 sm:space-y-8">
          <GraphandForm fields={this.fields} fieldsOptions={this.fieldsOptions} onSubmit={handleSubmit}>
            {({ fields, formRef, handleSubmit, isLoading }) => (
              <form ref={formRef} onSubmit={handleSubmit} className={isLoading ? "opacity-50" : ""}>
                <div className="card bg-white rounded-lg shadow divide-y divide-gray-200" onSubmit={this.handleSubmit}>
                  <div className="w-full flex flex-col p-6">
                    <div className="card-body py-4 w-full">
                      <img
                        alt="LNA solutions d'assurance"
                        className="mx-auto mb-3 d-block"
                        src="/images/lna_logo_white.png"
                        style={{ maxHeight: "150px" }}
                      />
                      {fields.render("email")}
                      {fields.render("password", { inputType: "password" })}
                    </div>
                    <div className="card-footer text-muted w-full flex justify-end">
                      <button
                        className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-secondary-700 bg-secondary-100 hover:bg-secondary-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary-500"
                        type={isLoading ? "button" : "submit"}
                      >
                        Connexion
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            )}
          </GraphandForm>
        </div>
      </div>
    );
  }
}

Login.defaultProps = {
  loading: false,
  handleSubmit: null,
};

export default Login;
