import { Dialog, Transition } from "@headlessui/react";
import React, { useState } from "react";
import graphandClient from "../utils/graphand";

const PreviewClaimModal = (props) => {
  const [isOpen, setIsOpen] = useState(true);

  function closeModal() {
    setIsOpen(false);
    props.onClose();
  }

  const controls = (
    <div className="flex items-stretch ml-4 flex-row-reverse">
      <button
        className="bg-white h-full truncate inline-flex items-center px-4 border border-gray-200 shadow-sm text-sm font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary-500"
        onClick={closeModal}
        type="button"
      >
        Fermer
      </button>
    </div>
  );

  const ClaimModel = graphandClient.getModel("Data:claims");
  return (
    <Transition as={React.Fragment} show={isOpen}>
      <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={closeModal}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75" />
          </Transition.Child>

          <span aria-hidden="true" className="inline-block h-screen align-middle">
            &#8203;
          </span>
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block w-full max-w-screen-2xl p-6 my-8 text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
              {ClaimModel.get(props._id).suspense((claim) => (
                <>
                  <div className="text-right mb-4">
                    <button
                      className="bg-white h-full truncate inline-flex items-center px-4 py-2 border border-gray-200 shadow-sm text-sm font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary-500"
                      onClick={closeModal}
                      type="button"
                    >
                      Fermer
                    </button>
                  </div>

                  <div className="space-y-6">
                    <div>
                      <h2 className={`font-bold leading-7 text-secondary-900 sm:text-2xl sm:truncate mb-3`}>Aperçu du sinistre</h2>
                      <div className="grid grid-cols-3 gap-4">
                        <div className="col-span-1">
                          <h4 className="font-medium text-primary-500">Numéro de sinistre</h4>
                          {claim.renderFieldView("claimRef")}
                        </div>
                        <div className="col-span-1">
                          <h4 className="font-medium text-primary-500">Nature</h4>
                          {claim.renderFieldView("nature")}
                        </div>
                        <div className="col-span-1">
                          <h4 className="font-medium text-primary-500">Statut</h4>
                          {claim.renderFieldView("statut")}
                        </div>
                        <div className="col-span-1">
                          <h4 className="font-medium text-primary-500">Montant réglé</h4>
                          {claim.renderFieldView("amount")}
                        </div>
                        <div className="col-span-1">
                          <h4 className="font-medium text-primary-500">Montant frais réglé</h4>
                          {claim.renderFieldView("feesAmount")}
                        </div>
                        <div className="col-span-1">
                          <h4 className="font-medium text-primary-500">Montant recours</h4>
                          {claim.renderFieldView("appealAmount")}
                        </div>
                        <div className="col-span-1">
                          <h4 className="font-medium text-primary-500">Provision</h4>
                          {claim.renderFieldView("provision")}
                        </div>
                        <div className="col-span-1">
                          <h4 className="font-medium text-primary-500">Provision de l'expert</h4>
                          {claim.renderFieldView("expertProvision")}
                        </div>
                        <div className="col-span-1">
                          <h4 className="font-medium text-primary-500">Provision recours</h4>
                          {claim.renderFieldView("appealProvision")}
                        </div>
                        <div className="col-span-1">
                          <h4 className="font-medium text-primary-500">Date de survenance</h4>
                          {claim.renderFieldView("date")}
                        </div>
                        <div className="col-span-1">
                          <h4 className="font-medium text-primary-500">Date de paiement</h4>
                          {claim.renderFieldView("payDate")}
                        </div>
                        <div className="col-span-1">
                          <h4 className="font-medium text-primary-500">Date de déclaration</h4>
                          {claim.renderFieldView("startDate")}
                        </div>
                        <div className="col-span-1">
                          <h4 className="font-medium text-primary-500">Date de cloture</h4>
                          {claim.renderFieldView("endDate")}
                        </div>
                        <div className="col-span-1">
                          <h4 className="font-medium text-primary-500">Date ouverture de chantier</h4>
                          {claim.renderFieldView("openingDate")}
                        </div>
                        <div className="col-span-1">
                          <h4 className="font-medium text-primary-500">Gestionnaire</h4>
                          {claim.renderFieldView("manager")}
                        </div>

                        <div className="col-span-3">
                          <h4 className="font-medium text-primary-500">Circonstances</h4>
                          {claim.renderFieldView("circonstances")}
                        </div>

                        <div className="col-span-3">
                          <h4 className="font-medium text-primary-500">Observations de l'expert</h4>
                          {claim.renderFieldView("observations")}
                        </div>

                        <div className="col-span-3">
                          <h4 className="font-medium text-primary-500">Commentaires</h4>
                          {claim.renderFieldView("comments")}
                        </div>
                      </div>
                    </div>

                    <div>
                      <h2 className={`font-bold leading-7 text-secondary-900 sm:text-2xl sm:truncate mb-3`}>Tiers lésé</h2>
                      <div className="grid grid-cols-3 gap-4">
                        <div className="col-span-1">
                          <h4 className="font-medium text-primary-500">Prénom</h4>
                          {claim.renderFieldView("firstname")}
                        </div>
                        <div className="col-span-1">
                          <h4 className="font-medium text-primary-500">Nom</h4>
                          {claim.renderFieldView("lastname")}
                        </div>
                        <div className="col-span-1">
                          <h4 className="font-medium text-primary-500">Email</h4>
                          {claim.renderFieldView("email")}
                        </div>
                        <div className="col-span-1">
                          <h4 className="font-medium text-primary-500">Téléphone</h4>
                          {claim.renderFieldView("phone")}
                        </div>
                        <div className="col-span-1">
                          <h4 className="font-medium text-primary-500">Assureur</h4>
                          {claim.renderFieldView("assureur")}
                        </div>
                        <div className="col-span-3">
                          <h4 className="font-medium text-primary-500">Adresse</h4>
                          {claim.renderFieldView("address")}
                        </div>
                        <div className="col-span-1">
                          <h4 className="font-medium text-primary-500">Code postal</h4>
                          {claim.renderFieldView("postalcode")}
                        </div>
                        <div className="col-span-1">
                          <h4 className="font-medium text-primary-500">Ville</h4>
                          {claim.renderFieldView("city")}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ))}
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default PreviewClaimModal;
