import React from "react";
import { Link, NavLink, withRouter } from "react-router-dom";
import "./index.scss";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

class Tabs extends React.Component {
  render() {
    const { items } = this.props;
    const pathName = this.props.location.pathname;
    items.forEach((item) => {
      item.current = item.current ?? item.link === pathName;
    });

    return (
      <div>
        <div className="sm:hidden">
          <label htmlFor="tabs" className="sr-only">
            Sélectionner
          </label>
          <select
            id="tabs"
            name="tabs"
            className="block w-full focus:ring-secondary-500 focus:border-secondary-500 border-gray-300 rounded-md"
            defaultValue={items.find((tab) => tab.current)?.label}
          >
            {items.map((tab, index) => (
              <option key={index}>{tab.label}</option>
            ))}
          </select>
        </div>
        <div className="hidden sm:block">
          <div className="border-b border-gray-200">
            <nav className="-mb-px flex space-x-8" aria-label="Tabs">
              {items.map((tab, index) => {
                return (
                  <NavLink
                    key={index}
                    to={tab.link}
                    className={classNames(
                      tab.current
                        ? "border-secondary-500 text-secondary-600"
                        : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300",
                      "group inline-flex items-center py-4 px-1 border-b-2 font-medium text-sm transition",
                    )}
                    aria-current={tab.current ? "page" : undefined}
                  >
                    {tab.icon ? (
                      <tab.icon
                        className={classNames(tab.current ? "text-secondary-500" : "text-gray-400 group-hover:text-gray-500", "-ml-0.5 mr-2 h-5 w-5")}
                        aria-hidden="true"
                      />
                    ) : null}
                    <span>{tab.label}</span>
                  </NavLink>
                );
              })}
            </nav>
          </div>
        </div>
      </div>
    );
  }
}

Tabs.defaultProps = {
  items: [],
};

Tabs = withRouter(Tabs);

export default Tabs;
