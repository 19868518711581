"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var GraphandErrorCodes;
(function (GraphandErrorCodes) {
    GraphandErrorCodes["UNKNOWN"] = "unknown";
    GraphandErrorCodes["UNAUTHORIZED"] = "unauthorized";
    GraphandErrorCodes["INVALID_TOKEN"] = "invalid_token";
    GraphandErrorCodes["EXPIRED_TOKEN"] = "expired_token";
    GraphandErrorCodes["NOT_FOUND"] = "not_found";
    GraphandErrorCodes["INVALID_PLUGIN"] = "invalid_plugin";
})(GraphandErrorCodes || (GraphandErrorCodes = {}));
class GraphandError extends Error {
    constructor(message = "Unknown error", code = GraphandErrorCodes.UNKNOWN, status = 500, target) {
        super();
        const { constructor } = Object.getPrototypeOf(this);
        this.name = constructor.name;
        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, constructor);
        }
        if (message) {
            Object.assign(this, { message });
        }
        if (code) {
            Object.assign(this, { code });
        }
        if (status) {
            Object.assign(this, { status });
        }
        if (target) {
            Object.assign(this, { target });
        }
    }
    static fromJSON(input, status) {
        switch (input.type) {
            case "GraphandValidationError":
                const GraphandValidationError = require("./GraphandValidationError").default;
                return new GraphandValidationError(input.message, input.field, input.code);
            case "GraphandError":
            default:
                return new GraphandError(input.message, input.code, status !== null && status !== void 0 ? status : input.status, input.target);
        }
    }
    static parse(input) {
        const errors = Array.isArray(input)
            ? input
            : typeof input === "object"
                ? [input]
                : typeof input === "string"
                    ? [
                        {
                            type: "GraphandError",
                            message: input,
                        },
                    ]
                    : [];
        return errors.map((e) => GraphandError.fromJSON(e));
    }
    toJSON() {
        return {
            type: this.name,
            message: this.message,
            code: this.code,
            target: this.target,
            status: this.status,
        };
    }
}
GraphandError.Codes = GraphandErrorCodes;
exports.default = GraphandError;
