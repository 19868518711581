"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const rxjs_1 = require("rxjs");
const _propertiesMiddleware = (fromModel, toModel, middleware) => {
    const fromKeys = Object.getOwnPropertyNames(fromModel.prototype);
    const toKeys = Object.getOwnPropertyNames(toModel.prototype);
    const patchKeys = fromKeys.filter((key) => !toKeys.includes(key) && typeof fromModel.prototype[key] === "function");
    const patch = {};
    patchKeys.forEach((key) => {
        patch[key] = function () {
            return middleware(this, fromModel.prototype[key], arguments);
        };
    });
    return Object.assign(toModel.prototype, patch);
};
class GraphandModelPromise {
    constructor(executor, model, query) {
        const self = this;
        self.executor = executor;
        // if (!model || !(model.prototype instanceof GraphandModel)) {
        //   throw new Error("Please provide a valid model");
        // }
        self.model = model;
        self.query = query || {};
        if (model) {
            model._promiseAvailableMethods.forEach((slug) => {
                self[slug] = model.prototype[slug].bind(self);
            });
        }
        Object.defineProperty(self, "executor", { enumerable: false });
        Object.defineProperty(self, "model", { enumerable: false });
        if (self._id) {
            Object.defineProperty(self, "_id", { enumerable: true, value: self._id });
            Object.defineProperty(self, "query", { enumerable: false });
        }
        if (!Object.keys(self.query).length) {
            Object.defineProperty(self, "query", { enumerable: false });
        }
    }
    get _id() {
        var _a;
        const { query } = this;
        const _id = typeof query === "object" && ((_a = query.query) === null || _a === void 0 ? void 0 : _a._id) ? query.query._id : query;
        return typeof _id === "string" ? _id : null;
    }
    toString() {
        return this._id;
    }
    encodeQuery() {
        return this._id;
    }
    toPromise() {
        return new Promise(this.executor);
    }
    createObservable() {
        this._observable = new rxjs_1.Observable((subscriber) => {
            this.then((res) => { var _a; return (this._resSub = (_a = res === null || res === void 0 ? void 0 : res.subscribe) === null || _a === void 0 ? void 0 : _a.apply(res, [(r) => subscriber.next(r)])); });
        });
    }
    subscribe(callback) {
        if (!this._observable) {
            this.createObservable();
        }
        const sub = this._observable.subscribe(callback);
        const unsubscribe = sub.unsubscribe;
        sub.unsubscribe = () => {
            var _a;
            unsubscribe.apply(sub);
            (_a = this._resSub) === null || _a === void 0 ? void 0 : _a.unsubscribe();
            delete this._observable;
        };
        return sub;
    }
}
Symbol.toStringTag;
_propertiesMiddleware(Promise, GraphandModelPromise, (item, fn, args) => {
    item.promise = item.promise || item.toPromise();
    return fn.apply(item.promise, args);
});
exports.default = GraphandModelPromise;
