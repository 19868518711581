import React from "react";

const ViewText = (props) => {
  if (!props.value) {
    return null;
  }

  let content = props.value;

  if (Array.isArray(props.value)) {
    content = props.value.join(", ");
  }

  if (props.field?.rte) {
    return <div dangerouslySetInnerHTML={{ __html: content }} />;
  }

  return props.options?.minimal ? <span className="line-clamp-2">{content}</span> : String(content);
};

export default ViewText;
