import React from "react";
import Breadcrumbs from "../../../components/Breadcrumbs";

import graphandClient from "../../../utils/graphand";
import Heading from "../../../components/Heading";
import ROLES from "../../../enums/roles";
import { GraphandForm } from "graphand-react";
import { CheckIcon } from "@heroicons/react/solid";
import CrudButton from "../../../components/CrudButton";

const steps = ["Client", "Contrat", "Activités", "Responsabilité civile", "Confirmation"];

class ContractRCProCreate extends React.Component {
  lastAccessStep = 0;
  contract;
  customer;
  state = {
    currentStep: 0,
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.currentStep > this.lastAccessStep) {
      this.lastAccessStep = this.state.currentStep;
    }
  }

  handleCreateAccount = async (values) => {
    const customer = await graphandClient.getModel("Account").create({
      ...values,
      agency: values.agency || graphandClient.authmanager.user.agency?._id,
      role: ROLES.CUSTOMER,
      password: Math.random().toString(36).substr(2, 9),
    });

    const contract = await graphandClient
      .getModel("Data:contrat-rc-pro")
      .create({ customer, status: "draft", "payment-mode": "year", responsible: graphandClient.authmanager.user.agency?._id });

    this.customer = customer;
    this.contract = contract;

    this.nextStep();
  };

  nextStep = () => {
    this.setState(({ currentStep }) => ({ currentStep: currentStep + 1 }));
  };

  handleSubmit = async () => {
    // this.setState({ loading: true });
    // const { values } = this.state;
    //
    // let payload = { ...values, status: "draft" };
    // if (this.state.newCustomer) {
    //   payload.customer = this.state.newCustomer._id;
    // }
    // const contract = await graphandClient.getModel("Data:contrat-rc-pro").create(payload);
    // this.setState({ loading: false });
    // this.props.history.push(`/contracts-rc-pro/${contract._id}/details/medias`);
  };

  render() {
    const [ContractRCPro, Account] = graphandClient.getModels(["Data:contrat-rc-pro", "Account"]);

    console.log(this.contract);

    return (
      <div className="py-8 sm:px-8 bg-gray-100">
        <div className="max-w-screen-xl w-full mx-auto space-y-4 sm:space-y-8 ContractRCProCreate">
          <Breadcrumbs pages={[{ label: "Tarificateur" }]} />
          <Heading title={"Tarificateur"} />
          <div className="my-4 bg-white border border-gray-200 rounded-md shadow">
            <nav aria-label="Progress" className="border-b border-gray-300">
              <ol className="divide-y divide-gray-300 md:flex md:divide-y-0">
                {steps.map((step, stepIdx) => (
                  <li key={step.ref} className="relative md:flex-1 md:flex">
                    {stepIdx < this.state.currentStep ? (
                      <a
                        className={`${stepIdx <= this.lastAccessStep ? "cursor-pointer" : ""} group flex items-center w-full`}
                        onClick={() => stepIdx <= this.lastAccessStep && this.setState({ currentStep: stepIdx })}
                      >
                        <span className="px-6 py-4 flex items-center text-sm font-medium">
                          <span className="flex-shrink-0 w-10 h-10 flex items-center justify-center bg-primary-600 rounded-full group-hover:bg-primary-800">
                            <CheckIcon className="w-6 h-6 text-white" aria-hidden="true" />
                          </span>
                          <span className="ml-4 text-sm font-medium text-gray-900">{step}</span>
                        </span>
                      </a>
                    ) : stepIdx == this.state.currentStep || (stepIdx == 0 && !this.state.currentStep) ? (
                      <a
                        className={`${stepIdx <= this.lastAccessStep ? "cursor-pointer" : ""} px-6 py-4 flex items-center text-sm font-medium`}
                        onClick={() => stepIdx <= this.lastAccessStep && this.setState({ currentStep: stepIdx })}
                        aria-current="step"
                      >
                        <span className="flex-shrink-0 w-10 h-10 flex items-center justify-center border-2 border-primary-600 rounded-full">
                          <span className="text-primary-600">{stepIdx + 1}</span>
                        </span>
                        <span className="ml-4 text-sm font-medium text-primary-600 font-bold">{step}</span>
                      </a>
                    ) : (
                      <a
                        className={`${stepIdx <= this.lastAccessStep ? "cursor-pointer" : ""} group flex items-center`}
                        onClick={() => stepIdx <= this.lastAccessStep && this.setState({ currentStep: stepIdx })}
                      >
                        <span className="px-6 py-4 flex items-center text-sm font-medium">
                          <span className="flex-shrink-0 w-10 h-10 flex items-center justify-center border-2 border-gray-300 rounded-full group-hover:border-gray-400">
                            <span className="text-gray-500 group-hover:text-gray-900">{stepIdx + 1}</span>
                          </span>
                          <span className="ml-4 text-sm font-medium text-gray-500 group-hover:text-gray-900">{step}</span>
                        </span>
                      </a>
                    )}

                    {stepIdx !== steps.length - 1 ? (
                      <>
                        {/* Arrow separator for lg screens and up */}
                        <div className="hidden md:block absolute top-0 right-0 h-full w-5" aria-hidden="true">
                          <svg className="h-full w-full text-gray-300" viewBox="0 0 22 80" fill="none" preserveAspectRatio="none">
                            <path d="M0 -2L20 40L0 82" vectorEffect="non-scaling-stroke" stroke="currentcolor" strokeLinejoin="round" />
                          </svg>
                        </div>
                      </>
                    ) : null}
                  </li>
                ))}
              </ol>
            </nav>

            <div className="p-8">
              {(() => {
                switch (this.state.currentStep) {
                  case 0:
                    return (
                      <GraphandForm
                        key="step0"
                        map={["agency", "firstname", "lastname", "email", "phone", "slug", "address", "postal", "city", "iban", "bic", "bank-name"]}
                        model={Account}
                        template={Account.inlineTemplate.bind(this)}
                        onSubmit={this.handleCreateAccount}
                      />
                    );
                  case 1:
                    return (
                      <GraphandForm
                        key="step1"
                        instance={this.contract}
                        onSubmit={async (values) => {
                          await this.contract.update({ set: values });
                          this.nextStep();
                        }}
                        template={ContractRCPro.contractFormTemplate.bind(this)}
                      />
                    );
                  case 2:
                    return (
                      <GraphandForm
                        key="step2"
                        instance={this.contract}
                        onSubmit={async (values) => {
                          await this.contract.update({ set: values });
                          this.nextStep();
                        }}
                        template={ContractRCPro.activitiesFormTemplate.bind(this)}
                      />
                    );
                  case 3:
                    return (
                      <GraphandForm
                        key="step3"
                        instance={this.contract}
                        onSubmit={async (values) => {
                          await this.contract.update({ set: values });
                          this.nextStep();
                        }}
                        template={ContractRCPro.responsabiliteFormTemplate.bind(this)}
                      />
                    );
                  case 4:
                    const contract = this.contract;
                    return (
                      <form
                        onSubmit={(e) => {
                          e.preventDefault();
                          e.stopPropagation();
                          this.props.history.push(`/contracts-rc-pro/${contract._id}/details/medias`);
                        }}
                      >
                        <h2 className="text-xl">Cotisations annuelles : {contract.yearPrice}€</h2>
                        <div className="text-lg mb-6">
                          Paiement {contract.renderFieldView("payment-mode")} n°1 : {contract.tiercePrice}€{" "}
                          <span className="text-gray-500">(à régler avant souscription)</span>
                          <br />
                          <CrudButton
                            type={"update"}
                            className="mt-2"
                            label="Télécharger la proposition"
                            onClick={() => contract.downloadContract()}
                          />
                        </div>

                        <h2 className="text-lg mb-2">Le proposant déclare :</h2>
                        <ul className="text-sm">
                          <li>Ne pas exercer l'activité de constructeurs de Maisons Individuelles au sens de la loi de décembre 1990.</li>
                          <li>
                            Ne pas être contractant général soit une société proposant à un client une offre « clé en main » sur mesure qui comprend
                            l’étude du projet, le coût de réalisation et les assurances.
                          </li>
                          <li>Ne pas être entreprise générale de bâtiment.</li>
                          <li>Ne pas intervenir en qualité de maître d’œuvre, de BET d’AMO.</li>
                          <li>Ne pas intervenir en qualité de promoteur immobilier.</li>
                          <li>Ne pas réaliser des travaux de technique non courante.</li>
                          <li>Ne pas intervenir sur des monuments historiques.</li>
                          <li>Ne pas intervenir en qualité de fabricant négociant importateur de matériaux de construction.</li>
                          <li>Ne pas avoir une activité principale de négoce ou fabrication de matériaux de construction non suivie de pose.</li>
                          <li>Ne pas réaliser de travaux de technique non courante.</li>
                          <li>Ne pas intervenir sur des moments historiques.</li>
                          <li>Ne pas être ou avoir été en redressement, liquidation judiciaire au cours de 60 derniers mois.</li>
                          <li>Ne pas avoir déclaré au cours des 36 derniers mois un(des) accident(s) du travail.</li>
                          <li>Ne pas avoir fait l’objet d’une ou plusieurs mises en cause au cours des 36 derniers mois.</li>
                          <li>Ne pas avoir connaissance de situations engageant sa responsabilité.</li>
                          <li>Ne pas avoir recours à la sous-traitance.</li>
                          <li>
                            N’avoir aucune activité de conception, de direction et/ou de surveillance de travaux que ce soit en qualité de locateur ou
                            de sous-traitant.
                          </li>
                          <li>
                            Ne pas exercer l'activité de construction, modification ou réparation de ponts, viaducs, tours, flèches, cheminées de
                            four, pylônes.
                          </li>
                          <li>
                            Ne pas exercer l'activité de battage de pieux, travaux dans les carrières et les mines, construction de tunnels, travaux à
                            bord des navires.
                          </li>
                          <li>Ne pratiquer aucun travaux nécessitant l’utilisation d’explosifs.</li>
                          <li>Ne pratiquer aucun travaux à proximité des avions ou dans les zones d'aéroport.</li>
                          <li>
                            Ne pratiquer aucun travaux sur ou dans : Les docks, les ports ou les chemins de fers ou les installations chimiques ou
                            pétrochimiques, les raffineries pétrolifères ou de gaz, les installations de stockage de gaz ou centrales thermiques ou
                            nucléaires ou travaux sous terrain, subaquatiques, et en général les installations de stockage de gaz ou pétrole offshore.
                          </li>
                          <li>Ne pas exercer l'activité autre que celles autorisées selon le tableau « nomenclature des activités assurables ».</li>
                          <li>Que les produits mis en œuvre font l'objet d'avis techniques.</li>
                          <li>Respecter les règles de l’art ou les normes AFNOR et les DTU qui pourraient s’y substituer.</li>
                          <li>Respecter les conformités aux normes CE des produits fabriqués et/ou négociés et/ou installés.</li>
                        </ul>

                        <div className="mt-8 flex justify-end">
                          <CrudButton type={"submit"} className="mt-8" />
                        </div>
                      </form>
                    );
                  default:
                    return null;
                }
              })()}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ContractRCProCreate;
