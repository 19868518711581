import { Transition } from "@headlessui/react";
import React from "react";
import "./index.scss";
import FieldsPopover from "./fieldsPopover";
import GraphandUIListLine from "./line";
import Pagination from "./pagination";
import { faSort } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import isEqual from "fast-deep-equal";
import { Translation } from "react-i18next";

class GraphandUIList extends React.Component {
  state = {
    page: 1,
    fields: [],
    selection: [],
    showSelectedOnly: false,
    listQuery: null,
    sort: undefined,
  };

  constructor(props) {
    super(props);

    const fields = props.map;
    this.state.fields = fields;
  }

  componentDidMount() {
    if (this.props.cacheKey) {
      try {
        const prevState = JSON.parse(localStorage.getItem(this.props.cacheKey));
        if (prevState) {
          this.setState(prevState);
        }
      } catch (e) {}
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.cacheKey) {
      localStorage.setItem(this.props.cacheKey, JSON.stringify(this.state));
    }

    const newState = {};

    if (this.state.showSelectedOnly && !prevState.showSelectedOnly) {
      newState.listQuery = { ...Object.assign(this.state.listQuery || {}, { ids: this.selection }) };
      // newState.page = 1;
    } else if (!this.state.showSelectedOnly && prevState.showSelectedOnly) {
      newState.listQuery = { ...this.state.listQuery };
      delete newState.listQuery.ids;
      // newState.page = 1;
    }

    if (this.props.map !== prevProps.map) {
      newState.fields = this.props.map;
    }

    if (!isEqual(this.props.query || {}, prevProps.query || {})) {
      newState.page = 1;
    }

    if (Object.keys(newState).length) {
      this.setState(newState);
    }

    if (this.props.onPageChange && this.state.page !== prevState.page) {
      this.props.onPageChange(this.state.page);
    }

    if (this.props.onSortChange && this.state.sort !== prevState.sort) {
      this.props.onSortChange(this.state.sort);
    }
  }

  onSelectionChange(selection) {
    this.props.onSelectionChange?.apply(undefined, [selection]) || this.setState({ selection });
  }

  get selection() {
    return (this.props.selection ?? this.state.selection) || [];
  }

  get query() {
    let query = { $and: [this.state.listQuery?.query, this.props.query].filter(Boolean) };

    if (query.$and.length === 1) {
      // eslint-disable-next-line prefer-destructuring
      query = query.$and[0];
    } else if (!query.$and.length) {
      query = undefined;
    }

    let { page } = this.state;

    if (page === 1) {
      page = undefined;
    }

    return {
      count: true,
      pageSize: this.props.pageSize,
      page,
      ids: this.props.ids,
      sort: this.state.sort,
      ...this.state.listQuery,
      query,
    };
  }

  toggleSort = (slug) => {
    this.setState(({ sort }) => (sort === slug ? { sort: `-${slug}` } : sort === `-${slug}` ? { sort: undefined } : { sort: slug }));
  };

  render() {
    const { query } = this;

    const fallback = (
      <div className="GraphandUIList">
        <div className="shadow border-b border-gray-200 sm:rounded-lg mt-2 sm:mt-4">
          <div className="flex items-center justify-between bg-gray-100 border-t sm:rounded-t-lg">
            <div className="flex items-center">
              <div className="w-14 flex items-center justify-center">
                {this.props.model.getList(query).suspense(
                  (list) => {
                    const checked = list.every((item) => this.selection.includes(item._id));
                    return (
                      <input
                        checked={checked}
                        className="cursor-pointer focus:ring-primary-500 h-4 w-4 text-primary-600 border-gray-300 rounded"
                        onChange={() => {
                          const selectionSet = new Set(this.selection);
                          if (checked) list.forEach((item) => selectionSet.delete(item._id));
                          else list.forEach((item) => selectionSet.add(item._id));
                          this.onSelectionChange([...selectionSet]);
                        }}
                        type="checkbox"
                      />
                    );
                  },
                  { updateKey: query },
                )}
              </div>
              <div
                className={`${
                  this.selection.length ? "opacity-100" : "opacity-50"
                } px-1 sm:px-2 text-gray-500 flex items-center transition ease-out duration-100`}
              >
                {this.selection.length || "Aucun"} élément{this.selection.length > 1 ? "s" : null} sélectionné
                {this.selection.length > 1 ? "s" : null}
                <div className="space-x-2 ml-2">
                  {this.selection.length ? (
                    <button
                      className="px-1 text-sm bg-transparent rounded-md font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500 text-primary-600 hover:text-primary-500"
                      onClick={() => this.onSelectionChange([])}
                      type="button"
                    >
                      Vider la sélection
                    </button>
                  ) : null}
                  {this.selection.length || this.state.showSelectedOnly ? (
                    <button
                      className="px-1 text-sm bg-transparent rounded-md font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary-500 text-secondary-600 hover:text-secondary-500"
                      onClick={() => this.setState((prevState) => ({ showSelectedOnly: !prevState.showSelectedOnly }))}
                      type="button"
                    >
                      {this.state.showSelectedOnly ? "Afficher la liste" : "Afficher la sélection"}
                    </button>
                  ) : null}
                  {this.selection.length ? (
                    <button
                      className="px-1 text-sm bg-transparent rounded-md font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 text-red-600 hover:text-red-500 hover:bg-red-100"
                      onClick={() => this.props.model.delete({ ids: this.selection }).then(() => this.onSelectionChange([]))}
                      type="button"
                    >
                      [trash] Supprimer
                    </button>
                  ) : null}
                  {this.selection.length ? (
                    <button
                      className="px-1 text-sm bg-transparent rounded-md font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary-500 text-secondary-600 hover:text-secondary-500 hover:bg-secondary-100"
                      onClick={() => null}
                      type="button"
                    >
                      [penToSquare] Modifier
                    </button>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="flex items-center px-2 sm:px-4 py-2">
              <FieldsPopover fields={this.state.fields} model={this.props.model} onFieldsChange={(fields) => this.setState({ fields })} />
            </div>
          </div>
          <div className="relative">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-100">
                <tr>
                  <th className="relative w-14" scope="col" />
                  {this.state.fields.map((slug) => {
                    const field = this.props.model.fields[slug];
                    if (!field) {
                      return null;
                    }

                    const cellProps =
                      field.options?.tableCellProps && typeof field.options?.tableCellProps === "function"
                        ? field.options?.tableCellProps(field, slug)
                        : field.options?.tableCellProps || {};

                    let label = field.__dataField?.name;
                    if (label === undefined) {
                      label = <Translation>{(t) => t(`labels.fields.${slug}`)}</Translation>;
                    }

                    return (
                      <th
                        {...cellProps}
                        className={`text-xs font-medium truncate text-gray-500 tracking-wider py-0 ${cellProps.className || ""}`}
                        scope="col"
                      >
                        <div className="flex items-center px-1 sm:px-2 py-2">
                          <span className="uppercase truncate mr-2" title={slug}>
                            {label}
                          </span>
                        </div>
                      </th>
                    );
                  })}
                  <th className="relative" scope="col" />
                </tr>
              </thead>
              <tbody>
                {Array(10)
                  .fill(null)
                  .map((_, index) =>
                    React.createElement(GraphandUIListLine, {
                      loading: true,
                      even: index % 2,
                      fields: this.state.fields,
                      model: this.props.model,
                    }),
                  )}
              </tbody>
            </table>
          </div>
          <div className="bg-white px-4 flex items-center justify-between border-t border-gray-200 sm:px-6 h-14 sm:rounded-b-lg overflow-hidden">
            {this.props.model
              .getList(query)
              .suspense(
                (list) =>
                  (list.count || this.props.count) > this.props.pageSize ? (
                    <Pagination
                      count={list.count}
                      nbPages={6}
                      onPageChange={(page) => this.setState({ page })}
                      page={this.state.page}
                      pageSize={this.props.pageSize}
                    />
                  ) : null,
                { updateKey: query },
              )}
          </div>
        </div>
      </div>
    );

    const empty = (
      <div className="mt-4 relative w-full flex items-center justify-center border-4 border-dashed border-gray-300 rounded-xl h-96 text-2xl font-bold text-gray-300">
        La liste est vide
      </div>
    );

    const render = (list, loading = false) => (
      <div className="GraphandUIList -mt-px">
        <div className="shadow border-b border-gray-200 sm:rounded-lg">
          {!this.props.disableFieldsPopover ? (
            <div className="flex items-center justify-between bg-gray-100 border-t sm:rounded-t-lg">
              <div />
              <div className="flex items-center px-2 sm:px-4 py-2">
                <FieldsPopover fields={this.state.fields} model={this.props.model} onFieldsChange={(fields) => this.setState({ fields })} />
              </div>
            </div>
          ) : null}
          <div className="relative">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-100">
                <tr>
                  {this.state.fields.map((slug) => {
                    const field = this.props.model.fields[slug];
                    if (!field) {
                      return null;
                    }

                    const cellProps =
                      field.options?.tableCellProps && typeof field.options?.tableCellProps === "function"
                        ? field.options?.tableCellProps(field, slug)
                        : field.options?.tableCellProps || {};

                    let label = field.__dataField?.name;
                    if (label === undefined) {
                      label = <Translation>{(t) => t(`labels.fields.${slug}`)}</Translation>;
                    }

                    return (
                      <th
                        {...cellProps}
                        className={`text-xs font-medium truncate text-gray-500 tracking-wider py-0 ${cellProps.className || ""}`}
                        scope="col"
                      >
                        <div className="flex items-center px-1 sm:px-2 py-2" onClick={() => !this.props.disableSort && this.toggleSort(slug)}>
                          <span className="uppercase truncate mr-2" title={slug}>
                            {label}
                          </span>
                          <span className="sort">
                            <Transition
                              className="transform"
                              enter="transition ease-out duration-200"
                              enterFrom="scale-0"
                              enterTo="scale-1"
                              leave="transition ease-in duration-200"
                              leaveFrom="scale-1"
                              leaveTo="scale-0"
                              show={this.state.sort === slug || this.state.sort === `-${slug}`}
                            >
                              <div
                                className={`transform transition ease-in duration-200 text-secondary-500 ${
                                  this.state.sort === slug ? "rotate-180" : "rotate-0"
                                }`}
                              >
                                <FontAwesomeIcon icon={faSort} />
                              </div>
                            </Transition>
                          </span>
                        </div>
                      </th>
                    );
                  })}
                  <th className="relative" scope="col" />
                </tr>
              </thead>
              <tbody>
                {list.map((item, index) => (
                  <GraphandUIListLine
                    key={item._id}
                    basePath={this.props.basePath}
                    even={index % 2}
                    fields={this.state.fields}
                    item={item}
                    loading={loading}
                    model={this.props.model}
                    controls={this.props.controls}
                    onToggleCheck={() => {
                      const selectionSet = new Set(this.selection);
                      if (selectionSet.has(item._id)) selectionSet.delete(item._id);
                      else selectionSet.add(item._id);
                      this.onSelectionChange([...selectionSet]);
                    }}
                  />
                ))}
              </tbody>
            </table>
          </div>
          {(list.count || this.props.count) > this.props.pageSize ? (
            <div className="bg-white px-4 flex items-center justify-between border-t border-gray-200 sm:px-6 h-14 sm:rounded-b-lg overflow-hidden">
              <Pagination
                count={list.count || this.props.count}
                nbPages={6}
                onPageChange={(page) => this.setState({ page })}
                page={this.state.page}
                pageSize={this.props.pageSize}
              />
            </div>
          ) : null}
        </div>
      </div>
    );

    if (this.props.list) {
      return render(this.props.list, this.props.loading);
    }

    return this.props.model.getList(query).suspense(
      (list, loading) => {
        if (!loading && !list.count) {
          return empty;
        }

        return render(list, loading);
      },
      {
        updateKey: query,
        subscribe: true,
        fallback,
      },
    );
  }
}

GraphandUIList.defaultProps = {
  map: ["_id"],
  controls: null,
  basePath: "",
  query: undefined,
  ids: undefined,
  list: null,
  count: 0,
  disableFieldsPopover: false,
  disableSort: false,
  loading: null,
  pageSize: 15,
};

export default GraphandUIList;
