import { faChevronDown, faGripVertical } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Popover, Transition } from "@headlessui/react";
import arrayMove from "array-move";
import React from "react";
import { SortableContainer, SortableElement, SortableHandle } from "react-sortable-hoc";
import { Translation } from "react-i18next";

const FieldsPickerItemHandle = SortableHandle(() => (
  <div className="h-5 w-5 flex items-center justify-center mr-2 cursor-move text-gray-500 hover:text-gray-700">
    <FontAwesomeIcon icon={faGripVertical} />
  </div>
));

const FieldsPickerItem = SortableElement((props) => {
  let label = props.field.__dataField?.name;
  if (label === undefined) {
    label = <Translation>{(t) => t(`labels.fields.${props.slug}`)}</Translation>;
  }

  return (
    <div className="p-1 z-10">
      <div className="hover:bg-gray-50 flex rounded-md items-center w-full text-sm text-gray-700 h-9 px-2">
        <FieldsPickerItemHandle />
        <input
          checked={props.fields.includes(props.slug)}
          className="focus:ring-primary-500 h-4 w-4 text-primary-600 border-gray-300 rounded"
          id={props.slug}
          name={props.slug}
          onChange={() => {
            let { fields } = props;
            if (fields.includes(props.slug)) fields = fields.filter((k) => k !== props.slug);
            else fields = fields.concat(props.slug);
            props.onChange(fields);
          }}
          type="checkbox"
        />
        <label className="truncate cursor-pointer ml-3 text-sm w-full font-medium text-gray-700" htmlFor={props.slug}>
          {label}
        </label>
      </div>
    </div>
  );
});

const FieldsPicker = SortableContainer((props) => {
  return (
    <div className="py-1">
      {props.fields.map((slug, index) => {
        const field = props.model.fields[slug];
        if (!field) {
          return null;
        }

        return <FieldsPickerItem key={slug} index={index} {...props} field={field} slug={slug} />;
      })}
    </div>
  );
});

const FieldsPopover = (props) => (
  <Popover as="div" className="relative inline-block text-left ml-3">
    <div>
      <Popover.Button
        aria-expanded="false"
        aria-haspopup="true"
        className="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-secondary-500"
        type="button"
      >
        {props.fields.length}
        <div className="h-5 w-5 flex items-center justify-center ml-2">
          <FontAwesomeIcon icon={faChevronDown} />
        </div>
      </Popover.Button>
    </div>

    <Transition
      as={React.Fragment}
      enter="transition ease-out duration-100"
      enterFrom="transform opacity-0 scale-95"
      enterTo="transform opacity-100 scale-100"
      leave="transition ease-in duration-75"
      leaveFrom="transform opacity-100 scale-100"
      leaveTo="transform opacity-0 scale-95"
    >
      <Popover.Panel
        aria-labelledby="user-menu-button"
        aria-orientation="vertical"
        className="z-10 max-h-80 overflow-y-auto origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100 focus:outline-none"
        role="menu"
        tabIndex={-1}
      >
        <form className="divide-y divide-gray-100" role="none">
          <FieldsPicker
            fields={props.fields}
            lockAxis="y"
            model={props.model}
            onChange={props.onFieldsChange}
            onSortEnd={({ oldIndex, newIndex }) => props.onFieldsChange(arrayMove(props.fields, oldIndex, newIndex))}
            useDragHandle
          />
          <div className="py-1">
            {Object.keys(props.model.fields)
              .filter((k) => !props.fields.includes(k))
              .map((slug) => {
                const field = props.model.fields[slug];
                if (!field) {
                  return null;
                }

                let label = field.__dataField?.name;
                if (label === undefined) {
                  label = <Translation>{(t) => t(`labels.fields.${slug}`)}</Translation>;
                }

                return (
                  <div className="p-1">
                    <div className="hover:bg-gray-50 flex rounded-md items-center w-full text-sm text-gray-700 h-9 px-2">
                      <input
                        checked={props.fields.includes(slug)}
                        className="focus:ring-primary-500 h-4 w-4 text-primary-600 border-gray-300 rounded"
                        id={slug}
                        name={slug}
                        onChange={() => {
                          let { fields } = props;
                          if (fields.includes(slug)) fields = fields.filter((k) => k !== slug);
                          else fields = fields.concat(slug);
                          props.onFieldsChange(fields);
                        }}
                        type="checkbox"
                      />
                      <label className="truncate cursor-pointer ml-3 text-sm w-full font-medium text-gray-700" htmlFor={slug}>
                        {label}
                      </label>
                    </div>
                  </div>
                );
              })}
          </div>
        </form>
      </Popover.Panel>
    </Transition>
  </Popover>
);

export default FieldsPopover;
