import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Translation } from "react-i18next";

const InputNumber = ({ options, value, onChange, errors, id, slug, field, inputRef }) => {
  let label = field.__dataField?.name || options.label;
  if (label === undefined) {
    label = <Translation>{(t) => t(`labels.fields.${slug}`)}</Translation>;
  }

  return (
    <div {...options}>
      <label className="block text-sm font-medium text-gray-700" htmlFor={id}>
        {label}
      </label>
      {options.description ? <p className="text-sm text-gray-500">{options.description}</p> : null}
      <div className="mt-1 relative rounded-md shadow-sm">
        <input
          ref={inputRef}
          className={`rounded-md block w-full border rounded-md shadow-sm py-2 h-11 px-3 mb-4 focus:outline-none sm:text-sm focus:border-light-secondary-500 ${
            errors?.length ? `border-red-300` : `border-gray-300 `
          }`}
          id={id}
          max={options.max}
          min={options.min}
          name={id}
          onChange={(e) => onChange(e.currentTarget.value)}
          type="number"
          step="any"
          value={value}
        />
        {errors?.length ? (
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
            <FontAwesomeIcon className="h-5 w-5 text-red-500" icon={faExclamationCircle} />
          </div>
        ) : null}
      </div>
      {errors.map((e) => (
        <p className="mt-2 text-sm text-red-600" id="email-error">
          {e.message}
        </p>
      ))}
    </div>
  );
};

export default InputNumber;
