import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./index.scss";
import graphandClient from "./utils/graphand";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationFR from "./locales/fr/translation.json";

// the translations
const resources = {
  fr: {
    translation: translationFR,
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "fr",
  });

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root"),
);
