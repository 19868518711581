import React from "react";
import graphandClient from "../../../utils/graphand";
import Breadcrumbs from "../../../components/Breadcrumbs";
import Heading from "../../../components/Heading";
import { Menu, Transition } from "@headlessui/react";
import GraphandUIList from "../../../components/GraphandUIList";
import { GraphandForm } from "graphand-react";
import { GraphandFieldText } from "graphand-js";

const filters = [
  // { query: {}, label: "Tous les contrats" },
  {
    query: { _id: { $exists: true }, $or: [{ status: "pending" }, { status: "draft" }] },
    label: "Projets",
    displayCode: false,
    displayStatus: false,
  },
  {
    query: { _id: { $exists: true }, $or: [{ status: "active" }, { status: "archived" }] },
    label: "Contrats",
    displayCode: true,
    displayStatus: true,
  },
  // { query: { $or: [{ status: "archived" }] }, label: "Archivés", displayCode: true, displayStatus: true },
  // { query: { status: "draft" }, label: "Brouillons", displayCode: false, displayStatus: false },
  // { query: { status: "archived" }, label: "Résiliés" },
];

class ContractsRCProList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      filter: filters[0],
      q: "",
      ids: undefined,
    };

    try {
      const prevState = JSON.parse(localStorage.getItem("ContractsListState"));
      if (prevState) {
        this.state = prevState;
      }
    } catch (e) {}

    this.searchTemplate = ({ fields, formRef, handleSubmit }) => (
      <form onSubmit={handleSubmit} ref={formRef}>
        {fields.render("q", { label: null, placeholder: "Rechercher un projet ou un contrat" })}
      </form>
    );
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    localStorage.setItem("ContractsListState", JSON.stringify(this.state));

    if (this.state.q && this.state.q !== prevState.q) {
      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout);
      }

      const EsMapping = graphandClient.getModel("EsMapping");
      this.searchTimeout = setTimeout(() => {
        let query;
        try {
          const code = this.state.q.match(/\-([0-9].+?)$/)[1];
          query = {
            query_string: {
              query: code,
            },
          };
        } catch (e) {}

        query = query || {
          query_string: {
            query: Number.isNaN(parseInt(this.state.q)) ? `*${this.state.q}*` : this.state.q,
          },
        };

        EsMapping.search("60f719431720d38371647c5a", {
          query,
          size: 10,
        }).then((res) => {
          const ids = res.hits.hits.map((r) => r._id);
          this.setState({ ids });
        });
      }, 500);
    }
  }

  render() {
    const controls = (contract) => {
      let _list = [
        {
          label: "Détails",
          handler: () => this.props.history.push(`/contracts-rc-pro/${contract._id}/details`),
        },
        {
          label: "Documents",
          handler: () => this.props.history.push(`/contracts-rc-pro/${contract._id}/details/medias`),
        },
      ];

      if (graphandClient.authmanager.user?.isAdmin()) {
        _list = _list.concat([
          {
            label: "Modifier la tarification",
            handler: () => this.props.history.push(`/contracts-rc-pro/${contract._id}`),
          },
          {
            label: "Supprimer la tarification",
            handler: async () => {
              const customer = await contract.customer;
              const ContractRCPro = graphandClient.getModel("Data:contrat-rc-pro");
              const count = await ContractRCPro.count({ query: { customer } });
              if (count > 1) {
                alert("Cette tarification ne peut pas etre supprimée par le proposant est lié à plus d'un contrat.");
                return;
              }

              if (!window.confirm("Voulez-vous vraiment supprimer cette ligne et le client qui est lié à cette tarification ?")) {
                return null;
              }

              await customer.delete();
              await contract.delete();
            },
          },
        ]);
      }

      if (contract.status === "draft") {
        _list = _list.concat([
          {
            label: "Modifier le brouillon",
            handler: () => this.props.history.push(`/contracts-rc-pro/${contract._id}`),
          },
          {
            label: "Passer en validation",
            handler: () => contract.update({ set: { status: "pending" } }).then(() => graphandClient.getModel("Data:contrat-rc-pro").clearCache()),
          },
          {
            label: "Télécharger la proposition",
            handler: () => contract.downloadContract(),
          },
        ]);
      } else if (contract.status === "pending") {
        _list = _list.concat([
          {
            label: "Convertir en brouillon",
            handler: () => contract.update({ set: { status: "draft" } }).then(() => graphandClient.getModel("Data:contrat-rc-pro").clearCache()),
          },
        ]);

        if (graphandClient.authmanager.user?.isAdmin()) {
          _list = _list.concat([
            {
              label: "Valider le projet",
              handler: () =>
                contract.update({ set: { status: "active" } }).then(() => {
                  graphandClient.getModel("Data:contrat-rc-pro").clearCache();
                  this.props.history.push(`/contracts-rc-pro/${contract._id}/details`);
                }),
            },
          ]);
        }
      } else if (contract.status === "active" && graphandClient.authmanager.user?.isAdmin()) {
        _list = _list.concat([
          {
            label: "Résilier",
            handler: () => contract.update({ set: { status: "archived" } }).then(() => graphandClient.getModel("Data:contrat-rc-pro").clearCache()),
          },
        ]);
      }

      if (!contract.isProject()) {
        _list = _list.concat([
          {
            label: "Paiements",
            handler: () => this.props.history.push(`/contracts-rc-pro/${contract._id}/details/payments`),
          },
          {
            label: "Sinistres",
            handler: () => this.props.history.push(`/contracts-rc-pro/${contract._id}/details/claims`),
          },
        ]);
      }

      return (
        <Menu.Items className="absolute right-0 w-56 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-10 overflow-hidden">
          {_list.filter(Boolean).map((item) => (
            <Menu.Item>
              {({ active }) => (
                <button
                  className={`${active ? "bg-gray-100" : ""} group flex items-center w-full px-2 py-3 text-sm`}
                  onClick={item.handler}
                  type="button"
                >
                  {item.label}
                </button>
              )}
            </Menu.Item>
          ))}
        </Menu.Items>
      );
    };

    return (
      <div className="py-8 sm:px-8 bg-gray-100">
        <div className="max-w-screen-xl w-full mx-auto space-y-4 sm:space-y-8">
          <Breadcrumbs pages={[{ name: "contracts-rc-pro", label: "Portefeuille" }]} />
          <Heading title={"Portefeuille"} />
          <GraphandForm values={{ q: this.state.q }} onChange={({ q }) => this.setState({ q })} fields={{ q: new GraphandFieldText({}) }}>
            {this.searchTemplate}
          </GraphandForm>
          <div>
            <div className="border-b border-gray-200">
              <nav className="-mb-px flex space-x-8" aria-label="Tabs">
                {filters.map((filter) => (
                  <a
                    key={filter.label}
                    className={
                      (this.state.filter?.label === filter?.label
                        ? "border-primary-500 text-primary-600"
                        : "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300") +
                      "whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm cursor-pointer"
                    }
                    onClick={() => this.setState({ filter })}
                    aria-current={this.state.filter?.label === filter?.label ? "page" : undefined}
                  >
                    {filter.label}
                  </a>
                ))}
              </nav>
            </div>
            <GraphandUIList
              cacheKey="ContractsGraphandListState"
              query={this.state.filter.query}
              ids={this.state.q && this.state.ids ? this.state.ids : undefined}
              model={graphandClient.getModel("Data:contrat-rc-pro")}
              map={[this.state.filter.displayCode ? "code" : false, "customer", "responsible", "status", "effective-date"].filter(Boolean)}
              controls={controls}
            />
            <div className="flex space-x-4 mt-2">
              <div className="flex items-center text-yellow-500">
                <div className="h-3 w-3 rounded-full bg-yellow-500 mr-2 flex-shrink-0" /> Projet en attente de validation de notre part
              </div>
              <div className="flex items-center text-emerald-500">
                <div className="h-3 w-3 rounded-full bg-emerald-500 mr-2 flex-shrink-0" /> En cours
              </div>
              <div className="flex items-center text-orange-500">
                <div className="h-3 w-3 rounded-full bg-orange-500 mr-2 flex-shrink-0" /> Résilié
              </div>
              <div className="flex items-center text-secondary-500">
                <div className="h-3 w-3 rounded-full bg-secondary-500 mr-2 flex-shrink-0" /> Brouillon : En attente de demande de validation de votre
                part
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ContractsRCProList;
