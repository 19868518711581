import axios from "axios";
import "jsoneditor-react/es/editor.min.css";

class Creditsafe {
  static _token;
  static _axios;

  static get axios() {
    if (!this._axios) {
      this._axios = axios.create({
        baseURL: "https://connect.creditsafe.com/v1",
        transformRequest: [
          (data, headers) => {
            if (this._token) {
              headers.Authorization = `Bearer ${this._token}`;
            }

            return data;
          },
        ].concat(axios.defaults.transformRequest),
      });
    }

    return this._axios;
  }

  static async login({ username, password }) {
    try {
      // if (!username || !password) {
      //   const graphandClient = require("./graphand").default;
      //   const credentials = await graphandClient.models["creditsafe-credentials"].get();
      //   username = credentials.raw.username;
      //   password = credentials.raw.password;
      // }
      //
      // const { data } = await this.axios.post("/authenticate", { username, password });
      // this._token = data.token;
    } catch (e) {
      console.error(e);
    }
  }

  static async getCompanies(params) {
    if (this._token === undefined) {
      await this.login({});
    }

    if (!this._token) {
      return;
    }

    return [];
    // const { data } = await this.axios.get("/companies", { params });
    // return data?.companies || [];
  }

  static async readCompany(connectId) {
    if (this._token === undefined) {
      await this.login({});
    }

    if (!this._token) {
      return;
    }

    const { data } = await this.axios.get(`/companies/${connectId}`);
    return data;
  }

  static async readCompanyFromSiret(siret, prefetch = false) {
    const regNo = siret.replace(/[\. ]/g, "");
    const companies = await Creditsafe.getCompanies({ regNo, countries: "FR" });
    if (!companies?.length) {
      if (!prefetch) {
        alert("Aucune entreprise correspondante. Vérifiez que le n° SIRET que vous avez entré est correct.");
      }
      return;
    }

    let companyId;
    if (companies.length > 1 && !prefetch) {
      // await new Promise((resolve) => {
      //   const companiesDialog = dialog(
      //     <div className="modal-container" onClick={() => companiesDialog.close()}>
      //       <div className="modal-dialog modal-lg" role="document">
      //         <div className="modal-content" onClick={(e) => e.stopPropagation()}>
      //           <div className="modal-header">
      //             <h5 className="modal-title">Plusieurs entreprises correspondent</h5>
      //             <button aria-label="Close" className="close" onClick={() => companiesDialog.close()} type="button">
      //               <span aria-hidden="true">&times;</span>
      //             </button>
      //           </div>
      //           <div className="modal-body">
      //             Plusieurs entreprises correspondent, veuillez sélectionner celle qui correspond à celle du client actuel.
      //             <div className="py-3">
      //               {companies.map((company) => (
      //                 <div className="card mb-2">
      //                   <div className="card-body">
      //                     <h5 className="card-title">{company.name}</h5>
      //                     <h6 className="card-subtitle mb-2 text-muted">{company.address.city}</h6>
      //                     <p className="card-text">{company.address.simpleValue}</p>
      //                     <a
      //                       className="card-link"
      //                       href="#"
      //                       onClick={(e) => {
      //                         e.preventDefault();
      //                         companyId = company.id;
      //                         companiesDialog.close();
      //                       }}
      //                     >
      //                       Sélectionner
      //                     </a>
      //                   </div>
      //                 </div>
      //               ))}
      //             </div>
      //           </div>
      //           <div className="modal-footer">
      //             <button className="btn btn-secondary" onClick={() => companiesDialog.close()} type="button">
      //               Annuler
      //             </button>
      //           </div>
      //         </div>
      //       </div>
      //     </div>,
      //     { close: resolve },
      //   );
      // });
    } else if (companies.length === 1) {
      companyId = companies[0].id;
    }

    if (!companyId) {
      return;
    }

    const data = await this.readCompany(companyId);

    if (!prefetch) {
      this.readCompanyWithData(data);
    }

    return data;
  }

  static async readCompanyWithData(data) {
    if (typeof window !== "undefined") {
      window.open(`https://www.creditsafe.fr/csfr/Company/Summary/${data.report.companySummary.companyNumber}`);
    }
    return;
    // const creditsafeDialog = dialog(
    //   <div className="modal-container" onClick={() => creditsafeDialog.close()}>
    //     <div className="modal-dialog modal-lg" role="document">
    //       <div className="modal-content" onClick={(e) => e.stopPropagation()}>
    //         <div className="modal-header">
    //           <h5 className="modal-title">Données creditsafe ({data?.companyId})</h5>
    //           <button aria-label="Close" className="close" onClick={() => creditsafeDialog.close()} type="button">
    //             <span aria-hidden="true">&times;</span>
    //           </button>
    //         </div>
    //         <div className="modal-body">{data?.report ? <Editor value={data.report} /> : null}</div>
    //         <div className="modal-footer">
    //           <button className="btn btn-secondary" onClick={() => creditsafeDialog.close()} type="button">
    //             Fermer
    //           </button>
    //         </div>
    //       </div>
    //     </div>
    //   </div>,
    // );
  }
}

export default Creditsafe;
