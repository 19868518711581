import React from "react";
import Breadcrumbs from "../../../components/Breadcrumbs";
import Heading from "../../../components/Heading";
import graphandClient from "../../../utils/graphand";
import { GraphandForm } from "graphand-react";
import CrudButton from "../../../components/CrudButton";

class ContactCreate extends React.Component {
  handleSubmit = async (values) => {
    await graphandClient.getModel("Data:contacts").create(values);
    this.props.history.push("/contacts");
  };

  render() {
    return (
      <div className="py-8 sm:px-8 bg-gray-100">
        <div className="max-w-screen-xl w-full mx-auto space-y-4 sm:space-y-8 ContactCreate">
          <Breadcrumbs pages={[{ name: "contacts", label: "Coordonnées" }, { label: "Ajouter une coordonnée" }]} />
          <Heading title={"Ajouter une coordonnée"} />
          <GraphandForm
            model={graphandClient.getModel("Data:contacts")}
            map={["name", "email", "phone"]}
            onSubmit={this.handleSubmit}
            controls={[
              <div className="text-right mt-8">
                <CrudButton type={"submit"} />
              </div>,
            ]}
          />
        </div>
      </div>
    );
  }
}

export default ContactCreate;
