import { GraphandFieldDate, GraphandFieldRelation } from "graphand-js";
import moment from "moment";
import React from "react";
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import XLSX from "xlsx";

import graphandClient from "../../utils/graphand";
import { GraphandForm } from "graphand-react";
import { subDays } from "date-fns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretRight } from "@fortawesome/free-solid-svg-icons";

const DIPLAY_CHARTS = {
  CONTRACTS: 1,
  CLAIMS: 2,
};

class Dashboard extends React.Component {
  userSubscription;
  state = {
    displayCharts: null,
    params: {
      agencies: undefined,
      from: subDays(new Date(), 180),
      to: undefined,
    },
  };

  // componentDidMount() {
  //   if (this.state.displayCharts) {
  //     this.setState({ displayCharts: null });
  //   }
  //
  //   setTimeout(() => this.setState({ displayCharts: DIPLAY_CHARTS.CONTRACTS }), 200);
  // }

  // componentDidMount() {
  //   this.userSubscription = graphandClient.authmanager.userSubject.subscribe(() => this.forceUpdate());
  // }
  //
  // componentWillUnmount() {
  //   this.userSubscription && this.userSubscription.unsubscribe();
  // }

  s2ab(s) {
    const buf = new ArrayBuffer(s.length); // convert s to arrayBuffer
    const view = new Uint8Array(buf); // create uint8array as viewer
    for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff; // convert to octet
    return buf;
  }

  async downloadBordereauApporteur(data, agency) {
    const [Contract] = graphandClient.getModels(["Data:contrat-rc-pro"]);

    const wb = XLSX.utils.book_new();
    wb.Props = {
      CreatedDate: new Date(),
    };

    wb.SheetNames.push(agency.name);
    const contracts = data
      .filter((point) => point.agency === agency._id)
      .reduce((_contracts, point) => {
        if (point.contracts?.length) {
          _contracts = _contracts.concat(
            point.contracts.map((c) => {
              const contract = new Contract(c);
              contract.raw._customer = new graphandClient.models.Account(c.customer);

              return contract;
            }),
          );
        }

        return _contracts;
      }, [])
      .sort((c1, c2) => c2.raw.code && c1.raw.code && c1.raw.code - c2.raw.code);
    const ws_data = [
      ["BORDEREAU DES EMISSIONS DE PRIMES (Affaires Nouvelles)"],
      ["Nom apporteur", agency.name],
      ["Code", "???"],
      ["Période", `${this.state.params.from ? moment(this.state.params.from).format("LL") : "début"} - ${moment(this.state.params.to).format("LL")}`],
      ["Produit :", "LNA TPEBAT", "", "Garanties :", "RCG - RC Pro - Décennale"],
      [],
      [],
      [
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "Totaux :",
        contracts.reduce((total, contract) => total + contract.quarterPrice, 0),
        contracts.reduce((total, contract) => total + contract.fraisApporteur, 0),
      ],
      [],
      [
        "N°contrat",
        "Date souscription",
        "Ech. principale",
        "Fract.",
        "Nom souscripteur",
        "Adresse",
        "Code postal",
        "Ville",
        "Code Activité (APE)",
        "Date début quittance",
        "Date fin quittance",
        "TTC Client",
        "dt Frais apporteur",
      ],
      ...contracts.map((contract) => [
        contract.renderFieldView("code"),
        moment(contract["effective-date"]).format("DD/MM/YYYY"),
        moment(contract["effective-date"]).add(1, "year").subtract(1, "day").format("DD/MM/YYYY"),
        contract["payment-mode"] === "year"
          ? "A"
          : contract["payment-mode"] === "semester"
          ? "S"
          : contract["payment-mode"] === "trimester"
          ? "T"
          : "?",
        contract.raw._customer.fullname,
        contract.raw._customer.address,
        contract.raw._customer.postal?.toString(),
        contract.raw._customer.city,
        contract["ape-code"],
        moment(contract["effective-date"]).format("DD/MM/YYYY"),
        contract.addQuarter().format("DD/MM/YYYY"),
        contract.quarterPrice,
        contract.fraisApporteur,
      ]),
    ];
    const ws = XLSX.utils.aoa_to_sheet(ws_data);
    wb.Sheets[agency.name] = ws;
    const wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });
    const blob = new Blob([this.s2ab(wbout)], { type: "application/octet-stream" });
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.style = "display: none";
    a.href = url;
    a.download = `${agency.name}.xlsx`;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }

  async downloadBordereauPrimes(data) {
    const [Contract, Account] = graphandClient.getModels(["Data:contrat-rc-pro", "Account"]);

    const wb = XLSX.utils.book_new();
    wb.Props = {
      CreatedDate: new Date(),
    };

    wb.SheetNames.push("Feuil1");
    const contracts = data
      .reduce((_contracts, point) => {
        if (point.contracts?.length) {
          _contracts = _contracts.concat(
            point.contracts.map((c) => {
              const contract = new Contract(c);
              contract.raw._customer = new Account(c.customer);

              return contract;
            }),
          );
        }

        return _contracts;
      }, [])
      .sort((c1, c2) => c2.raw.code && c1.raw.code && c1.raw.code - c2.raw.code);
    const ws_data = [
      ["LNA - LES NOUVELLES ASSURANCES"],
      ["Courtier Distributeur"],
      ["Code", "???"],
      ["Période", `${this.state.params.from ? moment(this.state.params.from).format("LL") : "début"} - ${moment(this.state.params.to).format("LL")}`],
      ["Produit :", "LNA TPEBAT", "", "Garanties :", "RCG - RC Pro - Décennale"],
      [],
      [],
      [
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "Totaux :",
        contracts.reduce((total, contract) => total + (contract.quarterPrice * 100) / 120, 0),
        contracts.reduce((total, contract) => total + ((contract.quarterPrice * 100) / 120) * 0.45, 0),
        contracts.reduce((total, contract) => total + ((contract.quarterPrice * 100) / 120) * 0.4, 0),
        contracts.reduce((total, contract) => total + ((contract.quarterPrice * 100) / 120) * 0.15, 0),
        contracts.reduce((total, contract) => total + ((contract.quarterPrice * 100) / 120) * 0.098, 0),
        contracts.reduce((total, contract) => total + parseInt(((contract.quarterPrice * 100) / 120) * 0.1, 10), 0),
        contracts.reduce(
          (total, contract) =>
            contract["payment-mode"] === "year"
              ? total + 25
              : contract["payment-mode"] === "semester"
              ? total + 12.5
              : contract["payment-mode"] === "trimester"
              ? total + 6.25
              : total,
          0,
        ),
        contracts.reduce((total, contract) => total + ((contract.quarterPrice * 100) / 120) * 0.225, 0),
        contracts.reduce(
          (total, contract) =>
            contract["payment-mode"] === "year"
              ? total + 300
              : contract["payment-mode"] === "semester"
              ? total + 150
              : contract["payment-mode"] === "trimester"
              ? total + 75
              : total,
          0,
        ),
        contracts.reduce((total, contract) => total + contract.quarterPrice, 0),
      ],
      [],
      [
        "Identifiant Ordre Client",
        "N°contrat",
        "Date devis",
        "Date souscription",
        "Ech. principale",
        "Fract.",
        "Nom souscripteur",
        "Adresse",
        "Code postal",
        "Ville",
        "Code Activité (APE)",
        "Nature de l'émission",
        "Nature de la prime",
        "Ident. Quittance",
        "Date début quittance",
        "Date fin quittance",
        "Prime totale HT",
        "dt RCG",
        "dt RCD",
        "dt Dommages",
        "Taxes",
        "Coûts de Police (Frais)",
        "Frais Adhésion TPE BAT",
        "Montant Com.",
        "Frais Apporteur",
        "Prime Totale Client",
      ],
      ...contracts.map((contract) => [
        contract.renderFieldView("code"),
        contract.renderFieldView("code"),
        moment(contract["effective-date"]).format("DD/MM/YYYY"),
        moment(contract["effective-date"]).format("DD/MM/YYYY"),
        moment(contract["effective-date"]).add(1, "year").subtract(1, "day").format("DD/MM/YYYY"),
        contract["payment-mode"] === "year"
          ? "A"
          : contract["payment-mode"] === "semester"
          ? "S"
          : contract["payment-mode"] === "trimester"
          ? "T"
          : "?",
        contract.raw._customer.fullname,
        contract.raw._customer.address,
        contract.raw._customer.postal,
        contract.raw._customer.city,
        contract["ape-code"],
        "AN",
        "AN",
        contract.renderFieldView("code"),
        moment(contract["effective-date"]).format("DD/MM/YYYY"),
        contract.addQuarter().format("DD/MM/YYYY"),
        (contract.quarterPrice * 100) / 120,
        ((contract.quarterPrice * 100) / 120) * 0.45,
        ((contract.quarterPrice * 100) / 120) * 0.4,
        ((contract.quarterPrice * 100) / 120) * 0.15,
        ((contract.quarterPrice * 100) / 120) * 0.098,
        parseInt(((contract.quarterPrice * 100) / 120) * 0.1, 10),
        contract["payment-mode"] === "year"
          ? 25
          : contract["payment-mode"] === "semester"
          ? 12.5
          : contract["payment-mode"] === "trimester"
          ? 6.25
          : "?",
        ((contract.quarterPrice * 100) / 120) * 0.225,
        contract["payment-mode"] === "year"
          ? 300
          : contract["payment-mode"] === "semester"
          ? 150
          : contract["payment-mode"] === "trimester"
          ? 75
          : "?",
        contract.quarterPrice,
      ]),
    ];
    const ws = XLSX.utils.aoa_to_sheet(ws_data);
    wb.Sheets.Feuil1 = ws;
    const wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });
    const blob = new Blob([this.s2ab(wbout)], { type: "application/octet-stream" });
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.style = "display: none";
    a.href = url;
    a.download = `Bordereau-des-emissions-de-primes.xlsx`;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }

  async downloadBordereauClaims(data) {
    const [Claim, Account] = graphandClient.getModels(["Data:claims", "Account"]);

    const wb = XLSX.utils.book_new();
    wb.Props = {
      CreatedDate: new Date(),
    };

    wb.SheetNames.push("Feuil1");
    const claims = data
      .reduce((_claims, point) => {
        if (point.claims?.length) {
          _claims = _claims.concat(
            point.claims.map((c) => {
              const claim = new Claim(c);
              c.contract.customer = c.customer;
              claim.populate(["contract"]);
              claim.contract.populate(["customer"]);

              return claim;
            }),
          );
        }

        return _claims;
      }, [])
      .sort((c1, c2) => c1.date - c2.date);

    console.log(claims[0]);

    const ws_data = [
      ["LNA - LES NOUVELLES ASSURANCES"],
      ["Courtier Distributeur"],
      ["Code", "???"],
      ["Période", `${this.state.params.from ? moment(this.state.params.from).format("LL") : "début"} - ${moment(this.state.params.to).format("LL")}`],
      ["Produit :", "LNA TPEBAT", "", "Garanties :", "RCG - RC Pro - Décennale"],
      [],
      [],
      ["Numéro de contrat", "Date d'effet", "Nom souscripteur", "SIRET", "APE", ...Object.keys(Claim.fields)],
      ...claims.map((claim) => [
        claim.contract?.renderFieldView("code"),
        claim.contract?.get("effective-date"),
        claim.contract?.customer?.get("slug"),
        claim.contract?.get("siret"),
        claim.contract?.get("ape-code"),
        ...Object.keys(Claim.fields).map((slug) => claim.get(slug)),
      ]),
    ];

    const ws = XLSX.utils.aoa_to_sheet(ws_data);
    wb.Sheets.Feuil1 = ws;
    const wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });
    const blob = new Blob([this.s2ab(wbout)], { type: "application/octet-stream" });
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.style = "display: none";
    a.href = url;
    a.download = `Bordereau-des-sinistres.xlsx`;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }

  parseContractsData = (rawData) => {
    if (!rawData) {
      return {};
    }

    const contractsDates = rawData.reduce((dates, point) => {
      if (point.agency && !dates.includes(point.date)) {
        dates.push(point.date);
      }

      return dates;
    }, []);

    return contractsDates
      .reduce((data, date) => {
        const points = rawData.filter((point) => point.date === date);

        data.push({
          name: date,
          date: moment(date, "YYYY-M-D"),
          ...points.reduce((list, point) => {
            if (point.agency) {
              list[point.agency] = point.count;
            }

            return list;
          }, {}),
        });

        return data;
      }, [])
      .sort((pointA, pointB) => pointA.date - pointB.date);
  };

  parseClaimsData = (rawData) => {
    if (!rawData) {
      return {};
    }

    const claimsDates = rawData.reduce((dates, point) => {
      if (point.agency && !dates.includes(point.date)) {
        dates.push(point.date);
      }

      return dates;
    }, []);

    return claimsDates
      .reduce((data, date) => {
        const points = rawData.filter((point) => point.date === date);

        data.push({
          name: date,
          date: moment(date, "YYYY-M-D"),
          ...points.reduce((list, point) => {
            if (point.agency) {
              list[point.agency] = point.count;
            }

            return list;
          }, {}),
        });

        return data;
      }, [])
      .sort((pointA, pointB) => pointA.date - pointB.date);
  };

  toggleDisplayChart = (chart) => {
    if (this.state.displayCharts === chart) {
      this.setState({ displayCharts: null });
    } else {
      this.setState({ displayCharts: chart });
    }
  };

  render() {
    const [Agency, Aggregation] = graphandClient.getModels(["Data:agency", "Aggregation"]);
    const agenciesList = this.state.params.agencies?.length ? { ids: this.state.params.agencies } : {};
    // console.log(this.state.params);
    return Agency.getList(agenciesList).suspense(
      (agencies) => (
        <div className="py-8 px-4 sm:px-8 bg-gray-100">
          <div className="max-w-screen-xl w-full mx-auto space-y-4 sm:space-y-8">
            {graphandClient
              .getModel("Data:dashboard")
              .get()
              .suspense((dashboard) => {
                return (
                  <div
                    className="w-full h-0 bg-no-repeat bg-cover bg-center rounded-md overflow-hidden shadow"
                    style={{ paddingBottom: "20%", backgroundImage: `url(${dashboard.image.getUrl({ w: 1280, h: 256, fit: "contain" })})` }}
                  />
                );
              })}

            <div className="bg-white shadow sm:rounded-lg p-4 sm:p-6">
              <div className="md:flex md:items-center md:justify-between mb-4">
                <div className="flex-1 min-w-0">
                  <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">Tableau de bord</h2>
                </div>
              </div>
              <GraphandForm
                controls={null}
                fields={{
                  agencies: new GraphandFieldRelation({
                    name: "Agences",
                    multiple: true,
                    defaultValue: agencies,
                    _model: graphandClient.getModel("Data:agency"),
                  }),
                  from: new GraphandFieldDate({
                    name: "Début",
                  }),
                  to: new GraphandFieldDate({
                    name: "Fin",
                  }),
                }}
                onChange={(params) => {
                  // console.log(params);
                  this.setState({ params });
                }}
                values={this.state.params}
              >
                {({ fields }) => (
                  <div className="">
                    {graphandClient.authmanager.user?.isAdmin() ? fields.render("agencies") : null}
                    <div className="flex space-x-4 w-full">
                      <div className="w-full">{fields.render("from")}</div>
                      <div className="w-full">{fields.render("to")}</div>
                    </div>
                  </div>
                )}
              </GraphandForm>
            </div>

            {Aggregation.execute("5f325db93e94040014a32837", this.state.params)
              .cache()
              .suspense(
                (data, loading) => {
                  if (loading || !data.data?.length) {
                    return (
                      <div className="bg-white shadow sm:rounded-lg p-4 sm:p-6">
                        <div className="md:flex md:items-center md:justify-between mb-4">
                          <div className="flex-1 min-w-0">
                            <h2
                              className="cursor-pointer text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate flex items-center"
                              onClick={() => this.toggleDisplayChart(DIPLAY_CHARTS.CONTRACTS)}
                            >
                              <FontAwesomeIcon icon={faCaretRight} className="text-gray-500 mr-3" />
                              Nouveaux contrats
                            </h2>
                          </div>
                        </div>

                        <div
                          className="w-full h-32 flex items-center justify-center select-none font-extrabold text-2xl text-secondary-300"
                          onClick={() => this.toggleDisplayChart(DIPLAY_CHARTS.CONTRACTS)}
                        >
                          Cliquez ici pour afficher le graphique
                        </div>
                      </div>
                    );
                  }

                  const contractsData = this.parseContractsData(data.data);
                  return (
                    <div className="bg-white shadow sm:rounded-lg p-4 sm:p-6">
                      <div className="md:flex md:items-center md:justify-between mb-4">
                        <div className="flex-1 min-w-0">
                          <h2
                            className="cursor-pointer text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate flex items-center"
                            onClick={() => this.toggleDisplayChart(DIPLAY_CHARTS.CONTRACTS)}
                          >
                            <FontAwesomeIcon
                              icon={this.state.displayCharts === DIPLAY_CHARTS.CONTRACTS ? faCaretDown : faCaretRight}
                              className="text-gray-500 mr-3"
                            />
                            Nouveaux contrats
                          </h2>
                        </div>
                        <div className="mt-4 flex md:mt-0 md:ml-4">
                          <button
                            onClick={() => this.downloadBordereauApporteur(data.data, agencies[0])}
                            type="button"
                            className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary-500"
                          >
                            Bordereau apporteur
                          </button>
                          {graphandClient.authmanager.user?.isAdmin() ? (
                            <button
                              onClick={() => this.downloadBordereauPrimes(data.data)}
                              type="button"
                              className="ml-3 inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-secondary-600 hover:bg-secondary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary-500"
                            >
                              Bordereau des primes
                            </button>
                          ) : null}
                        </div>
                      </div>

                      {this.state.displayCharts === DIPLAY_CHARTS.CONTRACTS ? (
                        <ResponsiveContainer height={500} width="100%">
                          <BarChart data={contractsData}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name" tickFormatter={(value) => moment(value, "YYYY-M-D").format("LL")} />
                            <YAxis />
                            <Tooltip
                              formatter={(value, name) => [`${value} contrat(s)`, Agency.get(name, false)?.name || name]}
                              labelFormatter={(value) => moment(value, "YYYY-M-D").format("LL")}
                            />
                            <Legend formatter={(value) => Agency.get(value).suspense((_agency) => _agency.name, value)} />
                            {agencies.map((agency) => {
                              return <Bar dataKey={agency._id} fill={agency.color} stackId="a" />;
                            })}
                          </BarChart>
                        </ResponsiveContainer>
                      ) : (
                        <div
                          className="w-full h-32 flex items-center justify-center select-none font-extrabold text-2xl text-secondary-300"
                          onClick={() => this.toggleDisplayChart(DIPLAY_CHARTS.CONTRACTS)}
                        >
                          Cliquez ici pour afficher le graphique
                        </div>
                      )}
                    </div>
                  );
                },
                {
                  updateKey: this.state.params,
                },
              )}

            {Aggregation.execute("620becd23a36dd72f9653c16", this.state.params)
              .cache()
              .suspense(
                (data, loading) => {
                  if (loading || !data.data?.length) {
                    return (
                      <div className="bg-white shadow sm:rounded-lg p-4 sm:p-6">
                        <div className="md:flex md:items-center md:justify-between mb-4">
                          <div className="flex-1 min-w-0">
                            <h2
                              className="cursor-pointer text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate flex items-center"
                              onClick={() => this.toggleDisplayChart(DIPLAY_CHARTS.CLAIMS)}
                            >
                              <FontAwesomeIcon icon={faCaretRight} className="text-gray-500 mr-3" />
                              Sinistres
                            </h2>
                          </div>
                        </div>

                        <div
                          className="w-full h-32 flex items-center justify-center select-none font-extrabold text-2xl text-secondary-300"
                          onClick={() => this.toggleDisplayChart(DIPLAY_CHARTS.CLAIMS)}
                        >
                          Cliquez ici pour afficher le graphique
                        </div>
                      </div>
                    );
                  }

                  const claimsData = this.parseClaimsData(data.data);
                  return (
                    <div className="bg-white shadow sm:rounded-lg p-4 sm:p-6">
                      <div className="md:flex md:items-center md:justify-between mb-4">
                        <div className="flex-1 min-w-0">
                          <h2
                            className="cursor-pointer text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate flex items-center"
                            onClick={() => this.toggleDisplayChart(DIPLAY_CHARTS.CLAIMS)}
                          >
                            <FontAwesomeIcon
                              icon={this.state.displayCharts === DIPLAY_CHARTS.CLAIMS ? faCaretDown : faCaretRight}
                              className="text-gray-500 mr-3"
                            />
                            Sinistres
                          </h2>
                        </div>
                        <div className="mt-4 flex md:mt-0 md:ml-4">
                          <button
                            onClick={() => this.downloadBordereauClaims(data.data, agencies[0])}
                            type="button"
                            className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary-500"
                          >
                            Bordereau des sinistres
                          </button>
                        </div>
                      </div>

                      {this.state.displayCharts === DIPLAY_CHARTS.CLAIMS ? (
                        <ResponsiveContainer height={500} width="100%">
                          <BarChart data={claimsData}>
                            <CartesianGrid strokeDasharray="3 3" />
                            <XAxis dataKey="name" tickFormatter={(value) => moment(value, "YYYY-M-D").format("LL")} />
                            <YAxis />
                            <Tooltip
                              formatter={(value, name) => [`${value} sinistre(s)`, Agency.get(name, false)?.name || name]}
                              labelFormatter={(value) => moment(value, "YYYY-M-D").format("LL")}
                            />
                            <Legend formatter={(value) => Agency.get(value).suspense((_agency) => _agency.name, value)} />
                            {agencies.map((agency) => {
                              return <Bar dataKey={agency._id} fill={agency.color} stackId="a" />;
                            })}
                          </BarChart>
                        </ResponsiveContainer>
                      ) : (
                        <div
                          className="w-full h-32 flex items-center justify-center select-none font-extrabold text-2xl text-secondary-300"
                          onClick={() => this.toggleDisplayChart(DIPLAY_CHARTS.CLAIMS)}
                        >
                          Cliquez ici pour afficher le graphique
                        </div>
                      )}
                    </div>
                  );
                },
                {
                  updateKey: this.state.params,
                },
              )}
            {/*<div>*/}
            {/*  {this.state.displayCharts ? (*/}
            {/*    Aggregation.execute("5f32c374dbe34f001419b971", this.state.params)*/}
            {/*      .cache()*/}
            {/*      .suspense(*/}
            {/*        (data, loading) => {*/}
            {/*          if (loading) {*/}
            {/*            return (*/}
            {/*              <div className="bg-white shadow sm:rounded-lg p-4 sm:p-6">*/}
            {/*                <div className="md:flex md:items-center md:justify-between mb-4">*/}
            {/*                  <div className="flex-1 min-w-0">*/}
            {/*                    <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">Nouveaux clients</h2>*/}
            {/*                  </div>*/}
            {/*                </div>*/}

            {/*                <div className="w-full bg-gray-300 animate-pulse" style={{ height: "500px" }} />*/}
            {/*              </div>*/}
            {/*            );*/}
            {/*          }*/}

            {/*          const contractsData = this.parseContractsData(data.data);*/}
            {/*          return (*/}
            {/*            <div className="bg-white shadow sm:rounded-lg p-4 sm:p-6">*/}
            {/*              <div className="md:flex md:items-center md:justify-between mb-4">*/}
            {/*                <div className="flex-1 min-w-0">*/}
            {/*                  <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">Nouveaux clients</h2>*/}
            {/*                </div>*/}
            {/*              </div>*/}

            {/*              <ResponsiveContainer height={500} width="100%">*/}
            {/*                <BarChart data={contractsData}>*/}
            {/*                  <CartesianGrid strokeDasharray="3 3" />*/}
            {/*                  <XAxis dataKey="name" tickFormatter={(value) => moment(value, "YYYY-M-D").format("LL")} />*/}
            {/*                  <YAxis />*/}
            {/*                  <Tooltip*/}
            {/*                    formatter={(value, name) => [`${value} client(s)`, Agency.get(name, false)?.name || name]}*/}
            {/*                    labelFormatter={(value) => moment(value, "YYYY-M-D").format("LL")}*/}
            {/*                  />*/}
            {/*                  <Legend formatter={(value) => Agency.get(value).suspense((_agency) => _agency.name, value)} />*/}
            {/*                  {agencies.map((agency) => {*/}
            {/*                    return <Bar dataKey={agency._id} fill={agency.color} stackId="a" />;*/}
            {/*                  })}*/}
            {/*                </BarChart>*/}
            {/*              </ResponsiveContainer>*/}
            {/*            </div>*/}
            {/*          );*/}
            {/*        },*/}
            {/*        {*/}
            {/*          updateKey: this.state.params,*/}
            {/*          fallback: (*/}
            {/*            <div className="bg-white shadow sm:rounded-lg p-4 sm:p-6">*/}
            {/*              <div className="md:flex md:items-center md:justify-between mb-4">*/}
            {/*                <div className="flex-1 min-w-0">*/}
            {/*                  <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">Nouveaux clients</h2>*/}
            {/*                </div>*/}
            {/*              </div>*/}

            {/*              <div className="w-full bg-gray-300 animate-pulse" style={{ height: "500px" }} />*/}
            {/*            </div>*/}
            {/*          ),*/}
            {/*        },*/}
            {/*      )*/}
            {/*  ) : (*/}
            {/*    <div className="bg-white shadow sm:rounded-lg p-4 sm:p-6">*/}
            {/*      <div className="md:flex md:items-center md:justify-between mb-4">*/}
            {/*        <div className="flex-1 min-w-0">*/}
            {/*          <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">Nouveaux clients</h2>*/}
            {/*        </div>*/}
            {/*      </div>*/}

            {/*      <div className="w-full bg-gray-300 animate-pulse" style={{ height: "500px" }} />*/}
            {/*    </div>*/}
            {/*  )}*/}
            {/*</div>*/}
          </div>
        </div>
      ),
      { updateKey: this.state.params.agencies },
    );
  }
}

export default Dashboard;
