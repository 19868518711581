import React from "react";
import { HomeIcon, ChevronRightIcon } from "@heroicons/react/solid";
import "./index.scss";
import { Link, withRouter } from "react-router-dom";

class Breadcrumbs extends React.Component {
  render() {
    const { pages } = this.props;
    const pathName = this.props.location.pathname;
    const paths = pathName.split("/").filter((p) => p);

    return (
      <nav className="breadcrumbs flex mb-4" aria-label="Breadcrumb">
        <ol className="bg-transparent pr-6 flex space-x-4">
          <li className="flex">
            <div className="flex items-center">
              <Link className="text-gray-400 hover:text-gray-500" to="/">
                <HomeIcon className="flex-shrink-0 h-5 w-5" aria-hidden="true" />
                <span className="sr-only">Tableau de bord</span>
              </Link>
            </div>
          </li>
          {pages.map((page, index) => {
            const link = page.link || `/${page.name || ""}`;

            return (
              <li key={index} className="flex">
                <div className="flex items-center">
                  <ChevronRightIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                  {link === "/" || link === pathName ? (
                    <span aria-current="page" className="ml-4 text-sm font-medium">
                      {page.label || page.name}
                    </span>
                  ) : (
                    <Link to={link} className="ml-4 text-sm font-medium text-gray-500 hover:text-gray-700">
                      {page.label || page.name}
                    </Link>
                  )}
                </div>
              </li>
            );
          })}
        </ol>
      </nav>
    );
  }
}

Breadcrumbs.defaultProps = {
  pages: [],
};

Breadcrumbs = withRouter(Breadcrumbs);

export default Breadcrumbs;
