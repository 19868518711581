import React from "react";
import Breadcrumbs from "../../../components/Breadcrumbs";
import Heading from "../../../components/Heading";
import graphandClient from "../../../utils/graphand";
import GraphandUIList from "../../../components/GraphandUIList";
import { Menu } from "@headlessui/react";
import ReactDOM from "react-dom";
import PreviewClaimModal from "../../../modals/PreviewClaim";
import UpdateClaimModal from "../../../modals/UpdateClaim";
import UpdateClaimDocumentsModal from "../../../modals/UpdateClaimDocuments";
import { GraphandForm } from "graphand-react";
import { GraphandFieldText } from "graphand-js";

class ClaimsList extends React.Component {
  state = {
    q: "",
    ids: undefined,
  };

  constructor(props) {
    super(props);

    this.searchTemplate = ({ fields, formRef, handleSubmit }) => (
      <form onSubmit={handleSubmit} ref={formRef}>
        {fields.render("q", { label: null, placeholder: "Rechercher un sinistre" })}
      </form>
    );
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.q && this.state.q !== prevState.q) {
      if (this.searchTimeout) {
        clearTimeout(this.searchTimeout);
      }

      const EsMapping = graphandClient.getModel("EsMapping");
      this.searchTimeout = setTimeout(
        () =>
          EsMapping.search("6295e99cc70d08b5f1624969", {
            query: {
              query_string: {
                query: `*${this.state.q}*`,
              },
            },
            size: 10,
          }).then((res) => {
            const ids = res.hits.hits.map((r) => r._id);
            this.setState({ ids });
          }),
        500,
      );
    }
  }

  render() {
    return (
      <div className="py-8 sm:px-8 bg-gray-100">
        <div className="max-w-screen-xl w-full mx-auto space-y-4 sm:space-y-8">
          <Breadcrumbs pages={[{ name: "claims", label: "Sinistres" }]} />
          <Heading title={"Liste des sinistres"} />
          <GraphandForm values={{ q: this.state.q }} onChange={({ q }) => this.setState({ q })} fields={{ q: new GraphandFieldText({}) }}>
            {this.searchTemplate}
          </GraphandForm>
          <GraphandUIList
            map={["claimRef", "date", "statut", "contract"]}
            model={graphandClient.getModel("Data:claims")}
            ids={this.state.q && this.state.ids ? this.state.ids : undefined}
            controls={(claim) => (
              <Menu.Items className="absolute right-0 w-56 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-10 overflow-hidden">
                <Menu.Item>
                  {({ active }) => (
                    <button
                      className={`${active ? "bg-gray-500 text-white" : "text-gray-900"} group flex items-center w-full px-2 py-2 text-sm`}
                      onClick={() => {
                        const node = document.createElement("div");
                        document.body.appendChild(node);
                        ReactDOM.render(
                          React.createElement(PreviewClaimModal, {
                            onClose: () => null,
                            _id: claim._id,
                          }),
                          node,
                        );
                        node.remove();
                      }}
                      type="button"
                    >
                      Aperçu
                    </button>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <button
                      className={`${active ? "bg-gray-500 text-white" : "text-gray-900"} group flex items-center w-full px-2 py-2 text-sm`}
                      onClick={() => {
                        const node = document.createElement("div");
                        document.body.appendChild(node);
                        ReactDOM.render(
                          React.createElement(UpdateClaimModal, {
                            onClose: () => null,
                            claim,
                          }),
                          node,
                        );
                        node.remove();
                      }}
                      type="button"
                    >
                      Modifier
                    </button>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <button
                      className={`${active ? "bg-gray-500 text-white" : "text-gray-900"} group flex items-center w-full px-2 py-2 text-sm`}
                      onClick={() => {
                        const node = document.createElement("div");
                        document.body.appendChild(node);
                        ReactDOM.render(
                          React.createElement(UpdateClaimDocumentsModal, {
                            onClose: () => null,
                            claim,
                          }),
                          node,
                        );
                        node.remove();
                      }}
                      type="button"
                    >
                      Documents
                    </button>
                  )}
                </Menu.Item>
              </Menu.Items>
            )}
          />
        </div>
      </div>
    );
  }
}

export default ClaimsList;
