import React from "react";
import graphandClient from "../../../utils/graphand";
import GraphandUIList from "../../../components/GraphandUIList";
import { Menu } from "@headlessui/react";
import { GraphandForm } from "graphand-react";
import { GraphandFieldDate } from "graphand-js";

const from = new Date();
from.setDate(1);
const to = new Date(from.getTime());
to.setMonth(to.getMonth() + 1);
to.setDate(0);

class NextPayments extends React.Component {
  state = {
    page: 1,
    sort: "nextPayment",
    params: {
      from,
      to,
    },
  };

  render() {
    const { page } = this.state;
    const controls = (contract) => {
      let _list = [
        {
          label: "Détails",
          handler: () => this.props.history.push(`/contracts-rc-pro/${contract._id}/details`),
        },
      ];

      if (!contract.isProject()) {
        _list = _list.concat([
          {
            label: "Paiements",
            handler: () => this.props.history.push(`/contracts-rc-pro/${contract._id}/details/payments`),
          },
          contract.paid
            ? false
            : {
                label: "Payer",
                handler: () => {
                  contract.paid = true;
                  const Payment = graphandClient.getModel("Data:payments");
                  Payment.create({ amount: contract.quarterPrice, contract });
                },
              },
        ]);
      }

      return (
        <Menu.Items className="absolute right-0 w-56 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-10 overflow-hidden">
          {_list.filter(Boolean).map((item) => (
            <Menu.Item>
              {({ active }) => (
                <button
                  className={`${active ? "bg-gray-100" : ""} group flex items-center w-full px-2 py-3 text-sm`}
                  onClick={item.handler}
                  type="button"
                >
                  {item.label}
                </button>
              )}
            </Menu.Item>
          ))}
        </Menu.Items>
      );
    };

    const fallback = (
      <div className="w-full py-12 animate-pulse">
        <img alt="LNA solutions d'assurance" className="mx-auto w-64 d-block" src="/images/lna_logo_white.png" />
      </div>
    );

    const sortField = this.state.sort.replace("-", "");
    const sortOrder = this.state.sort.match("-") ? -1 : 1;
    const sort = { [sortField]: sortOrder };
    const params = { ...this.state.params, skip: (page - 1) * 15, limit: page * 15, sort };

    return (
      <>
        <GraphandForm
          controls={null}
          fields={{
            from: new GraphandFieldDate({
              name: "Début",
            }),
            to: new GraphandFieldDate({
              name: "Fin",
            }),
          }}
          onChange={(params) => {
            this.setState({ params });
          }}
          values={this.state.params}
        >
          {({ fields }) => (
            <div className="py-4">
              <div className="flex space-x-4 w-full">
                <div className="w-full">{fields.render("from")}</div>
                <div className="w-full">{fields.render("to")}</div>
              </div>
            </div>
          )}
        </GraphandForm>

        {graphandClient
          .getModel("Aggregation")
          .get("621447baeb097a093b21acbd")
          .execute(params)
          .cache()
          .suspense(
            ({ data }, loading) => {
              const Contract = graphandClient.getModel("Data:contrat-rc-pro");
              const list = data.map((row) => new Contract(row));

              return (
                <GraphandUIList
                  cacheKey="621447baeb097a093b21acbd"
                  loading={loading}
                  onSortChange={(sort) => this.setState({ sort: sort || "nextPayment" })}
                  disableFieldsPopover
                  map={["code", "nextPayment", "paid"]}
                  model={Contract}
                  list={list}
                  count={1000}
                  pageSize={15}
                  onPageChange={(page) => this.setState({ page })}
                  controls={controls}
                />
              );
            },
            {
              updateKey: params,
              fallback,
            },
          )}
      </>
    );
  }
}

export default NextPayments;
