import React from "react";
import "./index.scss";
import graphandClient from "../../utils/graphand";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCross, faDownload, faTrash } from "@fortawesome/free-solid-svg-icons";

class InputMedia extends React.Component {
  state = {
    loading: false,
  };

  handleChange = async (e) => {
    this.setState({ loading: true });
    const file = e.currentTarget.files[0];
    const media = await graphandClient.models.Media.create({ file });
    if (this.props.value && this.props.replace) {
      graphandClient.models.Media.get(this.props.value).then((media) => media.delete());
    }
    this.props.onChange(media._id);
    this.setState({ loading: false });
  };

  render() {
    return (
      <div className={`InputMedia ${this.state.loading ? "loading" : ""}`}>
        <input className="input-file" id={this.props.id} onChange={this.handleChange} type="file" />
        <label htmlFor={this.props.id}>
          {this.props.value
            ? graphandClient.models.Media.get(this.props.value).suspense((media) =>
                media ? <div className="input-preview">{this.props.renderPreview(media.url)}</div> : this.props.renderEmpty(this.props),
              )
            : this.props.renderEmpty(this.props)}
        </label>
        {this.props.value ? (
          <button className="btn btn-danger" onClick={() => this.props.onChange(null)} type="button">
            <FontAwesomeIcon icon={faTrash} />
          </button>
        ) : null}
      </div>
    );
  }
}

InputMedia.defaultProps = {
  value: undefined,
  onChange: () => null,
  renderPreview: (url) => <img alt="" src={url} />,
  renderEmpty: (props) => props.renderPreview("/images/placeholder.jpg"),
  replace: true,
};

export default InputMedia;
