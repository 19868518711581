import { GraphandModelAccount, GraphandFieldText } from "graphand-js";
import React from "react";
import { toast } from "react-toastify";
import ROLES from "../enums/roles";
import Creditsafe from "../utils/creditsafe";
import CrudButton from "../components/CrudButton";
import Agency from "./Agency";

class Account extends GraphandModelAccount {
  static defaultField = "fullname";

  isPartner() {
    return this.raw.role === ROLES.PARTNER || this.raw.role === ROLES.ADMIN;
  }

  isAdmin() {
    return this.raw.role === ROLES.ADMIN;
  }

  isLNA() {
    return (this.isAdmin() || this.isPartner()) && this.agency?._id === "5ef4fb17d1e04800139da5cf";
  }

  async updateCreditsafe() {
    const companies = await Creditsafe.getCompanies({ name: this.slug, postCode: this.postal, countries: "FR" });
    if (!companies?.length) {
      alert(`Aucune entreprise avec le nom ${this.slug} et le code postal ${this.postal} n'a été trouvée`);
      return;
    }
    if (companies.length === 1) {
      return await this.update({ set: { creditsafeId: companies[0].id } });
    }

    // const creditsafeId = await new Promise((resolve) => {
    //   const companiesDialog = dialog(
    //     <div className="modal-container" onClick={() => companiesDialog.close()}>
    //       <div className="modal-dialog modal-lg" role="document">
    //         <div className="modal-content" onClick={(e) => e.stopPropagation()}>
    //           <div className="modal-header">
    //             <h5 className="modal-title">Plusieurs entreprises correspondent à "{this.slug}"</h5>
    //             <button aria-label="Close" className="close" onClick={() => companiesDialog.close()} type="button">
    //               <span aria-hidden="true">&times;</span>
    //             </button>
    //           </div>
    //           <div className="modal-body">
    //             Plusieurs entreprises correspondent à <strong>"{this.slug}"</strong>, veuillez sélectionner celle qui correspond à celle du client
    //             actuel.
    //             <div className="py-3">
    //               {companies.map((company) => (
    //                 <div className="card mb-2">
    //                   <div className="card-body">
    //                     <h5 className="card-title">{company.name}</h5>
    //                     <h6 className="card-subtitle mb-2 text-muted">{company.address.city}</h6>
    //                     <p className="card-text">{company.address.simpleValue}</p>
    //                     <a
    //                       className="card-link"
    //                       href="#"
    //                       onClick={(e) => {
    //                         e.preventDefault();
    //                         resolve(company.id);
    //                         companiesDialog.close();
    //                       }}
    //                     >
    //                       Sélectionner
    //                     </a>
    //                   </div>
    //                 </div>
    //               ))}
    //             </div>
    //           </div>
    //           <div className="modal-footer">
    //             <button className="btn btn-secondary" onClick={() => companiesDialog.close()} type="button">
    //               Annuler
    //             </button>
    //           </div>
    //         </div>
    //       </div>
    //     </div>,
    //     {
    //       close: () => {
    //         resolve();
    //       },
    //     },
    //   );
    // });
    // if (creditsafeId) {
    //   await this.update({ set: { creditsafeId } });
    // }
  }

  async readCreditsafe() {
    if (!this.creditsafeId) {
      await this.updateCreditsafe();
    }

    if (!this.creditsafeId) {
      return;
    }

    try {
      const infos = await Creditsafe.readCompany(this.creditsafeId);
      console.log(infos);
    } catch (e) {
      alert(`Une erreur est survenue lors de la récupération des informations sur l'entreprise ${this.creditsafeId}`);
    }
  }

  static inlineTemplate({ fields, values, formRef, handleSubmit, isLoading }) {
    const map = ["agency", "firstname", "lastname", "email", "phone", "slug", "address", "postal", "city", "iban", "bic", "bank-name"];
    return (
      <form ref={formRef} onSubmit={isLoading ? (e) => e.preventDefault() : handleSubmit} className="relative">
        {isLoading ? <div className="absolute inset-0 bg-white opacity-60 z-10" /> : null}
        <div className="grid grid-cols-2 gap-4">
          {map.map((slug) => (
            <div className={`col-span-2 ${["agency"].indexOf(slug) == -1 ? "sm:col-span-1" : ""}`}>{fields.render(slug)}</div>
          ))}
        </div>
        <div className="mt-8 flex justify-end">
          <CrudButton type={"submit"} className="mt-8" />
        </div>
      </form>
    );
  }
}

Account.customFields({
  fullname: new GraphandFieldText({
    getter(value, account) {
      return `${account.firstname} ${account.lastname}`;
    },
  }),
});

Account.hook("postCreate", function (created, err) {
  if (created && !err) {
    toast.success("Le client a bien été ajouté");
  }
});

Account.hook("postUpdate", function (updated, err) {
  if (updated && !err) {
    toast.success("Le client a bien été modifié");
  }
});

Account.hook("postDelete", function (args, err) {
  if (!err) {
    toast.success("Le client a bien été supprimé");
  }
});

export default Account;
