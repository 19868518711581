import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dialog, Transition } from "@headlessui/react";
import React, { useState } from "react";
import { GraphandForm } from "graphand-react";
import graphandClient from "../utils/graphand";
import ContractRCPro from "../models/ContractRCPro";

const ValidateContractModal = (props) => {
  const [isOpen, setIsOpen] = useState(true);

  function closeModal() {
    setIsOpen(false);
    props.onClose();
  }

  function confirmModal(effectiveDate) {
    setIsOpen(false);
    props.onConfirm(effectiveDate);
  }

  return (
    <Transition appear as={React.Fragment} show={isOpen}>
      <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={closeModal}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75" />
          </Transition.Child>

          <span aria-hidden="true" className="inline-block h-screen align-middle">
            &#8203;
          </span>
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block w-full max-w-md p-6 my-8 text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
              <GraphandForm
                model={ContractRCPro}
                onSubmit={(values) => {
                  if (!values["effective-date"]) {
                    throw new GraphandValidationError("Veuillez entrer une date", "effective-date", GraphandValidationError.Codes.REQUIRED);
                  }

                  if (!values["effective-date"].getTime || values["effective-date"].getTime() !== values["effective-date"].getTime()) {
                    throw new GraphandValidationError("Veuillez entrer une date valide", "effective-date");
                  }

                  //payload.set["effective-date"] = values["effective-date"];
                  confirmModal(values["effective-date"]);
                }}
              >
                {({ fields, formRef, handleSubmit }) => (
                  <div className="modal-dialog" role="document">
                    <form ref={formRef} onSubmit={handleSubmit} className="modal-content">
                      <div className="sm:flex sm:items-start">
                        <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-emerald-100 sm:mx-0 sm:h-10 sm:w-10">
                          <div className="h-6 w-6 text-emerald-600 flex items-center justify-center">
                            <FontAwesomeIcon icon={faCheck} size="lg" />
                          </div>
                        </div>
                        <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
                          <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                            Convertir le contrat en proposition
                          </Dialog.Title>
                          <div className="mt-2 w-full">{fields.render("effective-date")}</div>
                        </div>
                      </div>
                      <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                        <button
                          className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-emerald-600 text-base font-medium text-white hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500 sm:ml-3 sm:w-auto sm:text-sm"
                          type="submit"
                        >
                          Valider
                        </button>
                        <button
                          className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary-500 sm:mt-0 sm:w-auto sm:text-sm"
                          onClick={closeModal}
                          type="button"
                        >
                          Annuler
                        </button>
                      </div>
                    </form>
                  </div>
                )}
              </GraphandForm>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default ValidateContractModal;
