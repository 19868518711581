import { Dialog, Menu, Transition } from "@headlessui/react";
import React, { useState } from "react";
import graphandClient from "../utils/graphand";
import GraphandUIList from "../components/GraphandUIList";

const ManagePaymentsModal = (props) => {
  const [isOpen, setIsOpen] = useState(true);

  function closeModal() {
    setIsOpen(false);
    props.onClose();
  }

  return (
    <Transition appear as={React.Fragment} show={isOpen}>
      <Dialog as="div" className="fixed z-10 inset-0 overflow-y-auto" onClose={closeModal}>
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75" />
          </Transition.Child>

          <span aria-hidden="true" className="inline-block h-screen align-middle">
            &#8203;
          </span>
          <Transition.Child
            as={React.Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block w-full max-w-screen-xl p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
              <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900 mb-4">
                Gestion des paiements
              </Dialog.Title>
              <GraphandUIList
                query={{ contract: props.contract._id }}
                // controls={(item) => [
                //   <button className="btn btn-danger" onClick={() => item.delete()} type="button">
                //     Supprimer
                //   </button>,
                // ]}
                controls={(item) => (
                  <Menu.Items className="absolute right-0 w-56 mt-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-10 overflow-hidden">
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          className={`${active ? "bg-gray-500 text-white" : "text-gray-900"} group flex items-center w-full px-2 py-2 text-sm`}
                          onClick={() => item.delete()}
                          type="button"
                        >
                          Supprimer le paiement
                        </button>
                      )}
                    </Menu.Item>
                  </Menu.Items>
                )}
                map={["amount", "createdAt"]}
                model={graphandClient.getModel("Data:payments")}
              />

              <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <button
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-secondary-500 sm:mt-0 sm:w-auto sm:text-sm"
                  onClick={closeModal}
                  type="button"
                >
                  Annuler
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default ManagePaymentsModal;
