import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import InputTextRte from "./InputTextRte";
import { Translation } from "react-i18next";

class InputText extends React.Component {
  render() {
    const { options, value, onChange, errors, id, slug, field, inputRef } = this.props;

    let label = field.__dataField?.name || options.label;
    if (label === undefined) {
      label = <Translation>{(t) => t(`labels.fields.${slug}`)}</Translation>;
    }

    if (field.rte) {
      return React.createElement(InputTextRte, this.props);
    }

    if ((field.options?.length || field.multiple) && !options.disabled) {
      const SelectComponent = field.creatable ? CreatableSelect : Select;
      return (
        <div {...options} className={`${options.disabled ? "opacity-50" : ""} ${options.className}`}>
          {label ? (
            <label className="block text-sm font-medium text-gray-700 mb-1" htmlFor={id}>
              {label} {field.required ? "*" : null}
            </label>
          ) : null}
          {options.description ? <p className="text-sm text-gray-500 mb-1">{options.description}</p> : null}

          <SelectComponent
            className="w-100 z-30"
            isClearable
            isMulti={field.multiple}
            onChange={(_value) => onChange(field.multiple ? _value?.map((i) => i.value) : _value.value)}
            options={field.options?.map((o) => ({ label: o, value: o }))}
            value={field.multiple ? value?.map((i) => ({ label: i, value: i })) : { label: value, value }}
            {...(options.selectOptions || {})}
          />

          {errors?.map((e) => (
            <p className="mt-2 text-sm text-red-600" id={`${e.field}:${e.code}`}>
              {e.message}
            </p>
          ))}
        </div>
      );
    }

    return (
      <div {...options} className={`py-1 ${options.disabled ? "opacity-50" : ""} ${options.className || ""}`}>
        {label ? (
          <label className="block text-sm font-medium text-gray-700 mb-1" htmlFor={id}>
            {label} {field.required ? "*" : null}
          </label>
        ) : null}
        {options.description ? <p className="text-sm text-gray-500 mb-1">{options.description}</p> : null}
        <div className="relative rounded-md shadow-sm">
          <input
            ref={inputRef}
            className={`rounded-md block w-full border rounded-md shadow-sm py-2 h-11 px-3 focus:outline-none sm:text-sm focus:border-light-secondary-500 ${
              errors?.length ? `border-red-500` : `border-gray-200`
            }`}
            disabled={options.disabled}
            id={id}
            name={id}
            onChange={(e) => onChange(e.currentTarget.value || undefined)}
            placeholder={options.placeholder}
            type={options.inputType || "text"}
            value={value || ""}
          />
          {errors?.length ? (
            <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
              <div className="h-5 w-5 flex items-center justify-center">
                <FontAwesomeIcon className="text-red-500" icon={faExclamationCircle} size="lg" />
              </div>
            </div>
          ) : null}
        </div>

        {errors?.map((e) => (
          <p className="mt-2 text-sm text-red-600" id={`${e.field}:${e.code}`}>
            {e.message}
          </p>
        ))}
      </div>
    );
  }
}

export default InputText;
